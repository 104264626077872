import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Alert, Card, Col, Form, Row, Button } from "react-bootstrap";
import _ from "lodash";
import { InfoCircle } from "react-bootstrap-icons";
import moment from "moment";
import calculate from "calculate-coordinates";
import PolylineUtils from "@mapbox/polyline";
import * as geolib from "geolib";
import {
  ContentWrapper,
  JustnaikAppIntro,
  Modal,
  SimpleInputGroup,
  Table,
} from "../../components";
import { ApiRequest, exportData } from "../../helpers";
import { API_END_POINTS } from "../../config";
import { LambdaClient, InvokeCommand } from "@aws-sdk/client-lambda"; // ES Modules import

import { CircularProgress } from "@material-ui/core";
import { toast } from "react-toastify";
const config = {
  region: 'ap-southeast-1',
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_ACCESS_SECRET
  },
};

// Create a Lambda client
const client = new LambdaClient(config);
const WEEKEND_DAY_NUM = [0, 6];

const TransactionQuery = ({
  routeOpt = [],
  vehicleOpt = [],
  driverOpt = [],
  onSubmitQuery = () => {},
  onClearQuery = () => {},
  user,
  setToDate,
  setFromDate
}) => {
  const [route, setRoute] = useState(null);
  const [amPm, setAmPm] = useState("All");
  const [weekendWeekday, setWeekendWeekday] = useState("All");
  const [selectFromDate, setSelectFromDate] = useState(null);
  const [selectToDate, setSelectToDate] = useState(null);
  const [vehicle, setVehicle] = useState(null);
  const [driver, setDriver] = useState(null);
  const [paidBy, setPaidBy] = useState("All");

  const [canFilter, setCanFilter] = useState(false)

  const handleSubmitQuery = (e) => {
    e.preventDefault();
    const query = {
      route,
      amPm,
      selectFromDate,
      selectToDate,
      vehicle,
      driver,
      weekendWeekday,
      paidBy,
    };
    if (!route || route === "null") {
      query["route"] = null;
    }
    if (!selectFromDate || selectFromDate === "null") {
      query["selectFromDate"] = null;
    }
    if (!selectToDate || selectToDate === "null") {
      query["selectToDate"] = null;
    }
    if (!vehicle || vehicle === "null") {
      query["vehicle"] = null;
    }
    if (!driver || driver === "null") {
      query["driver"] = null;
    }
    onSubmitQuery(query);
  };

  const handleClear = () => {
    document.getElementById("date-from").value = "";
    setFromDate(null);
    setSelectFromDate(null);
    setToDate(null);
    setSelectToDate(null);
    setRoute(null);
    setAmPm("All");
    setWeekendWeekday("All");
    setVehicle(null);
    setDriver(null);
    setPaidBy("All");
    setCanFilter(false);
    onClearQuery();
  };
  

  return (
    <Card>
      <Card.Body>
        <h5>Search</h5>
        <Row>
          <Col lg={4} md={12} sm={12}></Col>
          <Col lg={8} md={12} sm={12}>
            <Form onSubmit={handleSubmitQuery} className="text-right">
              <Row>
              
                <Col>
                  <SimpleInputGroup labelWidth={10} preappendText="From Date">
                    <Form.Control
                      id="date-from"
                      max={moment().format("YYYY-MM-DD")}
                      min={
                        user.userType != "SUPER_ADMIN"
                          ? moment().subtract(3, "months").format("YYYY-MM-DD")
                          : moment().subtract(12, "months").format("YYYY-MM-DD")
                      }
                      onChange={(e) => {
                        setFromDate(
                          e.currentTarget.value
                            ? `${e.currentTarget.value} 00:00:00`
                            : e.currentTarget.value
                        )
                        setSelectFromDate(
                          e.currentTarget.value
                            ? `${e.currentTarget.value} 00:00:00`
                            : e.currentTarget.value
                        )
                      }
                      }
                      type="date"
                    />
                  </SimpleInputGroup>
                </Col>
                <Col>
                  <SimpleInputGroup labelWidth={10} preappendText="To Date">
                    <Form.Control
                      id="date-to"
                      min={moment(selectFromDate)
                        .format("YYYY-MM-DD")}
                      max={moment().format("YYYY-MM-DD")}
                      onChange={(e) => {
                        setToDate(
                          e.currentTarget.value
                            ? `${e.currentTarget.value} 23:59:59`
                            : e.currentTarget.value
                        )
                        setSelectToDate(
                          e.currentTarget.value
                            ? `${e.currentTarget.value} 23:59:59`
                            : e.currentTarget.value
                        )
                      }}
                      type="date"
                    />
                  </SimpleInputGroup>
                </Col>
              </Row>
              <SimpleInputGroup preappendText="AM/PM">
                <Form.Control
                  value={amPm}
                  onChange={(e) => setAmPm(e.currentTarget.value)}
                  custom
                  as="select">
                  <option>All</option>
                  <option>AM</option>
                  <option>PM</option>
                </Form.Control>
              </SimpleInputGroup>
              <SimpleInputGroup preappendText="Weekend / Weekday">
                <Form.Control
                  value={weekendWeekday}
                  onChange={(e) => setWeekendWeekday(e.currentTarget.value)}
                  custom
                  as="select">
                  <option>All</option>
                  <option>Weekend</option>
                  <option>Weekday</option>
                </Form.Control>
              </SimpleInputGroup>
              <SimpleInputGroup preappendText="Route">
                <Form.Control
                  value={route || "null"}
                  onChange={(e) => setRoute(e.currentTarget.value)}
                  custom
                  as="select">
                  <option value={"null"}>All</option>
                  {routeOpt.map((opt, i) => (
                    <option key={i} value={opt}>
                      {opt}
                    </option>
                  ))}
                </Form.Control>
              </SimpleInputGroup>
              <SimpleInputGroup preappendText="Vehicle">
                <Form.Control
                  value={vehicle || "null"}
                  onChange={(e) => setVehicle(e.currentTarget.value)}
                  custom
                  as="select">
                  <option value={"null"}>All</option>
                  {vehicleOpt.map((opt, i) => (
                    <option key={i} value={opt}>
                      {opt}
                    </option>
                  ))}
                </Form.Control>
              </SimpleInputGroup>
              <SimpleInputGroup preappendText="Driver">
                <Form.Control
                  value={driver || "null"}
                  onChange={(e) => setDriver(e.currentTarget.value)}
                  custom
                  as="select">
                  <option value={"null"}>All</option>
                  {driverOpt.map((opt, i) => (
                    <option key={i} value={opt}>
                      {opt}
                    </option>
                  ))}
                </Form.Control>
              </SimpleInputGroup>
              <SimpleInputGroup preappendText="Paid By">
                <Form.Control
                  value={paidBy}
                  onChange={(e) => setPaidBy(e.currentTarget.value)}
                  custom
                  as="select">
                  <option>All</option>
                  <option>Cash</option>
                  <option>Cashless</option>
                </Form.Control>
              </SimpleInputGroup>
              <Button className="ml-2" onClick={handleClear} variant="warning">
                Clear
              </Button>
              <Button className="ml-2" type="submit">
                Search
              </Button>
            </Form>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

const ClaimReportPage = ({ user }) => {
  const [mainClaimReportData, setMainClaimReportData] = useState({});
  const [tripCollection, setTripCollection] = useState(null);
  const [transaction, setTransaction] = useState(null);
  const [loading, setLoading] = useState(false);
  const [routesArr, setRoutesArr] = useState(null);
  const [driversArr, setDriversArr] = useState(null);
  const [vehiclesArr, setVehiclesArr] = useState(null);
  const [filteredTripCollection, setFilteredTripCollection] = useState(null);
  const [ultraFilteredTripCollection, setUltraFilteredTripCollection] =
    useState(null);

  const [toDate, setToDate] = useState(moment(new Date()).format("DD-MM-YYYY"));
  const [fromDate, setFromDate] = useState(
    moment(new Date()).subtract(1, "months").format("DD-MM-YYYY")
  );
  const [routesData, setRoutesData] = useState({});





  const handleGetAllRoute = () => {
    ApiRequest.fetch({
      method: "get",
      url: `${API_END_POINTS.ROUTE_GET_ALL}`,
      params: { showNotActive: false },
    })
      .then(async (data) => {
        await setRoutesArr(data);
        // handleRouteData()
      })
      .catch((e) => {});
  };
  const handleGetAllDriver = () => {
    ApiRequest.fetch({
      method: "get",
      url: `${API_END_POINTS.DRIVER_GET_ALL}`,
      params: { showNotActive: true },
    })
      .then(async (data) => {
        await setDriversArr(data);
        // handleRouteData()
      })
      .catch((e) => {});
  };
  const handleGetAllVehicle = () => {
    ApiRequest.fetch({
      method: "get",
      url: `${API_END_POINTS.VEHICLE_GET_ALL}`,
      params: { showNotActive: true },
    })
      .then(async (data) => {
        await setVehiclesArr(data);
        // handleRouteData()
      })
      .catch((e) => {});
  };

  useEffect(() => {
    const routeWithStops = {};
    //
    routesArr &&
      routesArr.forEach(({ id }) => {
        if (!routeWithStops[id]) {
          ApiRequest.fetch({
            method: "get",
            url: `${API_END_POINTS.STOP_GET_ALL}`,
            params: {
              routeId: id,
            },
          }).then((data) => {
            //
            // return data
            routeWithStops[id] = data;
          });
        }
      });
    setRoutesData((routesData) => ({ ...routesData, ...routeWithStops }));
    //
    //
  }, [routesArr]);

 

  useEffect(() => {
    handleGetAllRoute();
    handleGetAllDriver();
    handleGetAllVehicle();
  }, []);

  const tableHeader = useMemo(
    () => [
      {
        Header: "Route No.",
        accessor: "routeId",
        // disableFilters: true
      },
      {
        Header: "OD",
        accessor: "routeName",
        // disableFilters: true
      },
      {
        Header: "IB/OB",
        accessor: "direction",
        // disableFilters: true
      },
      // {
      //     Header: 'Trip ID',
      //     accessor: 'tripId',
      //     // disableFilters: true
      // },
      {
        Header: "Service Date",
        accessor: "serviceDate",
        // disableFilters: true
      },
      {
        Header: "Start Point",
        accessor: "startPoint",
        // disableFilters: true
      },
      {
        Header: "RPH No.",
        accessor: "tripId",
        // disableFilters: true
      },
      {
        Header: "Bus Plate Number",
        accessor: "busPlate",
        // disableFilters: true
      },
      {
        Header: "Bus Age",
        accessor: "busAge",
        // disableFilters: true
      },
      {
        Header: "Charge/KM",
        accessor: "kmRate",
        // disableFilters: true
      },
      {
        Header: "Driver ID",
        accessor: "driverIdentification",
        // disableFilters: true
      },
      {
        Header: "Bus Stop Travel",
        accessor: "busStops",
        // disableFilters: true
      },
      {
        Header: "Travel (KM) ",
        accessor: "kmApad",
        // disableFilters: true
      },
      {
        Header: "Total Claim ",
        accessor: "totalClaim",
        // disableFilters: true
      },
      {
        Header: "Travel(KM) GPS ",
        accessor: "kmApadG",
        // accessor: 'kmGps',
        // disableFilters: true
      },
      {
        Header: "Total Claim GPS",
        accessor: "totalClaimG",
        // disableFilters: true
      },
      {
        Header: "Status",
        accessor: "status",
        // disableFilters: true
      },
      {
        Header:
          "status of the trip (duplicate, trip outside schedule,no gps tracking, breakdown, replacement)",
        accessor: "statusDetail",
        // disableFilters: true
      },
      {
        Header: "KM as per BOP = ",
        accessor: "kmApadB",
        // disableFilters: true
      },
      {
        Header: "Claim as per BOP (RM)",
        accessor: "kmRateB",
        // disableFilters: true
      },
      {
        Header: "Start point",
        accessor: "actualStartS",
        // disableFilters: true
      },
      {
        Header: "Service Start Time",
        accessor: "serviceStart",
        // disableFilters: true
      },
      {
        Header: "Actual Start Time",
        accessor: "actualStartWithSeconds",
        // disableFilters: true
      },
      {
        Header: "Sales Start Time",
        accessor: "salesStart",
        // disableFilters: true
      },
      {
        Header: "Service End Time",
        accessor: "serviceEnd",
        // disableFilters: true
      },
      {
        Header: "Actual End Time",
        accessor: "actualEndWithSeconds",
        // disableFilters: true
      },
      {
        Header: "Sales End Time",
        accessor: "salesEnd",
        // disableFilters: true
      },
      // {
      //     Header: 'Price',
      //     accessor: 'fareTypeAmount_',
      //     // disableFilters: true
      // },
      {
        Header: "Punctuality",
        accessor: "punctuality",
        // disableFilters: true
      },
      {
        Header: "Passsengers Boarding Count",
        accessor: "passenger",
        // disableFilters: true
      },
      {
        Header: "Total Sales Amount (RM)",
        accessor: "totalAmount",
        // disableFilters: true
      },
      {
        Header: "Total On",
        accessor: "totalOn",
        // disableFilters: true
      },
      {
        Header: "Transfer Count",
        accessor: "transferCount",
        // disableFilters: true
      },
      {
        Header: "Monthly Pass",
        accessor: "monthlyPass",
        // disableFilters: true
      },
      {
        Header: "Adult",
        accessor: "noOfAdult",
        // disableFilters: true
      },
      {
        Header: "Child",
        accessor: "noOfChild",
        // disableFilters: true
      },
      {
        Header: "Senior",
        accessor: "noOfSenior",
        // disableFilters: true
      },
      {
        Header: "student",
        accessor: "noOfStudent",
        // disableFilters: true
      },
      {
        Header: "OKU",
        accessor: "noOfOku",
        // disableFilters: true
      },
      {
        Header: "JKM",
        accessor: "jkm",
        // disableFilters: true
      },
      {
        Header: "MAIM",
        accessor: "maim",
        // disableFilters: true
      },
      // {
      //     Header: 'Status (justNaik)',
      //     accessor: 'statusJ',
      //     // disableFilters: true
      // },
    ],
    []
  );

  

  const handleFilterTransactionData = useCallback(
    async ({
      route,
      amPm,
      selectFromDate,
      selectToDate,
      vehicle,
      driver,
      weekendWeekday,
      paidBy,
    }) => {
      if (!selectFromDate || !selectToDate) {
        toast.warning("Please Select the Start and End Date!");
        return;
      }
      setLoading(true);

      if (process.env.REACT_APP_NODE_ENV != 'production') {
        const backendProcessedClaimData = await ApiRequest.fetch({
          method: "get",
          url: `${API_END_POINTS.CLAIM_DETAILS_COLLECTION_BY_DATE_BACKEND}`,
          params: {
            timestamp: new Date(),
            from: selectFromDate,
            to: selectToDate,
            route,
        amPm,
        selectFromDate,
        selectToDate,
        vehicle,
        driver,
        weekendWeekday,
        paidBy
          },
        });

      setMainClaimReportData(backendProcessedClaimData)
      setLoading(false);
      } else {

        const params = {
          FunctionName: 'claimDetailsLambda', // Replace with your Lambda function name
          InvocationType: 'RequestResponse', // Synchronous invocation
          Payload: JSON.stringify({
            timestamp: new Date(),
            from: selectFromDate,
            to: selectToDate,
            route,
            amPm,
            selectFromDate,
            selectToDate,
            vehicle,
            driver,
            weekendWeekday,
            paidBy,
            agencyId: user.agency.id
          }) // Pass the request body as payload
        };
        // Input for the Lambda invocation
        const input = {
          FunctionName: "claimDetailsLambda", // required
          InvocationType: "RequestResponse", // "Event" || "RequestResponse" || "DryRun"
          LogType: "Tail", // "None" || "Tail"
          Payload: params.Payload, // Example payload
        };
  
        // Create an InvokeCommand
        const command = new InvokeCommand(input);
  
        // Invoke the Lambda function
        try {
          const response = await client.send(command);

          const jsonString = Buffer.from(response.Payload).toString('utf8');
          let responseObject;
          try {
            responseObject = JSON.parse(jsonString);
          } catch (error) {
            console.error("Error parsing JSON string:", jsonString);
            throw new Error("Decoded payload is not valid JSON");
          }
         

        setMainClaimReportData(responseObject.body)
        setLoading(false);
       
      } catch (error) {
        console.error('Error invoking Lambda function:', error);
      }
      }


      

      
    },
    [transaction]
  );

  const handleClearQuery = useCallback(() => {
    setFilteredTripCollection(null);
    setUltraFilteredTripCollection(null);
  }, []);

  const generateExportCsvData = useCallback(() => {
    if (!mainClaimReportData?.exportData) return toast.warning("Nothing to export!");

    const reportNameHeader = "Claim Details Report" + `\r\n\n`;
    const networkOperator = "Network Area:,EMPTY" + "\r\n";
    const networkArea = `Network Operator:,${user.agency.name}\r\n`;
    const datesGeneratedFor =
      fromDate && toDate
        ? `Reporting Period :,${moment(fromDate).format(
            "DD/MM/YYYY"
          )} - ${moment(toDate).format(
            "DD/MM/YYYY"
          )}\r\n`
        : "";
    const dateGenerated = `Generated At:,${moment().format("DD/MM/YYYY")}\r\n`;
    const generatedBy = `Generated By:,${user.firstName} ${user.lastName}\r\n`;
    const headderWithComma = `"status of the trip (duplicate, trip outside schedule,no gps tracking, breakdown, replacement)"`;
    // const header = "Trip Start, Trip End, Trip ID, Driver Name, Vehicle ID, Route ID, Cash (MYR), Cash Pax, Adult Cash (MYR), Adult Pax, Child Cash (MYR), Child Pax, Senior Cash (MYR), Senior Pax, Disabled Cash (MYR), Disabled Pax, Foreigner - Adult Cash (MYR), Foreigner - Adult Pax, Foreigner - Child Cash (MYR), Foreigner - Child Pax, Cashless (MYR), Cashless Pax, Adult Cashless (MYR), Adult Pax, Child Cashless (MYR), Child Pax, Senior Cashless (MYR), Senior Pax, Disabled Cashless (MYR), Disabled Pax, Foreigner - Adult Cashless (MYR), Foreigner - Adult Pax, Foreigner - Child Cashless (MYR), Foreigner - Child Pax, Total Transaction Count\r\n"
    // const mainHeader = `, ,,, ,,,,,,,,,,,,${totalClaimGpsT},,,,,,,,,,,,,0,${tAmount},${totallT + totallCT + totallST + totallOT},0,0,${totallT},${totallCT},${totallST},0,${totallOT},0,0\r\n`
    const headerPre =
      "\r,, ,, ,, , , ,,,,,, ,,Verified Data, ,,, , , , ,,, ,,,,ETM Boarding Passenger Count,, , ,,,,,,,,,,\r\n";
    const header = `Route No.,OD,IB/OB,Trip No.,Service Date,Start Point,RPH No.,Bus Plate Number,Bus Age,Charge/KM,Driver ID,Bus Stop Travel,Travel (KM),Total Claim,Travel (KM) GPS,Total Claim GPS,Status,${headderWithComma},KM as per BOP = ,Claim as per BOP (RM),Missed trip if no gps tracking,Start Point,Service Start Time,Actual Start Time,Sales Start Time,Service End Time,Actual End Time,Sales End Time,Punctuality,Passengers Boarding Count,Total Sales Amount (RM),Total On,Transfer Count,Monthly Pass,Adult,Child,Senior,Student,OKU,JKM,MAIM,\r\n`;
    let data =
      reportNameHeader +
      networkArea +
      networkOperator +
      datesGeneratedFor +
      dateGenerated +
      generatedBy;
   
    var BOM = "\uFEFF";
    var csvContent = BOM + data + mainClaimReportData?.exportData;
    exportData(
      csvContent,
      `Claim Details Report-${moment(fromDate, "YYYY-MM-DD hh:mm:ss").format(
        "MMMYYYY"
      )}-${user.agency.name}.csv`,
      "text/csv;charset=utf-8;"
    );
  }, [mainClaimReportData?.exportData]);

  return (
    <ContentWrapper>
      <h2 className="mb-3">Claim Details Report</h2>
      <Card className="mt-3">
        <Card.Body>
            <div>
              {routesArr && driversArr && vehiclesArr ? (
                <TransactionQuery
                  onClearQuery={handleClearQuery}
                  onSubmitQuery={handleFilterTransactionData}
                  routeOpt={routesArr.map(({ shortName }) => shortName)}
                  driverOpt={driversArr.map(({ driverName }) => driverName)}
                  vehicleOpt={vehiclesArr.map(
                    ({ registrationNumber }) => registrationNumber
                  )}
                  agencyNameOpt={[user?.agency?.name]}
                  user={user}
                  setFromDate={setFromDate}
                  setToDate={setToDate}
                />
              ) : (
                <div className="d-flex justify-content-center align-items-center">
                  <CircularProgress />
                </div>
              )}
              <Alert className="mt-3" variant="info">
                {" "}
                <InfoCircle /> Claim Details Report only shows data for the past
                1 months - Contact{" "}
                <a href="mailto: info@justnaik.com">info@justnaik.com</a> for
                more <br></br>
                <InfoCircle />
                The Trip ID is updated under the column RPH No. , so that you
                may find the GPS log of a trip by checking the Trip ID over the
                History Log. <br></br>
              </Alert>
              <div className="w-100 text-right">
                <Button onClick={generateExportCsvData}>Export Data</Button>
              </div>
              {mainClaimReportData?.returnData ? 
               <Table numbering columns={tableHeader} data={mainClaimReportData?.returnData} />
               :
               <Table numbering columns={tableHeader} data={[]} />

              }
             
            </div>
        </Card.Body>
      </Card>
      <Modal size="sm" centered show={loading}>
        <div className="text-center">
          <CircularProgress size={70} />
        </div>
      </Modal>
    </ContentWrapper>
  );
};

export default ClaimReportPage;
