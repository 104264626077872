import React, { useEffect, useState, useMemo, Fragment } from "react";
import moment from "moment";
import { CircularProgress } from "@material-ui/core";
import { Link, useHistory } from 'react-router-dom';


import { Calendar2Check, PencilSquare, PlusCircleFill, TrashFill, SquareFill, InfoCircle } from "react-bootstrap-icons";
import { Tabs, Tab, Card, Form, Row, Col, Button, Alert, Modal, } from "react-bootstrap";
import { ArrowRepeat, PlusCircle, Clipboard, ExclamationCircle, Shuffle } from 'react-bootstrap-icons'
import { v4 as uuidv4, v4 } from 'uuid';
import { toast } from 'react-toastify'
import { API_END_POINTS } from '../../config'
import { ApiRequest } from '../../helpers/api-request'
import { Trash } from 'react-bootstrap-icons'
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
// import { ScheduleV2TripPage } from '../ScheduleV2TripPage';


import {
    ContentWrapper,
    JustnaikAppIntro,
    EditScheduleModal,
    Table,
} from "../../components";
// import FeedbackDetailsModal from "./FeedbackDetailsModal";

import "./ScheduleV2Page.css";
import eventingBus from "eventing-bus";
import { ACTION_TYPES } from "../../config";
import e from "cors";
import { async } from "regenerator-runtime";
import DeleteModal from "../../components/DeleteModal";
import ScheduleV2TripPage from "../ScheduleV2TripPage";
// import { Toast } from "react-toastify/dist/components";
// TODO : REMOVE BELOW COMMENTS
// timing : timings in the add modal
// timingE : timings in the edit modal from the previously created
// timingsN : new timings added in the edit modal 
// delete : if item in the modal, delete directly, if delete from the newly added, then delete from timingsN,and when save, save the timingsN in create
// when save in edit modal: check the length of timingsN + timingsE > 0 or else show atleast one modal to save 
// handle isActive on Save
// refactor by making schedule component
// delete last record in precreated schedules?what happens? trying adding 1 record
const ScheduleV2Page = () => {
    const format = 'h:mm a';
    const [timing, setTiming] = useState([])
    const [mondayTiming, setMondayTiming] = useState([])
    const [tuesdayTiming, setTuesdayTiming] = useState([])
    const [wednesdayTiming, setWednesdayTiming] = useState([])
    const [thursdayTiming, setThursdayTiming] = useState([])
    const [fridayTiming, setFridayTiming] = useState([])
    const [saturdayTiming, setSaturdayTiming] = useState([])
    const [sundayTiming, setSundayTiming] = useState([])
    const [scheduleName, setScheduleName] = useState(null)
    const [moStart, setMoStart] = useState('')
    const [moEnd, setMoEnd] = useState('')
    const [tuStart, setTuStart] = useState(null)
    const [tuEnd, setTuEnd] = useState(null)
    const [weStart, setWeStart] = useState(null)
    const [weEnd, setWeEnd] = useState(null)
    const [thStart, setThStart] = useState(null)
    const [thEnd, setThEnd] = useState(null)
    const [frStart, setFrStart] = useState(null)
    const [frEnd, setFrEnd] = useState(null)
    const [saStart, setSaStart] = useState(null)
    const [saEnd, setSaEnd] = useState(null)
    const [suStart, setSuStart] = useState(null)
    const [suEnd, setSuEnd] = useState(null)
    const [show, setShow] = useState(false)
    const [mShow, setMShow] = useState(false)
    const [tShow, setTShow] = useState(false)
    const [wShow, setWShow] = useState(false)
    const [t2Show, setT2Show] = useState(false)
    const [fShow, setFShow] = useState(false)
    const [sShow, setSShow] = useState(false)
    const [s2Show, setS2Show] = useState(false)
    const [mShowN, setMShowN] = useState(false)
    const [tShowN, setTShowN] = useState(false)
    const [wShowN, setWShowN] = useState(false)
    const [t2ShowN, setT2ShowN] = useState(false)
    const [fShowN, setFShowN] = useState(false)
    const [sShowN, setSShowN] = useState(false)
    const [s2ShowN, setS2ShowN] = useState(false)
    const [isMo, setIsMo] = useState(true)
    const [isTu, setIsTu] = useState(true)
    const [isWe, setIsWe] = useState(true)
    const [isTh, setIsTh] = useState(true)
    const [isFr, setIsFr] = useState(true)
    const [isSa, setIsSa] = useState(true)
    const [isSu, setIsSu] = useState(true)
    const [refresh, setRefresh] = useState(true)
    const [schedules, setSchedules] = useState([])
    const [search, setSearch] = useState('')
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    // edit var
    const [isEditShow, setIsEditShow] = useState(false)
    const [currentEditSchedule, setCurrentEditSchedule] = useState('')
    const [currentEditScheduleId, setCurrentEditScheduleId] = useState('')
    const [currentCreatedAt, setCurrentCreatedAt] = useState('')
    const [currentUpdatedAt, setCurrentUpdatedAt] = useState('')
    const [mondayTimingE, setMondayTimingE] = useState([])
    const [tuesdayTimingE, setTuesdayTimingE] = useState([])
    const [wednesdayTimingE, setWednesdayTimingE] = useState([])
    const [thursdayTimingE, setThursdayTimingE] = useState([])
    const [fridayTimingE, setFridayTimingE] = useState([])
    const [saturdayTimingE, setSaturdayTimingE] = useState([])
    const [sundayTimingE, setSundayTimingE] = useState([])
    const [mondayTimingN, setMondayTimingN] = useState([])
    const [tuesdayTimingN, setTuesdayTimingN] = useState([])
    const [wednesdayTimingN, setWednesdayTimingN] = useState([])
    const [thursdayTimingN, setThursdayTimingN] = useState([])
    const [fridayTimingN, setFridayTimingN] = useState([])
    const [saturdayTimingN, setSaturdayTimingN] = useState([])
    const [sundayTimingN, setSundayTimingN] = useState([])
    const [moStartN, setMoStartN] = useState('')
    const [moEndN, setMoEndN] = useState('')
    // else if (moment(scheduledAt).isBefore(moment())) {
    //     toast.error('Cannot create Schedule after current time')
    // } 
    const [tuStartN, setTuStartN] = useState(null)
    const [tuEndN, setTuEndN] = useState(null)
    const [weStartN, setWeStartN] = useState(null)
    const [weEndN, setWeEndN] = useState(null)
    const [thStartN, setThStartN] = useState(null)
    const [thEndN, setThEndN] = useState(null)
    const [frStartN, setFrStartN] = useState(null)
    const [frEndN, setFrEndN] = useState(null)
    const [saStartN, setSaStartN] = useState(null)
    const [saEndN, setSaEndN] = useState(null)
    const [suStartN, setSuStartN] = useState(null)
    const [suEndN, setSuEndN] = useState(null)
    const [showN, setShowN] = useState(false)
    const [isMoN, setIsMoN] = useState(false)
    const [isTuN, setIsTuN] = useState(false)
    const [isWeN, setIsWeN] = useState(false)
    const [isThN, setIsThN] = useState(false)
    const [isFrN, setIsFrN] = useState(false)
    const [isSaN, setIsSaN] = useState(false)
    const [isSuN, setIsSuN] = useState(false)
    const [routesArr, setRoutesArr] = useState([])
    const [currentRoute, setCurrentRoute] = useState('')
    const [currentStops, setCurrentStops] = useState([])
    const [currentStopsOptions, setCurrentStopsOptions] = useState([])
    const [currentDirection, setCurrentDirection] = useState('')
    const [currentTimetableScheduleId, setCurrentTimetableScheduleId] = useState('')
    const [currentStartId, setCurrentStartId] = useState('')
    const [currentStopId, setCurrentStopId] = useState('')
    const [allTimetable, setAllTimetable] = useState({})
    const [timingsToDelete, setTimingsToDelete] = useState([])
    const [scheduleToDelete, setScheduleToDelete] = useState()
    const [timingsToUpdate, setTimingsToUpdate] = useState([])

    const [vehicles, setVehicles] = useState([])
    const [drivers, setDrivers] = useState([])

    const [isOpenConfirm, setIsOpenConfirm] = useState(false)
    const [isOpenDelete, setIsOpenDelete] = useState(false)


    function groupBy(objectArray, property) {
        return objectArray.reduce((acc, obj) => {
            const key = obj[property];
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(obj);
            return acc;
        }, {});
    }
    let reviver = function (key, value) {
        if (typeof value === 'undefined') { return null; }
        ;
    }

    const add_timings_time = (e, day, sTime, eTime) => {
        
        // e.preventDefault()
        if (day == 'mon') {
            let moStartN = sTime
            let moEndN = eTime
            let from = Date.parse('01/01/2011 ' + moStartN);
            let to = Date.parse('01/01/2011 ' + moEndN);

            if (from > to) {
                toast.error("End time should be greater than start time")
            } else {
                
                const new_entry = {
                    day: 'monday',
                    uuid: uuidv4(),
                    start_time: moStartN,
                    end_time: moEndN,
                    // schedule_id: currentEditScheduleId
                }
                
                setMondayTimingN((prevState) => [...prevState, new_entry])

                // mondayTimingN.push(new_entry)
                setMShowN(false)
                setMoStartN(null)
                setMoEndN(null)
                
            }
        } else if (day == 'tue') {
            let tuStartN = sTime
            let tuEndN = eTime
            let from = Date.parse('01/01/2011 ' + tuStartN);
            let to = Date.parse('01/01/2011 ' + tuEndN);

            if (from > to) {
                toast.error("End time should be greater than start time")
            } else {
                
                const new_entry = {
                    day: 'tuesday',
                    uuid: uuidv4(),
                    start_time: tuStartN,
                    end_time: tuEndN,
                    // schedule_id: currentEditScheduleId
                }
                // tuesdayTimingN.push(new_entry)
                setTuesdayTimingN((prevState) => [...prevState, new_entry])
                setTShowN(false)

                setTuStartN(null)
                setTuEndN(null)
            }
        } else if (day == 'wed') {
            let weStartN = sTime
            let weEndN = eTime
            let from = Date.parse('01/01/2011 ' + weStartN);
            let to = Date.parse('01/01/2011 ' + weEndN);

            if (from > to) {
                toast.error("End time should be greater than start time")
            } else {
                
                const new_entry = {
                    day: 'wednesday',
                    uuid: uuidv4(),
                    start_time: weStartN,
                    end_time: weEndN,
                    // schedule_id: currentEditScheduleId
                }
                // wednesdayTimingN.push(new_entry)
                setWednesdayTimingN((prevState) => [...prevState, new_entry])
                setWShowN(false)

                setWeStartN(null)
                setWeEndN(null)
            }
        } else if (day == 'thu') {
            let thStartN = sTime
            let thEndN = eTime
            let from = Date.parse('01/01/2011 ' + thStartN);
            let to = Date.parse('01/01/2011 ' + thEndN);

            if (from > to) {
                toast.error("End time should be greater than start time")
            } else {
                
                const new_entry = {
                    day: 'thursday',
                    uuid: uuidv4(),
                    start_time: thStartN,
                    end_time: thEndN,
                    // schedule_id: currentEditScheduleId
                }
                // thursdayTimingN.push(new_entry)
                setThursdayTimingN((prevState) => [...prevState, new_entry])
                setT2ShowN(false)

                setThStartN(null)
                setThEndN(null)
            }
        } else if (day == 'fri') {
            let frStartN = sTime
            let frEndN = eTime
            let from = Date.parse('01/01/2011 ' + frStartN);
            let to = Date.parse('01/01/2011 ' + frEndN);

            if (from > to) {
                toast.error("End time should be greater than start time")
            } else {
                
                const new_entry = {
                    day: 'friday',
                    uuid: uuidv4(),
                    start_time: frStartN,
                    end_time: frEndN,
                    // schedule_id: currentEditScheduleId
                }
                // fridayTimingN.push(new_entry)
                setFridayTimingN((prevState) => [...prevState, new_entry])
                setFShowN(false)

                setFrStartN(null)
                setFrEndN(null)
            }
        } else if (day == 'sat') {
            let saStartN = sTime
            let saEndN = eTime
            let from = Date.parse('01/01/2011 ' + saStartN);
            let to = Date.parse('01/01/2011 ' + saEndN);

            if (from > to) {
                toast.error("End time should be greater than start time")
            } else {
                
                const new_entry = {
                    day: 'saturday',
                    uuid: uuidv4(),
                    start_time: saStartN,
                    end_time: saEndN,
                    // schedule_id: currentEditScheduleId
                }
                setSaturdayTimingN((prevState) => [...prevState, new_entry])
                setSShowN(false)

                setSaStartN(null)
                setSaEndN(null)
            }
        }
        else if (day == 'sun') {
            let suStartN = sTime
            let suEndN = eTime
            let from = Date.parse('01/01/2011 ' + suStartN);
            let to = Date.parse('01/01/2011 ' + suEndN);

            if (from > to) {
                toast.error("End time should be greater than start time")
            } else {
                
                const new_entry = {
                    day: 'sunday',
                    uuid: uuidv4(),
                    start_time: suStartN,
                    end_time: suEndN,
                    // schedule_id: currentEditScheduleId
                }
                // sundayTimingN.push(new_entry)
                setSundayTimingN((prevState) => [...prevState, new_entry])
                setS2ShowN(false)

                setSuStartN(null)
                setSuEndN(null)
            }
        }
        else {
            return
        }

    }


    const handleEditClose = () => {
        setMondayTimingN([])
        setTuesdayTimingN([])
        setWednesdayTimingN([])
        setThursdayTimingN([])
        setFridayTimingN([])
        setSaturdayTimingN([])
        setSundayTimingN([])
        setMondayTimingE([])
        setTuesdayTimingE([])
        setWednesdayTimingE([])
        setThursdayTimingE([])
        setFridayTimingE([])
        setSaturdayTimingE([])
        setSundayTimingE([])
        // setScheduleName(null)
        setMoStartN(null)
        setMoEndN(null)
        setTuStartN(null)
        setTuEndN(null)
        setWeStartN(null)
        setWeEndN(null)
        setThStartN(null)
        setThEndN(null)
        setFrStartN(null)
        setFrEndN(null)
        setSaStartN(null)
        setSaEndN(null)
        setSuStartN(null)
        setSuEndN(null)
        setIsMoN(false)
        setIsTuN(false)
        setIsWeN(false)
        setIsThN(false)
        setIsFrN(false)
        setIsSaN(false)
        setIsSuN(false)
        setIsEditShow(false)
    };
    const handleEditShow = () => setIsEditShow(true);
    const handleEditCheckbox = (e, day) => {
        if (day == 'mon') {
            
            setIsMoN(e.target.checked)
        } else if (day == 'tue') {
            setIsTuN(e.target.checked)
        } else if (day == 'wed') {
            setIsWeN(e.target.checked)
        } else if (day == 'thu') {
            setIsThN(e.target.checked)
        } else if (day == 'fri') {
            setIsFrN(e.target.checked)
        } else if (day == 'sat') {
            setIsSaN(e.target.checked)
        }
        else if (day == 'sun') {
            setIsSuN(e.target.checked)
        }
        else {
            return
        }

    }
    // make efficient improve logic
    const handleCheckboxUpdation = () => {
        const days_isActive = [isMoN, isTuN, isWeN, isThN, isFrN, isSaN, isSuN]
        const days_name = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]
        for (let index = 0; index < days_isActive.length; index++) {
            const is_active = days_isActive[index]
            const day = days_name[index]
            ApiRequest.fetch({
                method: 'put',
                url: `${API_END_POINTS.SCHEDULEV2_UPDATE}/${currentEditScheduleId}`,
                data: { is_active, day }

                // params: { schedule_id : schedule_id}
            }).then((datas) => {
                ApiRequest.fetch({
                    method: 'put',
                    url: `${API_END_POINTS.SCHEDULEV2TIMETABLE_UPDATE_CHECKBOX}/${currentEditScheduleId}`,
                    data: { is_active, day }

                    // params: { schedule_id : schedule_id}
                }).then((data) => {
                }).catch(e => { })
            }).catch(e => { })

        }

    }
    const saveEditScheduleMain = async (e) => {
        e.preventDefault()
        if (isMoN && mondayTimingE.concat(mondayTimingN).length == 0) {
            return toast.error("Please enter monday entry or disable it")
        }
        if (isTuN && tuesdayTimingE.concat(tuesdayTimingN).length == 0) {
            return toast.error("Please enter tuesday entry or disable it")
        }
        if (isWeN && wednesdayTimingE.concat(wednesdayTimingN).length == 0) {
            return toast.error("Please enter wednesday entry or disable it")
        }
        if (isThN && thursdayTimingE.concat(thursdayTimingN).length == 0) {
            return toast.error("Please enter thursday entry or disable it")
        }
        if (isFrN && fridayTimingE.concat(fridayTimingN).length == 0) {
            return toast.error("Please enter friday entry or disable it")
        }
        if (isSaN && saturdayTimingE.concat(saturdayTimingN).length == 0) {
            return toast.error("Please enter saturday entry or disable it")
        }
        if (isSuN && sundayTimingE.concat(sundayTimingN).length == 0) {
            return toast.error("Please enter sunday entry or disable it")
        }
        setIsOpenConfirm(true)
    }

    const handleOnConfirmCancel = () => {
        setIsOpenConfirm(false)
    }
    const handleOnDeleteCancel = () => {
        setIsOpenDelete(false)
    }
    function preferredOrder(obj, order) {
        var newObject = {};
        for (var i = 0; i < order.length; i++) {
            if (obj.hasOwnProperty(order[i])) {
                newObject[order[i]] = obj[order[i]];
            }
        }
        return newObject;
    }

    const handleNew = async () => {
        
        let timingsCombined = []
        const s_id = uuidv4()

        if (mondayTimingN.length > 0) {
            // mondayTiming.isActive = isMo
            const calcTiming = mondayTimingN.map(v => ({ ...v, isActive: isMoN, schedule_id: s_id }))
            timingsCombined = [...timingsCombined, ...calcTiming]
        }
        if (tuesdayTimingN.length > 0) {
            const calcTiming = tuesdayTimingN.map(v => ({ ...v, isActive: isTuN, schedule_id: s_id }))

            timingsCombined = [...timingsCombined, ...calcTiming]
        }
        if (wednesdayTimingN.length > 0) {
            const calcTiming = wednesdayTimingN.map(v => ({ ...v, isActive: isWeN, schedule_id: s_id }))

            timingsCombined = [...timingsCombined, ...calcTiming]
        }
        if (thursdayTimingN.length > 0) {
            const calcTiming = thursdayTimingN.map(v => ({ ...v, isActive: isThN, schedule_id: s_id }))

            timingsCombined = [...timingsCombined, ...calcTiming]
        }
        if (fridayTimingN.length > 0) {
            const calcTiming = fridayTimingN.map(v => ({ ...v, isActive: isFrN, schedule_id: s_id }))

            timingsCombined = [...timingsCombined, ...calcTiming]
        }
        if (saturdayTimingN.length > 0) {
            const calcTiming = saturdayTimingN.map(v => ({ ...v, isActive: isSaN, schedule_id: s_id }))

            timingsCombined = [...timingsCombined, ...calcTiming]
        }
        if (sundayTimingN.length > 0) {
            const calcTiming = sundayTimingN.map(v => ({ ...v, isActive: isSuN, schedule_id: s_id }))
            timingsCombined = [...timingsCombined, ...calcTiming]
            
        }
        if (mondayTimingE.length > 0) {
            // mondayTiming.isActive = isMo
            const calcTiming = mondayTimingE.map(v => {
                delete v.is_active
                delete v.agency_id
                delete v.id
                delete v.name
                v.uuid = uuidv4()
                return preferredOrder({ ...v, isActive: isMoN, schedule_id: s_id }, [
                    "day", "uuid", "start_time", "end_time", "isActive", "schedule_id", "agency_id"
                ]);
            })
            timingsCombined = [...timingsCombined, ...calcTiming]
        }
        if (tuesdayTimingE.length > 0) {
            const calcTiming = tuesdayTimingE.map(v => {
                delete v.is_active
                delete v.agency_id
                delete v.id
                delete v.name
                v.uuid = uuidv4()
                return preferredOrder({ ...v, isActive: isTuN, schedule_id: s_id }, ["day", "uuid", "start_time", "end_time", "isActive", "schedule_id", "agency_id"])
            })

            timingsCombined = [...timingsCombined, ...calcTiming]
        }
        if (wednesdayTimingE.length > 0) {
            const calcTiming = wednesdayTimingE.map(v => {
                delete v.is_active
                delete v.agency_id
                delete v.id
                delete v.name
                v.uuid = uuidv4()
                return preferredOrder({ ...v, isActive: isWeN, schedule_id: s_id }, ["day", "uuid", "start_time", "end_time", "isActive", "schedule_id", "agency_id"])
            })

            timingsCombined = [...timingsCombined, ...calcTiming]
        }
        if (thursdayTimingE.length > 0) {
            const calcTiming = thursdayTimingE.map(v => {
                delete v.is_active
                delete v.agency_id
                delete v.id
                delete v.name
                v.uuid = uuidv4()
                return preferredOrder({ ...v, isActive: isThN, schedule_id: s_id }, ["day", "uuid", "start_time", "end_time", "isActive", "schedule_id", "agency_id"])


            })

            timingsCombined = [...timingsCombined, ...calcTiming]
        }
        if (fridayTimingE.length > 0) {
            const calcTiming = fridayTimingE.map(v => {
                delete v.is_active
                delete v.agency_id
                delete v.id
                delete v.name
                v.uuid = uuidv4()
                return preferredOrder({ ...v, isActive: isFrN, schedule_id: s_id }, ["day", "uuid", "start_time", "end_time", "isActive", "schedule_id", "agency_id"])
            })



            timingsCombined = [...timingsCombined, ...calcTiming]
        }
        if (saturdayTimingE.length > 0) {
            const calcTiming = saturdayTimingE.map(v => {
                delete v.is_active
                delete v.agency_id
                delete v.id
                delete v.name
                v.uuid = uuidv4()
                return preferredOrder({ ...v, isActive: isSaN, schedule_id: s_id }, ["day", "uuid", "start_time", "end_time", "isActive", "schedule_id", "agency_id"])
            })



            timingsCombined = [...timingsCombined, ...calcTiming]
        }
        if (sundayTimingE.length > 0) {
            const calcTiming = sundayTimingE.map(v => {
                delete v.is_active
                delete v.agency_id
                delete v.id
                delete v.name
                v.uuid = uuidv4()
                return preferredOrder({ ...v, isActive: isSuN, schedule_id: s_id }, ["day", "uuid", "start_time", "end_time", "isActive", "schedule_id", "agency_id"])
            })


            timingsCombined = [...timingsCombined, ...calcTiming]
            
        }
        // const timingsCombined = [...mondayTiming, ...tuesdayTiming, ...wednesdayTiming, ...thursdayTiming, ...fridayTiming, ...saturdayTiming, ...sundayTiming];
        // 
        if (timingsCombined.length == 0) {
            // what about if only checkbox?
            // toast.error(`Enter atleast 1 New Timing Record to Save!`)
            // handleCheckboxUpdation()
            // await updateNameinExisting(s_id)
            setRefresh(!refresh)
            handleEditClose()
            setIsOpenConfirm(false)
            


        }
        else {
            // update existing update
            await handleScheduleSave(timingsCombined, currentEditSchedule)
            // await handleCheckboxUpdation()
            // method to update name in schedulesv2 and schedulesv2timetable
            // await updateNameinExisting(s_id)
            setRefresh(!refresh)
            handleEditClose()
            setIsOpenConfirm(false)


        }


    }

    const saveEditSchedule = async (e) => {
        
        
        // for (let index = 0; index < timingsToDelete.length; index++) {
        //     const element = timingsToDelete[index];
        //     
        //     await handleScheduleRecordDelete(element)
        // }
        const editResult = Object.values(
            timingsToUpdate.reduce((accumulator, item) => {
              const { uuid, ...rest } = item;
              return {
                ...accumulator,
                [uuid]: { uuid, ...rest }
              };
            }, {})
          );
        for (let index = 0; index < editResult.length; index++) {
            const {uuid, Start, End, schedule_id} = editResult[index];
            updateExistingRecords(uuid, Start, End, schedule_id)
        }
        // handleScheduleRecordDelete()
        // e.preventDefault()

        //  return
        let timingsCombined = []
        const s_id = currentEditScheduleId

        if (mondayTimingN.length > 0) {
            // mondayTiming.isActive = isMo
            const calcTiming = mondayTimingN.map(v => ({ ...v, isActive: isMoN, schedule_id: s_id }))
            timingsCombined = [...timingsCombined, ...calcTiming]
        }
        if (tuesdayTimingN.length > 0) {
            const calcTiming = tuesdayTimingN.map(v => ({ ...v, isActive: isTuN, schedule_id: s_id }))

            timingsCombined = [...timingsCombined, ...calcTiming]
        }
        if (wednesdayTimingN.length > 0) {
            const calcTiming = wednesdayTimingN.map(v => ({ ...v, isActive: isWeN, schedule_id: s_id }))

            timingsCombined = [...timingsCombined, ...calcTiming]
        }
        if (thursdayTimingN.length > 0) {
            const calcTiming = thursdayTimingN.map(v => ({ ...v, isActive: isThN, schedule_id: s_id }))

            timingsCombined = [...timingsCombined, ...calcTiming]
        }
        if (fridayTimingN.length > 0) {
            const calcTiming = fridayTimingN.map(v => ({ ...v, isActive: isFrN, schedule_id: s_id }))

            timingsCombined = [...timingsCombined, ...calcTiming]
        }
        if (saturdayTimingN.length > 0) {
            const calcTiming = saturdayTimingN.map(v => ({ ...v, isActive: isSaN, schedule_id: s_id }))

            timingsCombined = [...timingsCombined, ...calcTiming]
        }
        if (sundayTimingN.length > 0) {
            const calcTiming = sundayTimingN.map(v => ({ ...v, isActive: isSuN, schedule_id: s_id }))
            timingsCombined = [...timingsCombined, ...calcTiming]
            
        }
        // const timingsCombined = [...mondayTiming, ...tuesdayTiming, ...wednesdayTiming, ...thursdayTiming, ...fridayTiming, ...saturdayTiming, ...sundayTiming];
        // 
        if (timingsCombined.length == 0) {
            // what about if only checkbox?
            // toast.error(`Enter atleast 1 New Timing Record to Save!`)
            handleCheckboxUpdation()
            await updateNameinExisting(s_id)
            setRefresh(!refresh)
            handleEditClose()
            setIsOpenConfirm(false)


        }
        else {
            // update existing update
            await handleEditScheduleSave(timingsCombined, currentEditSchedule)
            await handleCheckboxUpdation()
            // method to update name in schedulesv2 and schedulesv2timetable
            await updateNameinExisting(s_id)
            setRefresh(!refresh)
            handleEditClose()
            setIsOpenConfirm(false)


        }
        for (let index = 0; index < timingsToDelete.length; index++) {
            const element = timingsToDelete[index];
            
            await handleScheduleRecordDelete(element)
        }
    }
    // TODO:  agency id in controller , isactive from frontend

    const edit_timings = (e, day, uuid, Start, End) => {
        // e.preventDefault()
        if (day == 'mon') {

            let from = Date.parse('01/01/2011 ' + Start);
            let to = Date.parse('01/01/2011 ' + End);

            if (from >= to) {
                return toast.error("End time should be greater than start time")
            } else {
                
                var tim = mondayTiming.find(b => b.uuid === uuid);
                if (tim) {
                    tim.start_time = Start;
                    tim.end_time = End
                }
                setMondayTiming((prevState) => [...mondayTiming])

            }
        } else if (day == 'tue') {
            let from = Date.parse('01/01/2011 ' + Start);
            let to = Date.parse('01/01/2011 ' + End);

            if (from >= to) {
                toast.error("End time should be greater than start time")
            } else {
                
                var tim = tuesdayTiming.find(b => b.uuid === uuid);
                if (tim) {
                    tim.start_time = Start;
                    tim.end_time = End
                }
                setTuesdayTiming((prevState) => [...tuesdayTiming])
            }
        } else if (day == 'wed') {
            let from = Date.parse('01/01/2011 ' + Start);
            let to = Date.parse('01/01/2011 ' + End);

            if (from >= to) {
                toast.error("End time should be greater than start time")
            } else {
                
                var tim = wednesdayTiming.find(b => b.uuid === uuid);
                if (tim) {
                    tim.start_time = Start;
                    tim.end_time = End
                }
                setWednesdayTiming((prevState) => [...wednesdayTiming])
            }
        } else if (day == 'thu') {
            let from = Date.parse('01/01/2011 ' + Start);
            let to = Date.parse('01/01/2011 ' + End);

            if (from >= to) {
                toast.error("End time should be greater than start time")
            } else {
                
                var tim = thursdayTiming.find(b => b.uuid === uuid);
                if (tim) {
                    tim.start_time = Start;
                    tim.end_time = End
                }
                setThursdayTiming((prevState) => [...thursdayTiming])
            }
        } else if (day == 'fri') {
            let from = Date.parse('01/01/2011 ' + Start);
            let to = Date.parse('01/01/2011 ' + End);

            if (from >= to) {
                toast.error("End time should be greater than start time")
            } else {
                
                var tim = fridayTiming.find(b => b.uuid === uuid);
                if (tim) {
                    tim.start_time = Start;
                    tim.end_time = End
                }
                setFridayTiming((prevState) => [...fridayTiming])
            }
        } else if (day == 'sat') {
            let from = Date.parse('01/01/2011 ' + Start);
            let to = Date.parse('01/01/2011 ' + End);

            if (from >= to) {
                toast.error("End time should be greater than start time")
            } else {
                if (from > to) {
                    toast.error("End time should be greater than start time")
                } else {
                    
                    var tim = saturdayTiming.find(b => b.uuid === uuid);
                    if (tim) {
                        tim.start_time = Start;
                        tim.end_time = End
                    }
                    setSaturdayTiming((prevState) => [...saturdayTiming])
                }
            }
        }
        else if (day == 'sun') {
            let from = Date.parse('01/01/2011 ' + Start);
            let to = Date.parse('01/01/2011 ' + End);

            if (from >= to) {
                toast.error("End time should be greater than start time")
            } else {
                
                var tim = sundayTiming.find(b => b.uuid === uuid);
                if (tim) {
                    tim.start_time = Start;
                    tim.end_time = End
                }
                setSundayTiming((prevState) => [...sundayTiming])
            }
        }
        else {
            return
        }

    }

const updateExistingRecords = (uuid, Start, End, schedule_id) => {
    //    edit directly in DB
                            ApiRequest.fetch({
                                method: 'put',
                                url: `${API_END_POINTS.SCHEDULEV2_UPDATE_TIME}/${uuid}`,

                                data: { start_time: Start, end_time: End }

                                // params: { schedule_id : schedule_id}
                            }).then(async (datas) => {
                                // setMondayTimingE((prevState) => [...mondayTimingE])
                                
                                await findScheduleDetail(schedule_id)
                                ApiRequest.fetch({
                                    method: 'put',
                                    url: `${API_END_POINTS.SCHEDULEV2TIMETABLE_UPDATE_TIME}/${uuid}`,
                                    data: { start_time: Start, end_time: End }

                                    // params: { schedule_id : schedule_id}
                                }).then((data) => {

                                }).catch(e => { })
                            }).catch(e => { })

}

    const edit_timings_time = (e, day, uuid, Start, End, schedule_id) => {
        const updatedData = {uuid, Start, End, schedule_id}
                            setTimingsToUpdate(result => [...result, updatedData]);
                            
        // e.preventDefault()
        if (day == 'mon') {


            let from = Date.parse('01/01/2011 ' + Start);
            let to = Date.parse('01/01/2011 ' + End);

            if (from >= to) {
                toast.error("End time should be greater than start time")
            } else {
                if (mondayTimingE.length > 0) {
                    mondayTimingE.some(element => {
                        if (element.uuid == uuid) {

                            // local test start
                            const tim = mondayTimingE.find(b => b.uuid === uuid);
                            if (tim) {
                                tim.start_time = Start;
                                tim.end_time = End
                            }
                            
                            setMondayTimingE((prevState) => [...mondayTimingE])
                            // local test end

                            // //    edit directly in DB
                            // ApiRequest.fetch({
                            //     method: 'put',
                            //     url: `${API_END_POINTS.SCHEDULEV2_UPDATE_TIME}/${uuid}`,

                            //     data: { start_time: Start, end_time: End }

                            //     // params: { schedule_id : schedule_id}
                            // }).then(async (datas) => {
                            //     // setMondayTimingE((prevState) => [...mondayTimingE])
                            //     
                            //     await findScheduleDetail(schedule_id)
                            //     ApiRequest.fetch({
                            //         method: 'put',
                            //         url: `${API_END_POINTS.SCHEDULEV2TIMETABLE_UPDATE_TIME}/${uuid}`,
                            //         data: { start_time: Start, end_time: End }

                            //         // params: { schedule_id : schedule_id}
                            //     }).then((data) => {

                            //     }).catch(e => { })
                            // }).catch(e => { })

                            // e
                        }
                        else {
                            
                            var tim = mondayTimingN.find(b => b.uuid === uuid);
                            if (tim) {
                                tim.start_time = Start;
                                tim.end_time = End
                            }
                            setMondayTimingN((prevState) => [...mondayTimingN])
                        }
                    })
                }
                else {
                    
                    var tim = mondayTimingN.find(b => b.uuid === uuid);
                    if (tim) {
                        tim.start_time = Start;
                        tim.end_time = End
                    }
                    setMondayTimingN((prevState) => [...mondayTimingN])
                }
            }
        } else if (day == 'tue') {
            let from = Date.parse('01/01/2011 ' + Start);
            let to = Date.parse('01/01/2011 ' + End);

            if (from >= to) {
                toast.error("End time should be greater than start time")
            } else {
                if (tuesdayTimingE.length > 0) {

                    tuesdayTimingE.some(element => {
                        if (element.uuid == uuid) {

                            const tim = tuesdayTimingE.find(b => b.uuid === uuid);
                            if (tim) {
                                tim.start_time = Start;
                                tim.end_time = End
                            }
                            
                            setTuesdayTimingE((prevState) => [...tuesdayTimingE])
                            //    edit directly in DB
                            // ApiRequest.fetch({
                            //     method: 'put',
                            //     url: `${API_END_POINTS.SCHEDULEV2_UPDATE_TIME}/${uuid}`,

                            //     data: { start_time: Start, end_time: End }

                            //     // params: { schedule_id : schedule_id}
                            // }).then(async (datas) => {
                            //     // setMondayTimingE((prevState) => [...mondayTimingE])
                            //     
                            //     await findScheduleDetail(schedule_id)
                            //     ApiRequest.fetch({
                            //         method: 'put',
                            //         url: `${API_END_POINTS.SCHEDULEV2TIMETABLE_UPDATE_TIME}/${uuid}`,
                            //         data: { start_time: Start, end_time: End }

                            //         // params: { schedule_id : schedule_id}
                            //     }).then((data) => {

                            //     }).catch(e => { })
                            // }).catch(e => { })

                            // e
                        }
                        else {
                            
                            var tim = tuesdayTimingN.find(b => b.uuid === uuid);
                            if (tim) {
                                tim.start_time = Start;
                                tim.end_time = End
                            }
                            setTuesdayTimingN((prevState) => [...tuesdayTimingN])
                        }
                    })
                } else {
                    var tim = tuesdayTimingN.find(b => b.uuid === uuid);
                    if (tim) {
                        tim.start_time = Start;
                        tim.end_time = End
                    }
                    setTuesdayTimingN((prevState) => [...tuesdayTimingN])

                }


            }
        } else if (day == 'wed') {
            let from = Date.parse('01/01/2011 ' + Start);
            let to = Date.parse('01/01/2011 ' + End);

            if (from >= to) {
                toast.error("End time should be greater than start time")
            } else {
                if (wednesdayTimingE.length > 0) {
                    wednesdayTimingE.some(element => {
                        if (element.uuid == uuid) {
                            const tim = wednesdayTimingE.find(b => b.uuid === uuid);
                            if (tim) {
                                tim.start_time = Start;
                                tim.end_time = End
                            }
                            
                            setWednesdayTimingE((prevState) => [...wednesdayTimingE])
                            //    edit directly in DB
                            // ApiRequest.fetch({
                            //     method: 'put',
                            //     url: `${API_END_POINTS.SCHEDULEV2_UPDATE_TIME}/${uuid}`,

                            //     data: { start_time: Start, end_time: End }

                            //     // params: { schedule_id : schedule_id}
                            // }).then(async (datas) => {
                            //     // setMondayTimingE((prevState) => [...mondayTimingE])
                            //     
                            //     await findScheduleDetail(schedule_id)
                            //     ApiRequest.fetch({
                            //         method: 'put',
                            //         url: `${API_END_POINTS.SCHEDULEV2TIMETABLE_UPDATE_TIME}/${uuid}`,
                            //         data: { start_time: Start, end_time: End }

                            //         // params: { schedule_id : schedule_id}
                            //     }).then((data) => {

                            //     }).catch(e => { })
                            // }).catch(e => { })

                            // e
                        }
                        else {
                            
                            var tim = wednesdayTimingN.find(b => b.uuid === uuid);
                            if (tim) {
                                tim.start_time = Start;
                                tim.end_time = End
                            }
                            setWednesdayTimingN((prevState) => [...wednesdayTimingN])
                        }
                    })
                }
                else {
                    
                    var tim = wednesdayTimingN.find(b => b.uuid === uuid);
                    if (tim) {
                        tim.start_time = Start;
                        tim.end_time = End
                    }
                    setWednesdayTimingN((prevState) => [...wednesdayTimingN])
                }

            }
        } else if (day == 'thu') {
            let from = Date.parse('01/01/2011 ' + Start);
            let to = Date.parse('01/01/2011 ' + End);

            if (from >= to) {
                toast.error("End time should be greater than start time")
            } else {
                if (thursdayTimingE.length > 0) {
                    thursdayTimingE.some(element => {
                        if (element.uuid == uuid) {
                            const tim = thursdayTimingE.find(b => b.uuid === uuid);
                            if (tim) {
                                tim.start_time = Start;
                                tim.end_time = End
                            }
                            
                            setThursdayTimingE((prevState) => [...thursdayTimingE])
                            //    edit directly in DB
                            // ApiRequest.fetch({
                            //     method: 'put',
                            //     url: `${API_END_POINTS.SCHEDULEV2_UPDATE_TIME}/${uuid}`,

                            //     data: { start_time: Start, end_time: End }

                            //     // params: { schedule_id : schedule_id}
                            // }).then(async (datas) => {
                            //     // setMondayTimingE((prevState) => [...mondayTimingE])
                            //     
                            //     await findScheduleDetail(schedule_id)
                            //     ApiRequest.fetch({
                            //         method: 'put',
                            //         url: `${API_END_POINTS.SCHEDULEV2TIMETABLE_UPDATE_TIME}/${uuid}`,
                            //         data: { start_time: Start, end_time: End }

                            //         // params: { schedule_id : schedule_id}
                            //     }).then((data) => {

                            //     }).catch(e => { })
                            // }).catch(e => { })

                            // e
                        }
                        else {
                            
                            var tim = thursdayTimingN.find(b => b.uuid === uuid);
                            if (tim) {
                                tim.start_time = Start;
                                tim.end_time = End
                            }
                            setThursdayTimingN((prevState) => [...thursdayTimingN])
                        }
                    })
                }
                else {
                    
                    var tim = thursdayTimingN.find(b => b.uuid === uuid);
                    if (tim) {
                        tim.start_time = Start;
                        tim.end_time = End
                    }
                    setThursdayTimingN((prevState) => [...thursdayTimingN])
                }
            }
        } else if (day == 'fri') {
            let from = Date.parse('01/01/2011 ' + Start);
            let to = Date.parse('01/01/2011 ' + End);

            if (from >= to) {
                toast.error("End time should be greater than start time")
            } else {
                if (fridayTimingE.length > 0) {
                    fridayTimingE.some(element => {
                        if (element.uuid == uuid) {
                            const tim = fridayTimingE.find(b => b.uuid === uuid);
                            if (tim) {
                                tim.start_time = Start;
                                tim.end_time = End
                            }
                            
                            setFridayTimingE((prevState) => [...fridayTimingE])
                            //    edit directly in DB
                            // ApiRequest.fetch({
                            //     method: 'put',
                            //     url: `${API_END_POINTS.SCHEDULEV2_UPDATE_TIME}/${uuid}`,

                            //     data: { start_time: Start, end_time: End }

                            //     // params: { schedule_id : schedule_id}
                            // }).then(async (datas) => {
                            //     // setMondayTimingE((prevState) => [...mondayTimingE])
                            //     
                            //     await findScheduleDetail(schedule_id)
                            //     ApiRequest.fetch({
                            //         method: 'put',
                            //         url: `${API_END_POINTS.SCHEDULEV2TIMETABLE_UPDATE_TIME}/${uuid}`,
                            //         data: { start_time: Start, end_time: End }

                            //         // params: { schedule_id : schedule_id}
                            //     }).then((data) => {

                            //     }).catch(e => { })
                            // }).catch(e => { })

                            // e
                        }
                        else {
                            
                            var tim = fridayTimingN.find(b => b.uuid === uuid);
                            if (tim) {
                                tim.start_time = Start;
                                tim.end_time = End
                            }
                            setFridayTimingN((prevState) => [...fridayTimingN])
                        }
                    })
                }
                else {
                    
                    var tim = fridayTimingN.find(b => b.uuid === uuid);
                    if (tim) {
                        tim.start_time = Start;
                        tim.end_time = End
                    }
                    setFridayTimingN((prevState) => [...fridayTimingN])
                }

            }
        } else if (day == 'sat') {
            let from = Date.parse('01/01/2011 ' + Start);
            let to = Date.parse('01/01/2011 ' + End);

            if (from >= to) {
                toast.error("End time should be greater than start time")
            } else {
                if (saturdayTimingE.length > 0) {
                    saturdayTimingE.some(element => {
                        if (element.uuid == uuid) {
                            const tim = saturdayTimingE.find(b => b.uuid === uuid);
                            if (tim) {
                                tim.start_time = Start;
                                tim.end_time = End
                            }
                            
                            setSaturdayTimingE((prevState) => [...saturdayTimingE])
                            //    edit directly in DB
                            // ApiRequest.fetch({
                            //     method: 'put',
                            //     url: `${API_END_POINTS.SCHEDULEV2_UPDATE_TIME}/${uuid}`,

                            //     data: { start_time: Start, end_time: End }

                            //     // params: { schedule_id : schedule_id}
                            // }).then(async (datas) => {
                            //     // setMondayTimingE((prevState) => [...mondayTimingE])
                            //     
                            //     await findScheduleDetail(schedule_id)
                            //     ApiRequest.fetch({
                            //         method: 'put',
                            //         url: `${API_END_POINTS.SCHEDULEV2TIMETABLE_UPDATE_TIME}/${uuid}`,
                            //         data: { start_time: Start, end_time: End }

                            //         // params: { schedule_id : schedule_id}
                            //     }).then((data) => {

                            //     }).catch(e => { })
                            // }).catch(e => { })

                            // e
                        }
                        else {
                            
                            var tim = saturdayTimingN.find(b => b.uuid === uuid);
                            if (tim) {
                                tim.start_time = Start;
                                tim.end_time = End
                            }
                            setSaturdayTimingN((prevState) => [...saturdayTimingN])
                        }
                    })
                }
                else {
                    
                    var tim = saturdayTimingN.find(b => b.uuid === uuid);
                    if (tim) {
                        tim.start_time = Start;
                        tim.end_time = End
                    }
                    setSaturdayTimingN((prevState) => [...saturdayTimingN])
                }

            }
        }
        else if (day == 'sun') {
            let from = Date.parse('01/01/2011 ' + Start);
            let to = Date.parse('01/01/2011 ' + End);

            if (from >= to) {
                toast.error("End time should be greater than start time")
            } else {
                if (sundayTimingE.length > 0) {
                    sundayTimingE.some(element => {
                        if (element.uuid == uuid) {
                            const tim = sundayTimingE.find(b => b.uuid === uuid);
                            if (tim) {
                                tim.start_time = Start;
                                tim.end_time = End
                            }
                            
                            setSundayTimingE((prevState) => [...sundayTimingE])
                            //    edit directly in DB
                            // ApiRequest.fetch({
                            //     method: 'put',
                            //     url: `${API_END_POINTS.SCHEDULEV2_UPDATE_TIME}/${uuid}`,

                            //     data: { start_time: Start, end_time: End }

                            //     // params: { schedule_id : schedule_id}
                            // }).then(async (datas) => {
                            //     // setMondayTimingE((prevState) => [...mondayTimingE])
                            //     
                            //     await findScheduleDetail(schedule_id)
                            //     ApiRequest.fetch({
                            //         method: 'put',
                            //         url: `${API_END_POINTS.SCHEDULEV2TIMETABLE_UPDATE_TIME}/${uuid}`,
                            //         data: { start_time: Start, end_time: End }

                            //         // params: { schedule_id : schedule_id}
                            //     }).then((data) => {

                            //     }).catch(e => { })
                            // }).catch(e => { })

                            // e
                        }
                        else {
                            
                            var tim = sundayTimingN.find(b => b.uuid === uuid);
                            if (tim) {
                                tim.start_time = Start;
                                tim.end_time = End
                            }
                            setSundayTimingN((prevState) => [...sundayTimingN])
                        }
                    })
                }
                else {
                    
                    var tim = sundayTimingN.find(b => b.uuid === uuid);
                    if (tim) {
                        tim.start_time = Start;
                        tim.end_time = End
                    }
                    setSundayTimingN((prevState) => [...sundayTimingN])
                }
            }
        }
        else {
            return
        }

    }

    const add_timings = (e, day, sTime, eTime) => {
        
        // e.preventDefault()
        if (day == 'mon') {
            let moStart = sTime
            let moEnd = eTime
            
            
            let from = Date.parse('01/01/2011 ' + moStart);
            let to = Date.parse('01/01/2011 ' + moEnd);

            if (from > to) {
                toast.error("End time should be greater than start time")
            } else {
                

                const new_entry = {
                    day: 'monday',
                    uuid: uuidv4(),
                    start_time: moStart,
                    end_time: moEnd,
                    // schedule_id: uuidv4()
                }
                mondayTiming.push(new_entry)
                setMoStart(null)
                setMoEnd(null)
                setMShow(false)
                
            }
        } else if (day == 'tue') {
            let tuStart = sTime
            let tuEnd = eTime
            let from = Date.parse('01/01/2011 ' + tuStart);
            let to = Date.parse('01/01/2011 ' + tuEnd);

            if (from > to) {
                toast.error("End time should be greater than start time")
            } else {
                
                const new_entry = {
                    day: 'tuesday',
                    uuid: uuidv4(),
                    start_time: tuStart,
                    end_time: tuEnd,
                    // schedule_id: uuidv4()
                }
                tuesdayTiming.push(new_entry)
                setTuStart(null)
                setTuEnd(null)
                setTShow(false)

            }
        } else if (day == 'wed') {
            let weStart = sTime
            let weEnd = eTime
            let from = Date.parse('01/01/2011 ' + weStart);
            let to = Date.parse('01/01/2011 ' + weEnd);

            if (from > to) {
                toast.error("End time should be greater than start time")
            } else {
                
                const new_entry = {
                    day: 'wednesday',
                    uuid: uuidv4(),
                    start_time: weStart,
                    end_time: weEnd,
                    // schedule_id: uuidv4()
                }
                wednesdayTiming.push(new_entry)
                setWeStart(null)
                setWeEnd(null)
                setWShow(false)

            }
        } else if (day == 'thu') {
            let thStart = sTime
            let thEnd = eTime
            let from = Date.parse('01/01/2011 ' + thStart);
            let to = Date.parse('01/01/2011 ' + thEnd);

            if (from > to) {
                toast.error("End time should be greater than start time")
            } else {
                
                const new_entry = {
                    day: 'thursday',
                    uuid: uuidv4(),
                    start_time: thStart,
                    end_time: thEnd,
                    // schedule_id: uuidv4()
                }
                thursdayTiming.push(new_entry)
                setThStart(null)
                setThEnd(null)
                setT2Show(false)

            }
        } else if (day == 'fri') {
            let frStart = sTime
            let frEnd = eTime
            let from = Date.parse('01/01/2011 ' + frStart);
            let to = Date.parse('01/01/2011 ' + frEnd);

            if (from > to) {
                toast.error("End time should be greater than start time")
            } else {
                
                const new_entry = {
                    day: 'friday',
                    uuid: uuidv4(),
                    start_time: frStart,
                    end_time: frEnd,
                    // schedule_id: uuidv4()
                }
                fridayTiming.push(new_entry)
                setFrStart(null)
                setFrEnd(null)
                setFShow(false)

            }
        } else if (day == 'sat') {
            let saStart = sTime
            let saEnd = eTime
            let from = Date.parse('01/01/2011 ' + saStart);
            let to = Date.parse('01/01/2011 ' + saEnd);

            if (from > to) {
                toast.error("End time should be greater than start time")
            } else {
                
                const new_entry = {
                    day: 'saturday',
                    uuid: uuidv4(),
                    start_time: saStart,
                    end_time: saEnd,
                    // schedule_id: uuidv4()
                }
                saturdayTiming.push(new_entry)
                setSaStart(null)
                setSaEnd(null)
                setSShow(false)

            }
        }
        else if (day == 'sun') {
            let suStart = sTime
            let suEnd = eTime
            let from = Date.parse('01/01/2011 ' + suStart);
            let to = Date.parse('01/01/2011 ' + suEnd);

            if (from > to) {
                toast.error("End time should be greater than start time")
            } else {
                
                const new_entry = {
                    day: 'sunday',
                    uuid: uuidv4(),
                    start_time: suStart,
                    end_time: suEnd,
                    // schedule_id: uuidv4()
                }
                sundayTiming.push(new_entry)
                setSuStart(null)
                setSuEnd(null)
                setS2Show(false)

            }
        }
        else {
            return
        }

    }

    const getAllSchedule = () => {
        ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.SCHEDULEV2_GET_ALL}`,
            // data: { datan: datan, scheduleName: scheduleName }
        }).then((data) => {
            
            setSchedules(data)
        }).catch(e => { })
    }

    useEffect(() => {
        getAllSchedule()
    }, [refresh])

    const filteredSchedules = schedules
                                .filter((schedule) => schedule.name.toLowerCase().includes(search.toLowerCase()))
                                .sort((a, b) => a.name.localeCompare(b.name));

    const handleScheduleSave = (datan, scheduleName) => {
        ApiRequest.fetch({
            method: 'post',
            url: `${API_END_POINTS.SCHEDULEV2_CREATE}`,
            data: { datan: datan, scheduleName: scheduleName }
        }).then(() => {
            setShow(false)
            setTiming([])
            setMondayTiming([])
            setTuesdayTiming([])
            setWednesdayTiming([])
            setThursdayTiming([])
            setFridayTiming([])
            setSaturdayTiming([])
            setSundayTiming([])
            setScheduleName(null)
            setMoStart(null)
            setMoEnd(null)
            setTuStart(null)
            setTuEnd(null)
            setWeStart(null)
            setWeEnd(null)
            setThStart(null)
            setThEnd(null)
            setFrStart(null)
            setFrEnd(null)
            setSaStart(null)
            setSaEnd(null)
            setSuStart(null)
            setSuEnd(null)
            setIsMo(true)
            setIsTu(true)
            setIsWe(true)
            setIsTh(true)
            setIsFr(true)
            setIsSa(true)
            setIsSu(true)
            setRefresh(!refresh)
            toast.success(`Schedule Saved!`)

        }).catch(e => { })
    }
    const handleEditScheduleSave = (datan, scheduleName) => {
        

        ApiRequest.fetch({
            method: 'post',
            url: `${API_END_POINTS.SCHEDULEV2_CREATE2}`,
            data: { datan: datan, scheduleName: scheduleName }
        }).then((datan) => {
            
            ApiRequest.fetch({
                method: 'post',
                url: `${API_END_POINTS.SCHEDULEV2TIMETABLE_GET_DETAIL}`,
                data: { datan: datan }
            }).then((data) => {
                
                
                setShow(false)
                setTiming([])
                setMondayTiming([])
                setTuesdayTiming([])
                setWednesdayTiming([])
                setThursdayTiming([])
                setFridayTiming([])
                setSaturdayTiming([])
                setSundayTiming([])
                setScheduleName(null)
                setMoStart(null)
                setMoEnd(null)
                setTuStart(null)
                setTuEnd(null)
                setWeStart(null)
                setWeEnd(null)
                setThStart(null)
                setThEnd(null)
                setFrStart(null)
                setFrEnd(null)
                setSaStart(null)
                setSaEnd(null)
                setSuStart(null)
                setSuEnd(null)
                setIsMo(true)
                setIsTu(true)
                setIsWe(true)
                setIsTh(true)
                setIsFr(true)
                setIsSa(true)
                setIsSu(true)

                toast.success(`Schedule Saved!`)


            }).catch(e => { })
            // 
            // 
            // setShow(false)
            // setTiming([])
            // setMondayTiming([])
            // setTuesdayTiming([])
            // setWednesdayTiming([])
            // setThursdayTiming([])
            // setFridayTiming([])
            // setSaturdayTiming([])
            // setSundayTiming([])
            // setScheduleName(null)
            // setMoStart(null)
            // setMoEnd(null)
            // setTuStart(null)
            // setTuEnd(null)
            // setWeStart(null)
            // setWeEnd(null)
            // setThStart(null)
            // setThEnd(null)
            // setFrStart(null)
            // setFrEnd(null)
            // setSaStart(null)
            // setSaEnd(null)
            // setSuStart(null)
            // setSuEnd(null)
            // setIsMo(true)
            // setIsTu(true)
            // setIsWe(true)
            // setIsTh(true)
            // setIsFr(true)
            // setIsSa(true)
            // setIsSu(true)

            // toast.success(`Schedule Saved!`)

        }).catch(e => { })
    }


    const saveSchedule = (e) => {
        e.preventDefault()
        if (isMo && mondayTiming.length == 0) {
            return toast.error("Please enter monday entry or disable it")
        }
        if (isTu && tuesdayTiming.length == 0) {
            return toast.error("Please enter tuesday entry or disable it")
        }
        if (isWe && wednesdayTiming.length == 0) {
            return toast.error("Please enter wednesday entry or disable it")
        }
        if (isTh && thursdayTiming.length == 0) {
            return toast.error("Please enter thursday entry or disable it")
        }
        if (isFr && fridayTiming.length == 0) {
            return toast.error("Please enter friday entry or disable it")
        }
        if (isSa && saturdayTiming.length == 0) {
            return toast.error("Please enter saturday entry or disable it")
        }
        if (isSu && sundayTiming.length == 0) {
            return toast.error("Please enter sunday entry or disable it")
        }
        let timingsCombined = []
        const s_id = uuidv4()

        if (mondayTiming.length > 0) {
            // mondayTiming.isActive = isMo
            const calcTiming = mondayTiming.map(v => ({ ...v, isActive: isMo, schedule_id: s_id }))
            timingsCombined = [...timingsCombined, ...calcTiming]
        }
        if (tuesdayTiming.length > 0) {
            const calcTiming = tuesdayTiming.map(v => ({ ...v, isActive: isTu, schedule_id: s_id }))

            timingsCombined = [...timingsCombined, ...calcTiming]
        }
        if (wednesdayTiming.length > 0) {
            const calcTiming = wednesdayTiming.map(v => ({ ...v, isActive: isWe, schedule_id: s_id }))

            timingsCombined = [...timingsCombined, ...calcTiming]
        }
        if (thursdayTiming.length > 0) {
            const calcTiming = thursdayTiming.map(v => ({ ...v, isActive: isTh, schedule_id: s_id }))

            timingsCombined = [...timingsCombined, ...calcTiming]
        }
        if (fridayTiming.length > 0) {
            const calcTiming = fridayTiming.map(v => ({ ...v, isActive: isFr, schedule_id: s_id }))

            timingsCombined = [...timingsCombined, ...calcTiming]
        }
        if (saturdayTiming.length > 0) {
            const calcTiming = saturdayTiming.map(v => ({ ...v, isActive: isSa, schedule_id: s_id }))

            timingsCombined = [...timingsCombined, ...calcTiming]
        }
        if (sundayTiming.length > 0) {
            const calcTiming = sundayTiming.map(v => ({ ...v, isActive: isSu, schedule_id: s_id }))
            timingsCombined = [...timingsCombined, ...calcTiming]
            
        }
        // const timingsCombined = [...mondayTiming, ...tuesdayTiming, ...wednesdayTiming, ...thursdayTiming, ...fridayTiming, ...saturdayTiming, ...sundayTiming];
        // 
        if (timingsCombined.length == 0) {
            toast.error(`Enter atleast 1 Timing Record to Save!`)

        }
        else {
            handleScheduleSave(timingsCombined, scheduleName)
        }
    }
    const handleCheckbox = (e, day) => {
        if (day == 'mon') {
            
            setIsMo(e.target.checked)
        } else if (day == 'tue') {
            setIsTu(e.target.checked)
        } else if (day == 'wed') {
            setIsWe(e.target.checked)
        } else if (day == 'thu') {
            setIsTh(e.target.checked)
        } else if (day == 'fri') {
            setIsFr(e.target.checked)
        } else if (day == 'sat') {
            setIsSa(e.target.checked)
        }
        else if (day == 'sun') {
            setIsSu(e.target.checked)
        }
        else {
            return
        }

    }
    function sort_by_key(array, key) {
        return array.sort(function (a, b) {
            var x = moment(a[key],'h:mm a').format('X') ; var y = moment(a=b[key],'h:mm a').format('X');
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
        // return array.sort(function (a, b) {
        //     var x = a[key]; var y = b[key];
        //     return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        // });
        // 
     
    }
    const handleSearch = (e) => {
        setSearch(e.target.value)
    }
    const handledelete = (e,id) => {
        // e.preventDefault()
        e.stopPropagation();
        setIsEditShow(false)
        setIsOpenDelete(true)
        setScheduleToDelete(id)
    }

    const handleScheduleDelete = () => {
        // e.stopPropagation();
        const id  = scheduleToDelete
        ApiRequest.fetch({
            method: 'post',
            url: `${API_END_POINTS.SCHEDULEV2_DELETE}`,
            data: { id: id }
        }).then((datas) => {
            ApiRequest.fetch({
                method: 'post',
                url: `${API_END_POINTS.SCHEDULEV2TIMETABLE_DELETE}`,
                data: { id: id }
            }).then((data) => {
                
                setIsOpenDelete(false)
                setIsEditShow(false)
                setRefresh(!refresh)
                toast.success("Schedule Deleted!")
            }).catch(e => { })

        }).catch(e => { })
    }
    const handleScheduleRecordDelete = (id) => {
        // id is uuid of the schedule
        ApiRequest.fetch({
            method: 'post',
            url: `${API_END_POINTS.SCHEDULEV2_DELETE_RECORD}`,
            data: { id: id }
        }).then((datas) => {
            ApiRequest.fetch({
                method: 'post',
                url: `${API_END_POINTS.SCHEDULEV2TIMETABLE_DELETE_RECORD}`,
                data: { id: id }
            }).then((data) => {
                
                setRefresh(!refresh)
            }).catch(e => { })
            setRefresh(!refresh)
        }).catch(e => { })
    }
    const findScheduleDetail = (schedule_id) => {
        if (schedule_id) {


            
            ApiRequest.fetch({
                method: 'get',
                url: `${API_END_POINTS.SCHEDULEV2_FIND_ALL}/${schedule_id}`,
                // params: { schedule_id : schedule_id}
            }).then((data) => {
                setMondayTimingE([])
                setTuesdayTimingE([])
                setWednesdayTimingE([])
                setThursdayTimingE([])
                setFridayTimingE([])
                setSaturdayTimingE([])
                setSundayTimingE([])
                
                // setRefresh(!refresh)
                for (let index = 0; index < data.length; index++) {

                    if (data[index].day == 'monday') {
                        // mondayTimingE.push(data[index])
                        setMondayTimingE((prevState) => [...prevState, data[index]])
                        setIsMoN(data[index].is_active)
                        setMoStartN(null)
                        setMoEndN(null)
                        
                    } else if (data[index].day == 'tuesday') {

                        setTuesdayTimingE((prevState) => [...prevState, data[index]])
                        setIsTuN(data[index].is_active)

                        setTuStartN(null)
                        setTuEndN(null)
                        
                    } else if (data[index].day == 'wednesday') {

                        setWednesdayTimingE((prevState) => [...prevState, data[index]])
                        setIsWeN(data[index].is_active)

                        setWeStartN(null)
                        setWeEndN(null)

                    } else if (data[index].day == 'thursday') {
                        setThursdayTimingE((prevState) => [...prevState, data[index]])
                        setIsThN(data[index].is_active)

                        setThStartN(null)
                        setThEndN(null)
                    } else if (data[index].day == 'friday') {

                        setFridayTimingE((prevState) => [...prevState, data[index]])
                        setIsFrN(data[index].is_active)

                        setFrStartN(null)
                        setFrEndN(null)

                    } else if (data[index].day == 'saturday') {
                        setSaturdayTimingE((prevState) => [...prevState, data[index]])
                        setIsSaN(data[index].is_active)

                        setSaStartN(null)
                        setSaEndN(null)
                    }
                    else if (data[index].day == 'sunday') {
                        setSundayTimingE((prevState) => [...prevState, data[index]])
                        setIsSuN(data[index].is_active)

                        setSuStartN(null)
                        setSuEndN(null)
                    }
                    else {
                        return
                    }
                }

            }).catch(e => { })
        }
    }

    const populateSchedule = (e) => {
        e.preventDefault()
        if (!currentRoute || (!currentDirection && currentDirection !== 0) || !currentTimetableScheduleId) {
            toast.error("Fill all data to create a Timetable")
        }
        ApiRequest.fetch({
            method: 'post',
            url: `${API_END_POINTS.SCHEDULEV2TIMETABLE_CREATE}`,
            data: { route_id: currentRoute, direction_id: currentDirection, schedule_id: currentTimetableScheduleId, start_stop_id: currentStartId, end_stop_id: currentStopId }
        }).then(() => {
            toast.success("Timetable Added!")
            setCurrentEditSchedule('')
            setCurrentEditScheduleId('')
            findScheduleDetail('')
            setRefresh(!refresh)

        }).catch(e => { })
    }


    const handleScheduleOpen = (id, name, createdAt, updatedAt) => {
        setTimingsToDelete([])
        setCurrentEditSchedule(name)
        setCurrentEditScheduleId(id)
        setCurrentCreatedAt(createdAt)
        setCurrentUpdatedAt(updatedAt)
        findScheduleDetail(id)
        handleEditShow()
    }
    const handleGetAllRoute = async () => {
        ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.ROUTE_GET_ALL}`,

        }).then(async (data) => {
            const sortedData = data.sort((a, b) => a.shortName.localeCompare(b.shortName));
            await setRoutesArr(sortedData)
            // handleRouteData()
        }).catch(e => { })
    }
    useEffect(() => {
        handleGetAllRoute()

    }, [])
    const handleRouteSelection = (id) => {

        setCurrentRoute(id)
        
    }
    const handleDirectionSelection = (dir) => {
        
        if (dir == 'Outbound') {
            const result = currentStops.filter(obj => {
                return obj.directionId == 1
            })
            
            setCurrentStartId(result[0].id)
            setCurrentStopId(result[result.length - 1].id)

            setCurrentDirection(1)
        } else if (dir == 'Inbound') {
            const result = currentStops.filter(obj => {
                return obj.directionId == 2
            })
            
            setCurrentStartId(result[0].id)
            setCurrentStopId(result[result.length - 1].id)

            setCurrentDirection(2)

        }
        else if (dir == 'Loop') {
            setCurrentDirection(0)
            setCurrentStartId(currentStops[0].id)
            setCurrentStopId(currentStops[currentStops.length - 2].id)


        }
        else {
        }
    }
    const handleScheduleSelection = (id) => {
        setCurrentTimetableScheduleId(id)
    }
    useEffect(() => {
        //   fetch direction on current route
        setCurrentStopsOptions([])
        if (currentRoute) {
            ApiRequest.fetch({
                method: 'get',
                url: `${API_END_POINTS.ROUTE_GET_STOPS}/${currentRoute}`
            }).then((data) => {
                setCurrentStops(data.stops)
                if (data.stops[0].directionId == 0) {
                    setCurrentStopsOptions((prevState) => [...prevState, "Loop"])
                } else {
                    setCurrentStopsOptions((prevState) => [...prevState, "Inbound", "Outbound"])
                }

            }).catch(e => { })
        }
    }, [currentRoute])


    useEffect(() => {
        setAllTimetable({})
        ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.SCHEDULEV2TIMETABLE_GET_ALL}`,
        }).then((data) => {
            let group = groupBy(data, 'route_id');
            Object.keys(group).forEach(stateKey => {
                group[stateKey] = groupBy(group[stateKey], 'day');
                const groupNew = group
                setAllTimetable(prevState => ({
                    ...prevState,
                    group
                }))
            });
            
        }).catch(e => { })
    }, [refresh])

    const handleGetVehicles = () => {
        ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.VEHICLE_GET_ALL}`
        }).then((data) => {
            setVehicles(data.map(({ registrationNumber, id }) => ({ name: registrationNumber, id })))
        }).catch(e => { })
    }


    const handleGetDrivers = () => {
        ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.DRIVER_GET_ALL}`
        }).then((data) => {
            setDrivers(data.map(({ firstName, lastName, id }) => ({ name: `${firstName} ${lastName}`, id })))
        }).catch(e => { })
    }
    useEffect(() => {
        handleGetDrivers()
        handleGetVehicles()
    }, [])

    const handleDriverSelection = (driver_id, sct_id) => {
        if (!driver_id) {
            toast.error("please select Driver to update!")

        }

        ApiRequest.fetch({
            method: 'put',
            url: `${API_END_POINTS.SCHEDULEV2TIMETABLE_UPDATE}`,
            data: { scheduleId: sct_id, driverId: driver_id }

            // params: { schedule_id : schedule_id}
        }).then((data) => {
            toast.success("Driver Assigned!")
        }).catch(e => { })


    }
    const handleVehicleSelection = (vehicle_id, sct_id) => {
        if (!vehicle_id) {
            toast.error("please select Driver to update!")

        }
        ApiRequest.fetch({
            method: 'put',
            url: `${API_END_POINTS.SCHEDULEV2TIMETABLE_UPDATE}`,
            data: { scheduleId: sct_id, vehicleId: vehicle_id }

            // params: { schedule_id : schedule_id}
        }).then((data) => {
            toast.success("vehicle Assigned!")
        }).catch(e => { })

    }
    const navigation = useHistory()
    const handleRedirect = (id) => {
        navigation.push(`/schedule/editorial/${id}`)
    }


    const updateNameinExisting = () => {
        ApiRequest.fetch({
            method: 'put',
            url: `${API_END_POINTS.SCHEDULEV2_UPDATE_NAME}/${currentEditScheduleId}`,
            data: { name: currentEditSchedule }

            // params: { schedule_id : schedule_id}
        }).then((datas) => { }).catch(e => { })
        ApiRequest.fetch({
            method: 'put',
            url: `${API_END_POINTS.SCHEDULEV2TIMETABLE_UPDATE_NAME}/${currentEditScheduleId}`,
            data: { name: currentEditSchedule }

            // params: { schedule_id : schedule_id}
        }).then((datas) => { }).catch(e => { })
    }



    return (
        <>
            <EditScheduleModal
                title='Save'
                description={
                    <span>
                      <h5>  Choose from the below option to edit :</h5><br />
                        <Alert className='mt-0' variant='info' > <InfoCircle /> NOTE: Updating this schedule, will also Update Timetable associated with this schedule.<br></br>
                  </Alert>
                    </span>
                }
                onCancel={handleOnConfirmCancel}
                onConfirm={saveEditSchedule}
                onNew={handleNew}
                open={isOpenConfirm} />
            <DeleteModal
                title='Delete'
                description={
                    <span>
                       <h4> Are you sure, you want to delete the Schedule?</h4><br />
                        <Alert className='mt-0' variant='info' > <InfoCircle /> NOTE: Deleting this schedule, will also delete Timetable associated with this schedule.<br></br>
                  </Alert>
                    </span>
                }
                onCancel={handleOnDeleteCancel}
                onNew={handleScheduleDelete}
                open={isOpenDelete} />
            <h2 className="mb-3">Manage Schedules and TimeTable</h2>
            <Tabs
                defaultActiveKey="create-schedules"
                id="manage-schedules"
                className="mb-3"
            >
                <Tab eventKey="create-schedules" title="Manage schedules">
                    <Card onClick={handleShow}>
                        <Card.Body>
                            <Col className='pt-3'  >
                                <Card className='text-primary btn  h-100' >
                                    <Card.Body className='d-flex justify-content-center align-items-center' >
                                        <PlusCircle size={50} />
                                    </Card.Body>
                                    Add New Schedule
                                </Card>
                            </Col>
                        </Card.Body>
                    </Card>

                    <h2 style={{ display: 'inline-block' }} className='text-primary ml-4 mt-3'>  Created Schedules: </h2>
                    <div className="search-block">
                        <div>
                            <input
                                className='search'
                                type='search'
                                placeholder='Search Schedule'
                                onChange={handleSearch}
                            />
                        </div>
                    </div>
                    <div className='card-list'>
                        {filteredSchedules.map((schedule) => (
                            <div className='card-container' key={schedule.id} onClick={() => handleScheduleOpen(schedule.schedule_id, schedule.name, schedule.created_at, schedule.updated_at)}>

                                <h3>
                                    <Calendar2Check color="#2c278b" size={25} className='mr-1 ml-1'></Calendar2Check>
                                    {schedule.name}
                                    {/* <PencilSquare className="ml-5"></PencilSquare> */}
                                    <span className="ml-5 del-schedule">
                                        <Trash className="del-schedule-icon" onClick={(e) => handledelete(e, schedule.schedule_id)} color="#d11a2a" style={{position:'relative',zIndex: 9999,}}></Trash>
                                    </span>
                                </h3>

                            </div>

                        ))}
                    </div>
                </Tab>
                <Tab eventKey="select-route" title="Select Route">
                    <div>

                        <div className='card-list'>
                            {routesArr.map((schedule) => (
                                <div className='card-container' key={schedule.id} onClick={() => handleRedirect(schedule.id)}>

                                    <h3>
                                        <Shuffle color="#2c278b" size={25} className='mr-1 ml-1'></Shuffle>
                                        {`${schedule.shortName} - ${schedule.name}`}
                                        {/* <PencilSquare className="ml-5"></PencilSquare> */}

                                    </h3>

                                </div>

                            ))}
                        </div>

                    </div>

                </Tab>
                <Tab eventKey="trip-details" title="Scheduled Trips">
                    <div>

                        <div className='card-list'>
                        <ScheduleV2TripPage></ScheduleV2TripPage>
                        </div>

                    </div>

                </Tab>

            </Tabs>
            <Modal size='xl' centered show={show} onHide={handleClose} >
                <Form onSubmit={(e) => { saveSchedule(e) }}>
                    <Modal.Header closeButton={true}>
                        <Modal.Title>Add New Schedule:</Modal.Title>
                        <Form.Group className="ml-5 w-75">
                            <Form.Control required type='text' placeholder='Name The Schedule' value={scheduleName || ''} onChange={(e) => setScheduleName(e.target.value)} />
                        </Form.Group>
                        <button className='fare-discount-button col-md-2 ml-3'>Save Schedule</button>
                    </Modal.Header>
                </Form>
                <Modal.Body>
                    <div className='text-center' >
                        {/* new start */}
                        <Row className="align-items-center">
                            <Col xs={1}>
                                <Form.Check type="checkbox" style={{ marginBottom: '10px' }} checked={isMo} onChange={(e) => handleCheckbox(e, 'mon')} />
                            </Col>
                            <Col xs={11}>

                                <div style={{ width: '100%', height: '40px', border: '2px solid black', marginBottom: '15px', textAlign: 'center',opacity: isMo ? 1 : 0.4 }}><h4>Monday</h4></div>
                            </Col>
                        </Row>
                        {/* <Form onSubmit={(e) => { add_timings(e, "mon") }} > */}
                        <Form>
                            <Row className="align-items-center">

                                <Col xs={2} style={{
                                    width: '10.499999995%',
                                    flex: '0 0 10.499%', maxWidth: '10.499%', marginBottom: '60px'
                                }}>
                                    <Form.Label style={{ fontWeight: 'bold' }}>Trip No.</Form.Label><br></br>

                                    <Form.Group>
                                        <Form.Label style={{ fontWeight: 'bold' }} >Start Time</Form.Label>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label style={{ fontWeight: 'bold' }}>End Time</Form.Label>
                                    </Form.Group>
                                    <Form.Group>


                                    </Form.Group>
                                </Col>
                                <Col xs={10} style={{ opacity: isMo ? 1 : 0.4, pointerEvents: isMo ? '' : 'none' }}>
                                    <div className="scroll">
                                        <Row className="align-items-center scroll2">
                                            {mondayTiming.length > 0 && sort_by_key(mondayTiming, "start_time").map((timing, index) =>
                                                <Fragment key={timing.uuid}>
                                                    <Form.Group style={{ width: '100px', marginRight: '10px', marginLeft: '0px' }} key={timing.uuid}>
                                                        <Form.Label style={{ fontWeight: 'bold' }}>{index + 1}</Form.Label><br></br>
                                                        {/* <Form.Control type='time' value={timing.start_time} onChange={(e) => edit_timings(e, 'mon', timing.uuid, e.target.value, timing.end_time)} /> */}
                                                        <TimePicker
                                                            showSecond={false}
                                                            defaultValue={moment(timing.start_time, 'h:mm a')}
                                                            className="xxx"
                                                            onChange={(value) => edit_timings(e, 'mon', timing.uuid, value.format(format), timing.end_time)}
                                                            format={format}
                                                            value={moment(timing.start_time, 'h:mm a')}
                                                            use12Hours
                                                            allowEmpty={false}

                                                        />
                                                        <Form.Group className="mt-2">
                                                            <TimePicker
                                                                showSecond={false}
                                                                defaultValue={moment(timing.end_time, 'h:mm a')}
                                                                className="xxx"
                                                                onChange={(value) => edit_timings(e, 'mon', timing.uuid, timing.start_time, value.format(format))}
                                                                format={format}
                                                                value={moment(timing.end_time, 'h:mm a')}
                                                                use12Hours
                                                                allowEmpty={false}
                                                            />
                                                        </Form.Group>
                                                        {/* <Form.Control type='time' value={timing.end_time} onChange={(e) => edit_timings(e, 'mon', timing.uuid, timing.start_time, e.target.value)} /> */}
                                                        <Button onClick={(e) => setMondayTiming(mondayTiming.filter(item => item.uuid !== timing.uuid))} variant="danger" size="sm"> <TrashFill color=" #ffffff" size={20} /> </Button>

                                                    </Form.Group>

                                                </Fragment>
                                            )}
                                            {(mondayTiming.length == 0 || mShow) &&
                                                <Form.Group style={{ width: '100px', marginBottom: '10px' }}>
                                                    <TimePicker
                                                        showSecond={false}
                                                        className="xxx"
                                                        onChange={async (value) => {

                                                            await setMoStart(value)

                                                            await setMoEnd(value)

                                                            await add_timings(e, "mon", value.format(format), value.add(1, 'hours').format(format))
                                                        }}
                                                        format={format}
                                                        use12Hours
                                                        inputReadOnly
                                                        allowEmpty={false}
                                                    />
                                                    {/* <Form.Control type='time' max="23:00" value={moStart || ''} required onChange={async (e) => {
                                                        await setMoStart(e.target.value)

                                                        await setMoEnd(String(Number(String(e.target.value).substring(0, 2)) + 1) + String(e.target.value).slice(2))

                                                        await add_timings(e, "mon", e.target.value, (String(Number(String(e.target.value).substring(0, 2)) + 1)).toString().padStart(2, 0) + String(e.target.value).slice(2))


                                                    }
                                                    } /> */}
                                                    <Form.Group className="mt-2">

                                                        <Form.Control type='time' disabled={moStart ? 'false' : 'true'} value={moEnd || ''} required onChange={(e) => setMoEnd(e.target.value)} />
                                                    </Form.Group>
                                                </Form.Group>
                                            }
                                            <Button variant="primary" onClick={() => setMShow(true)} style={{ marginBottom: "45px" }} className="shadow-none btn bg-transparent btn-outline-transparent border-0">

                                                <div className="add-new-time">
                                                    <PlusCircleFill className="add-new-time-icon" style={{ marginLeft: 10 }} size={30} color='#2c278b' />
                                                </div>

                                            </Button>

                                        </Row>
                                    </div>
                                </Col>

                            </Row>
                        </Form>
                        {/* new end */}
                        {/* new start */}
                        <Row className="align-items-center">
                            <Col xs={1}>
                                {/* <Form.Check type="checkbox" style={{ marginBottom: '10px' }} checked={isMo} onChange={(e) => handleCheckbox(e, 'mon')} /> */}
                                <Form.Check style={{ marginBottom: '10px' }} type="checkbox" checked={isTu} onChange={(e) => handleCheckbox(e, 'tue')} />
                            </Col>
                            <Col xs={11}>

                                {/* <div style={{ width: '100%', height: '40px', border: '2px solid black', marginBottom: '15px', textAlign: 'center' }}><h4>Monday</h4></div> */}
                                <div style={{ width: '100%', height: '40px', border: '2px solid black', marginBottom: '15px', textAlign: 'center',opacity: isTu ? 1 : 0.4 }}><h4>Tuesday</h4></div>
                            </Col>
                        </Row>

                        {/* <Form onSubmit={(e) => { add_timings(e, "tue",) }} className='mt-4'> */}
                        <Form className='mt-4'>
                            <Row className="align-items-center">

                                <Col xs={2} style={{
                                    width: '10.499999995%',
                                    flex: '0 0 10.499%', maxWidth: '10.499%', marginBottom: '60px'
                                }}>
                                    <Form.Label style={{ fontWeight: 'bold' }}>Trip No.</Form.Label><br></br>

                                    <Form.Group>
                                        <Form.Label style={{ fontWeight: 'bold' }} >Start Time</Form.Label>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label style={{ fontWeight: 'bold' }}>End Time</Form.Label>
                                    </Form.Group>
                                    <Form.Group>


                                    </Form.Group>
                                </Col>
                                <Col xs={10} style={{ opacity: isTu ? 1 : 0.4, pointerEvents: isTu ? '' : 'none' }}>
                                    <div className="scroll">
                                        <Row className="align-items-center scroll2">
                                            {tuesdayTiming.length > 0 && sort_by_key(tuesdayTiming, "start_time").map((timing, index) =>
                                                <Form.Group style={{ width: '100px', marginRight: '10px', marginLeft: '0px' }} key={timing.uuid}>
                                                    <Form.Label style={{ fontWeight: 'bold' }}>{index + 1}</Form.Label><br></br>
                                                    <TimePicker
                                                        showSecond={false}
                                                        defaultValue={moment(timing.start_time, 'h:mm a')}
                                                        className="xxx"
                                                        onChange={(value) => edit_timings(e, 'tue', timing.uuid, value.format(format), timing.end_time)}
                                                        format={format}
                                                        value={moment(timing.start_time, 'h:mm a')}
                                                        use12Hours
                                                        allowEmpty={false}

                                                    />
                                                    <Form.Group className="mt-2">
                                                        <TimePicker
                                                            showSecond={false}
                                                            defaultValue={moment(timing.end_time, 'h:mm a')}
                                                            className="xxx"
                                                            onChange={(value) => edit_timings(e, 'tue', timing.uuid, timing.start_time, value.format(format))}
                                                            format={format}
                                                            value={moment(timing.end_time, 'h:mm a')}

                                                            use12Hours
                                                            allowEmpty={false}
                                                        />
                                                    </Form.Group>
                                                    {/* <Form.Control type='time' value={timing.start_time} onChange={(e) => edit_timings(e, 'tue', timing.uuid, e.target.value, timing.end_time)} />
                                                    <Form.Group className="mt-2">

                                                        <Form.Control type='time' value={timing.end_time} onChange={(e) => edit_timings(e, 'tue', timing.uuid, timing.start_time, e.target.value)} />
                                                    </Form.Group> */}
                                                    <Button variant="danger" onClick={(e) => setTuesdayTiming(tuesdayTiming.filter(item => item.uuid !== timing.uuid))} size="sm"><TrashFill color=" #ffffff" size={20} /> </Button>

                                                </Form.Group>
                                            )}
                                            {(tuesdayTiming.length == 0 || tShow) &&
                                                <Form.Group style={{ width: '100px', marginBottom: '10px' }}>
                                                    <TimePicker
                                                        showSecond={false}
                                                        className="xxx"
                                                        onChange={async (value) => {

                                                            await setTuStart(value)

                                                            await setTuEnd(value)

                                                            await add_timings(e, "tue", value.format(format), value.add(1, 'hours').format(format))
                                                        }}
                                                        format={format}
                                                        use12Hours
                                                        inputReadOnly
                                                        allowEmpty={false}
                                                    />
                                                    {/* <Form.Control type='time' value={tuStart || ''} required onChange={async (e) => {
                                                        add_timings(e, "tue", e.target.value, String(Number(String(e.target.value).substring(0, 2)) + 1) + String(e.target.value).slice(2))
                                                        await setTuStart(e.target.value)

                                                        await setTuEnd((String(Number(String(e.target.value).substring(0, 2)) + 1)).toString().padStart(2, 0) + String(e.target.value).slice(2))
                                                    }
                                                    } /> */}
                                                    <Form.Group className="mt-2">

                                                        <Form.Control type='time' disabled={tuStart ? 'false' : 'true'} value={tuEnd || ''} required onChange={(e) => setTuEnd(e.target.value)} />
                                                    </Form.Group>
                                                </Form.Group>
                                            }
                                            <Button variant="primary" onClick={() => setTShow(true)} style={{ marginBottom: "45px" }} className="shadow-none btn bg-transparent btn-outline-transparent border-0">

                                                <div className="add-new-time">
                                                    <PlusCircleFill className="add-new-time-icon" style={{ marginLeft: 10 }} size={30} color='#2c278b' />
                                                </div>

                                            </Button>

                                        </Row>
                                    </div>
                                </Col>

                            </Row>
                        </Form>
                        {/* new end */}
                        {/* new start */} <Row className="align-items-center">
                            <Col xs={1}>
                                <Form.Check style={{ marginBottom: '10px' }} type="checkbox" checked={isWe} onChange={(e) => handleCheckbox(e, 'wed')} />

                            </Col>
                            <Col xs={11}>

                                <div style={{ width: '100%', height: '40px', border: '2px solid black', marginBottom: '15px', textAlign: 'center',opacity: isWe ? 1 : 0.4 }}><h4>Wednesday</h4></div>
                            </Col>
                        </Row>

                        <Form onSubmit={(e) => { add_timings(e, "wed",) }} className='mt-4' >
                            <Row className="align-items-center">

                                <Col xs={2} style={{
                                    width: '10.499999995%',
                                    flex: '0 0 10.499%', maxWidth: '10.499%', marginBottom: '60px'
                                }}>
                                    <Form.Label style={{ fontWeight: 'bold' }}>Trip No.</Form.Label><br></br>

                                    <Form.Group>
                                        <Form.Label style={{ fontWeight: 'bold' }} >Start Time</Form.Label>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label style={{ fontWeight: 'bold' }}>End Time</Form.Label>
                                    </Form.Group>
                                    <Form.Group>


                                    </Form.Group>
                                </Col>
                                <Col xs={10} style={{ opacity: isWe ? 1 : 0.4, pointerEvents: isWe ? '' : 'none' }}>
                                    <div className="scroll">
                                        <Row className="align-items-center scroll2">
                                            {(wednesdayTiming.length > 0) && sort_by_key(wednesdayTiming, "start_time").map((timing, index) =>
                                                <Form.Group style={{ width: '100px', marginRight: '10px', marginLeft: '0px' }} key={timing.uuid}>
                                                    <Form.Label style={{ fontWeight: 'bold' }}>{index + 1}</Form.Label><br></br>
                                                    <TimePicker
                                                        showSecond={false}
                                                        defaultValue={moment(timing.start_time, 'h:mm a')}
                                                        className="xxx"
                                                        onChange={(value) => edit_timings(e, 'wed', timing.uuid, value.format(format), timing.end_time)}
                                                        format={format}
                                                        value={moment(timing.start_time, 'h:mm a')}

                                                        use12Hours
                                                        allowEmpty={false}

                                                    />
                                                    <Form.Group className="mt-2">
                                                        <TimePicker
                                                            showSecond={false}
                                                            defaultValue={moment(timing.end_time, 'h:mm a')}
                                                            className="xxx"
                                                            onChange={(value) => edit_timings(e, 'wed', timing.uuid, timing.start_time, value.format(format))}
                                                            format={format}
                                                            value={moment(timing.end_time, 'h:mm a')}

                                                            use12Hours
                                                            allowEmpty={false}
                                                        />
                                                    </Form.Group>
                                                    {/* <Form.Control type='time' value={timing.start_time} onChange={(e) => edit_timings(e, 'wed', timing.uuid, e.target.value, timing.end_time)} />
                                                    <Form.Group className="mt-2">
                                                        
                                                        <Form.Control type='time' value={timing.end_time} onChange={(e) => edit_timings(e, 'wed', timing.uuid, e.target.value, timing.start_time, e.target.value)} />
                                                    </Form.Group> */}
                                                    <Button variant="danger" onClick={(e) => setWednesdayTiming(wednesdayTiming.filter(item => item.uuid !== timing.uuid))} size="sm"><TrashFill color=" #ffffff" size={20} /> </Button>

                                                </Form.Group>
                                            )}
                                            {(wednesdayTiming.length == 0 || wShow) &&
                                                <Form.Group style={{ width: '100px', marginBottom: '10px' }}>
                                                    {/* <Form.Label >Start Time</Form.Label> */}
                                                    <TimePicker
                                                        showSecond={false}
                                                        className="xxx"
                                                        onChange={async (value) => {

                                                            await setWeStart(value)

                                                            await setWeEnd(value)

                                                            await add_timings(e, "wed", value.format(format), value.add(1, 'hours').format(format))
                                                        }}
                                                        format={format}
                                                        use12Hours
                                                        inputReadOnly
                                                        allowEmpty={false}
                                                    />
                                                    {/* <Form.Control type='time' value={weStart || ''} required onChange={async (e) => {
                                                        add_timings(e, "wed", e.target.value, String(Number(String(e.target.value).substring(0, 2)) + 1) + String(e.target.value).slice(2))
                                                        await setWeStart(e.target.value)

                                                        await setWeEnd((String(Number(String(e.target.value).substring(0, 2)) + 1)).toString().padStart(2, 0) + String(e.target.value).slice(2))
                                                    }} /> */}
                                                    <Form.Group className="mt-2">
                                                        {/* <Form.Label >End Time</Form.Label> */}
                                                        <Form.Control type='time' disabled={weStart ? 'false' : 'true'} value={weEnd || ''} required onChange={(e) => setWeEnd(e.target.value)} />
                                                    </Form.Group>
                                                </Form.Group>
                                            }
                                            <Button variant="primary" onClick={() => setWShow(true)} style={{ marginBottom: "45px" }} className="shadow-none btn bg-transparent btn-outline-transparent border-0">

                                                <div className="add-new-time">
                                                    <PlusCircleFill className="add-new-time-icon" style={{ marginLeft: 10 }} size={30} color='#2c278b' />
                                                </div>

                                            </Button>
                                        </Row>
                                    </div>
                                </Col>

                            </Row>
                        </Form>
                        {/* new end */}
                        {/* new start */}
                        <Row className="align-items-center">
                            <Col xs={1}>
                                <Form.Check style={{ marginBottom: '10px' }} type="checkbox" checked={isTh} onChange={(e) => handleCheckbox(e, 'thu')} />
                            </Col>
                            <Col xs={11}>
                                <div style={{ width: '100%', height: '40px', border: '2px solid black', marginBottom: '15px', textAlign: 'center',opacity: isTh ? 1 : 0.4 }}><h4>Thursday</h4></div>

                            </Col>
                        </Row>

                        <Form onSubmit={(e) => { add_timings(e, "thu",) }} className='mt-4'>
                            <Row className="align-items-center">

                                <Col xs={2}

                                    style={{
                                        width: '10.499999995%',
                                        flex: '0 0 10.499%', maxWidth: '10.499%', marginBottom: '60px'
                                    }}>
                                    <Form.Label style={{ fontWeight: 'bold' }}>Trip No.</Form.Label><br></br>

                                    <Form.Group>
                                        <Form.Label style={{ fontWeight: 'bold' }} >Start Time</Form.Label>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label style={{ fontWeight: 'bold' }}>End Time</Form.Label>
                                    </Form.Group>
                                    <Form.Group>


                                    </Form.Group>
                                </Col>
                                <Col xs={10} style={{ opacity: isTh ? 1 : 0.4, pointerEvents: isTh ? '' : 'none' }}>
                                    <div className="scroll">
                                        <Row className="align-items-center scroll2">
                                            {(thursdayTiming.length > 0) && sort_by_key(thursdayTiming, "start_time").map((timing, index) =>
                                                <Form.Group style={{ width: '100px', marginRight: '10px', marginLeft: '0px' }} key={timing.uuid}>
                                                    {/* <Form.Label >Start Time</Form.Label> */}
                                                    <Form.Label style={{ fontWeight: 'bold' }}>{index + 1}</Form.Label><br></br>

                                                    <TimePicker
                                                        showSecond={false}
                                                        defaultValue={moment(timing.start_time, 'h:mm a')}
                                                        className="xxx"
                                                        onChange={(value) => edit_timings(e, 'thu', timing.uuid, value.format(format), timing.end_time)}
                                                        format={format}
                                                        value={moment(timing.start_time, 'h:mm a')}

                                                        use12Hours
                                                        allowEmpty={false}

                                                    />
                                                    <Form.Group className="mt-2">
                                                        <TimePicker
                                                            showSecond={false}
                                                            defaultValue={moment(timing.end_time, 'h:mm a')}
                                                            className="xxx"
                                                            onChange={(value) => edit_timings(e, 'thu', timing.uuid, timing.start_time, value.format(format))}
                                                            format={format}
                                                            value={moment(timing.end_time, 'h:mm a')}

                                                            use12Hours
                                                            allowEmpty={false}
                                                        />
                                                    </Form.Group>
                                                    <Button variant="danger" onClick={(e) => setThursdayTiming(thursdayTiming.filter(item => item.uuid !== timing.uuid))} size="sm"><TrashFill color=" #ffffff" size={20} /> </Button>

                                                </Form.Group>
                                            )}
                                            {(thursdayTiming.length == 0 || t2Show) &&
                                                <Form.Group style={{ width: '100px', marginBottom: '10px' }}>
                                                    {/* <Form.Label >Start Time</Form.Label> */}
                                                    <TimePicker
                                                        showSecond={false}
                                                        className="xxx"
                                                        onChange={async (value) => {

                                                            await setThStart(value)

                                                            await setThEnd(value)

                                                            await add_timings(e, "thu", value.format(format), value.add(1, 'hours').format(format))
                                                        }}
                                                        format={format}
                                                        use12Hours
                                                        inputReadOnly
                                                        allowEmpty={false}
                                                    />
                                                    {/* <Form.Control type='time' value={thStart || ''} required onChange={async (e) => {
                                                        add_timings(e, "thu", e.target.value, String(Number(String(e.target.value).substring(0, 2)) + 1) + String(e.target.value).slice(2))
                                                        await setThStart(e.target.value)

                                                        await setThEnd((String(Number(String(e.target.value).substring(0, 2)) + 1)).toString().padStart(2, 0) + String(e.target.value).slice(2))
                                                    }} /> */}
                                                    <Form.Group className="mt-2">
                                                        {/* <Form.Label >End Time</Form.Label> */}
                                                        <Form.Control type='time' disabled={thStart ? 'false' : 'true'} value={thEnd || ''} required onChange={(e) => setThEnd(e.target.value)} />
                                                    </Form.Group>
                                                </Form.Group>
                                            }
                                            <Button variant="primary" onClick={() => setT2Show(true)} style={{ marginBottom: "45px" }} className="shadow-none btn bg-transparent btn-outline-transparent border-0">

                                                <div className="add-new-time">
                                                    <PlusCircleFill className="add-new-time-icon" style={{ marginLeft: 10 }} size={30} color='#2c278b' />
                                                </div>

                                            </Button>
                                        </Row>
                                    </div>
                                </Col>

                            </Row>
                        </Form>
                        {/* new end */}
                        {/* new start */}
                        <Row className="align-items-center">
                            <Col xs={1}>
                                <Form.Check style={{ marginBottom: '10px' }} type="checkbox" checked={isFr} onChange={(e) => handleCheckbox(e, 'fri')} />
                            </Col>
                            <Col xs={11}>
                                <div style={{ width: '100%', height: '40px', border: '2px solid black', marginBottom: '15px', textAlign: 'center',opacity: isFr ? 1 : 0.4 }}><h4>Friday</h4></div>

                            </Col>
                        </Row>

                        <Form onSubmit={(e) => { add_timings(e, "fri",) }} className='mt-4'>
                            <Row className="align-items-center">

                                <Col xs={2} style={{
                                    width: '10.499999995%',
                                    flex: '0 0 10.499%', maxWidth: '10.499%', marginBottom: '60px'
                                }}>
                                    <Form.Label style={{ fontWeight: 'bold' }}>Trip No.</Form.Label><br></br>

                                    <Form.Group>
                                        <Form.Label style={{ fontWeight: 'bold' }} >Start Time</Form.Label>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label style={{ fontWeight: 'bold' }}>End Time</Form.Label>
                                    </Form.Group>
                                    <Form.Group>


                                    </Form.Group>
                                </Col>
                                <Col xs={10} style={{ opacity: isFr ? 1 : 0.4, pointerEvents: isFr ? '' : 'none' }}>
                                    <div className="scroll">
                                        <Row className="align-items-center scroll2">
                                            {(fridayTiming.length > 0) && sort_by_key(fridayTiming, "start_time").map((timing, index) =>
                                                <Form.Group style={{ width: '100px', marginRight: '10px', marginLeft: '0px' }} key={timing.uuid}>
                                                    {/* <Form.Label >Start Time</Form.Label> */}
                                                    <Form.Label style={{ fontWeight: 'bold' }}>{index + 1}</Form.Label><br></br>
                                                    <TimePicker
                                                        showSecond={false}
                                                        defaultValue={moment(timing.start_time, 'h:mm a')}
                                                        className="xxx"
                                                        onChange={(value) => edit_timings(e, 'fri', timing.uuid, value.format(format), timing.end_time)}
                                                        format={format}
                                                        value={moment(timing.start_time, 'h:mm a')}

                                                        use12Hours
                                                        allowEmpty={false}

                                                    />
                                                    <Form.Group className="mt-2">
                                                        <TimePicker
                                                            showSecond={false}
                                                            defaultValue={moment(timing.end_time, 'h:mm a')}
                                                            className="xxx"
                                                            onChange={(value) => edit_timings(e, 'fri', timing.uuid, timing.start_time, value.format(format))}
                                                            format={format}
                                                            value={moment(timing.end_time, 'h:mm a')}

                                                            use12Hours
                                                            allowEmpty={false}
                                                        />
                                                    </Form.Group>
                                                    <Button variant="danger" onClick={(e) => setFridayTiming(fridayTiming.filter(item => item.uuid !== timing.uuid))} size="sm"><TrashFill color=" #ffffff" size={20} /> </Button>

                                                </Form.Group>
                                            )}
                                            {(fridayTiming.length == 0 || fShow) &&
                                                <Form.Group style={{ width: '100px', marginBottom: '10px' }}>
                                                    {/* <Form.Label >Start Time</Form.Label> */}
                                                    <TimePicker
                                                        showSecond={false}
                                                        className="xxx"
                                                        onChange={async (value) => {

                                                            await setFrStart(value)

                                                            await setFrEnd(value)

                                                            await add_timings(e, "fri", value.format(format), value.add(1, 'hours').format(format))
                                                        }}
                                                        format={format}
                                                        use12Hours
                                                        inputReadOnly
                                                        allowEmpty={false}
                                                    />
                                                    {/* <Form.Control type='time' value={frStart || ''} required onChange={async (e) => {
                                                        add_timings(e, "fri", e.target.value, String(Number(String(e.target.value).substring(0, 2)) + 1) + String(e.target.value).slice(2))
                                                        await setFrStart(e.target.value)

                                                        await setFrEnd((String(Number(String(e.target.value).substring(0, 2)) + 1)).toString().padStart(2, 0) + String(e.target.value).slice(2))
                                                    }} /> */}
                                                    <Form.Group className="mt-2">
                                                        {/* <Form.Label >End Time</Form.Label> */}
                                                        <Form.Control type='time' disabled={frStart ? 'false' : 'true'} value={frEnd || ''} required onChange={(e) => setFrEnd(e.target.value)} />
                                                    </Form.Group>
                                                </Form.Group>}
                                            <Button variant="primary" onClick={() => setFShow(true)} style={{ marginBottom: "45px" }} className="shadow-none btn bg-transparent btn-outline-transparent border-0">

                                                <div className="add-new-time">
                                                    <PlusCircleFill className="add-new-time-icon" style={{ marginLeft: 10 }} size={30} color='#2c278b' />
                                                </div>

                                            </Button>
                                        </Row>
                                    </div>
                                </Col>

                            </Row>
                        </Form>
                        {/* new end */}
                        {/* new start */}
                        <Row className="align-items-center">
                            <Col xs={1}>
                                <Form.Check style={{ marginBottom: '10px' }} type="checkbox" checked={isSa} onChange={(e) => handleCheckbox(e, 'sat')} />
                            </Col>
                            <Col xs={11}>
                                <div style={{ width: '100%', height: '40px', border: '2px solid black', marginBottom: '15px', textAlign: 'center',opacity: isSa ? 1 : 0.4 }}><h4>Saturday</h4></div>

                            </Col>
                        </Row>

                        <Form onSubmit={(e) => { add_timings(e, "sat",) }} className='mt-4'>
                            <Row className="align-items-center">

                                <Col xs={2} style={{
                                    width: '10.499999995%',
                                    flex: '0 0 10.499%', maxWidth: '10.499%', marginBottom: '60px'
                                }}>
                                    <Form.Label style={{ fontWeight: 'bold' }}>Trip No.</Form.Label><br></br>

                                    <Form.Group>
                                        <Form.Label style={{ fontWeight: 'bold' }} >Start Time</Form.Label>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label style={{ fontWeight: 'bold' }}>End Time</Form.Label>
                                    </Form.Group>
                                    <Form.Group>


                                    </Form.Group>
                                </Col>
                                <Col xs={10} style={{ opacity: isSa ? 1 : 0.4, pointerEvents: isSa ? '' : 'none' }}>
                                    <div className="scroll">
                                        <Row className="align-items-center scroll2">
                                            {(saturdayTiming.length > 0) && sort_by_key(saturdayTiming, "start_time").map((timing, index) =>
                                                <Form.Group style={{ width: '100px', marginRight: '10px', marginLeft: '0px' }} key={timing.uuid}>
                                                    {/* <Form.Label >Start Time</Form.Label> */}
                                                    <Form.Label style={{ fontWeight: 'bold' }}>{index + 1}</Form.Label><br></br>

                                                    <TimePicker
                                                        showSecond={false}
                                                        defaultValue={moment(timing.start_time, 'h:mm a')}
                                                        className="xxx"
                                                        onChange={(value) => edit_timings(e, 'sat', timing.uuid, value.format(format), timing.end_time)}
                                                        format={format}
                                                        value={moment(timing.start_time, 'h:mm a')}

                                                        use12Hours
                                                        allowEmpty={false}

                                                    />
                                                    <Form.Group className="mt-2">
                                                        <TimePicker
                                                            showSecond={false}
                                                            defaultValue={moment(timing.end_time, 'h:mm a')}
                                                            className="xxx"
                                                            onChange={(value) => edit_timings(e, 'sat', timing.uuid, timing.start_time, value.format(format))}
                                                            format={format}
                                                            value={moment(timing.end_time, 'h:mm a')}

                                                            use12Hours
                                                            allowEmpty={false}
                                                        />
                                                    </Form.Group>
                                                    <Button variant="danger" onClick={(e) => setSaturdayTiming(saturdayTiming.filter(item => item.uuid !== timing.uuid))} size="sm"><TrashFill color=" #ffffff" size={20} /> </Button>

                                                </Form.Group>

                                            )}
                                            {(saturdayTiming.length == 0 || sShow) &&
                                                <Form.Group style={{ width: '100px', marginBottom: '10px' }}>
                                                    {/* <Form.Label >Start Time</Form.Label> */}
                                                    <TimePicker
                                                        showSecond={false}
                                                        className="xxx"
                                                        onChange={async (value) => {

                                                            await setSaStart(value)

                                                            await setSaEnd(value)

                                                            await add_timings(e, "sat", value.format(format), value.add(1, 'hours').format(format))
                                                        }}
                                                        format={format}
                                                        use12Hours
                                                        inputReadOnly
                                                        allowEmpty={false}
                                                    />
                                                    {/* <Form.Control type='time' value={saStart || ''} required onChange={async (e) => {
                                                        add_timings(e, "sat", e.target.value, String(Number(String(e.target.value).substring(0, 2)) + 1) + String(e.target.value).slice(2))
                                                        await setSaStart(e.target.value)

                                                        await setSaEnd((String(Number(String(e.target.value).substring(0, 2)) + 1)).toString().padStart(2, 0) + String(e.target.value).slice(2))
                                                    }} /> */}
                                                    <Form.Group className="mt-2">
                                                        {/* <Form.Label >End Time</Form.Label> */}
                                                        <Form.Control type='time' disabled={saStart ? 'false' : 'true'} value={saEnd || ''} required onChange={(e) => setSaEnd(e.target.value)} />
                                                    </Form.Group>
                                                </Form.Group>
                                            }
                                            <Button variant="primary" onClick={() => setSShow(true)} style={{ marginBottom: "45px" }} className="shadow-none btn bg-transparent btn-outline-transparent border-0">

                                                <div className="add-new-time">
                                                    <PlusCircleFill className="add-new-time-icon" style={{ marginLeft: 10 }} size={30} color='#2c278b' />
                                                </div>

                                            </Button>
                                        </Row>
                                    </div>
                                </Col>

                            </Row>
                        </Form>
                        {/* new end */}
                        {/* new start */}
                        <Row className="align-items-center">
                            <Col xs={1}>
                                <Form.Check style={{ marginBottom: '10px' }} type="checkbox" checked={isSu} onChange={(e) => handleCheckbox(e, 'sun')} />
                            </Col>
                            <Col xs={11}>

                                <div style={{ width: '100%', height: '40px', border: '2px solid black', marginBottom: '15px', textAlign: 'center',opacity: isSu ? 1 : 0.4, }}><h4>Sunday</h4></div>
                            </Col>
                        </Row>

                        <Form onSubmit={(e) => { add_timings(e, "sun",) }} className='mt-4'>
                            <Row className="align-items-center">

                                <Col xs={2} style={{
                                    width: '10.499999995%',
                                    flex: '0 0 10.499%', maxWidth: '10.499%', marginBottom: '60px'
                                }}>
                                    <Form.Label style={{ fontWeight: 'bold' }}>Trip No.</Form.Label><br></br>

                                    <Form.Group>
                                        <Form.Label style={{ fontWeight: 'bold' }} >Start Time</Form.Label>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label style={{ fontWeight: 'bold' }}>End Time</Form.Label>
                                    </Form.Group>
                                    <Form.Group>


                                    </Form.Group>
                                </Col>
                                <Col xs={10} style={{ opacity: isSu ? 1 : 0.4, pointerEvents: isSu ? '' : 'none' }}>
                                    <div className="scroll">
                                        <Row className="align-items-center scroll2">
                                            {(sundayTiming.length > 0) && sort_by_key(sundayTiming, "start_time").map((timing, index) =>
                                                <Form.Group style={{ width: '100px', marginRight: '10px', marginLeft: '0px' }} key={timing.uuid}>
                                                    {/* <Form.Label >Start Time</Form.Label> */}
                                                    <Form.Label style={{ fontWeight: 'bold' }}>{index + 1}</Form.Label><br></br>

                                                    <TimePicker
                                                        showSecond={false}
                                                        defaultValue={moment(timing.start_time, 'h:mm a')}
                                                        className="xxx"
                                                        onChange={(value) => edit_timings(e, 'sun', timing.uuid, value.format(format), timing.end_time)}
                                                        format={format}
                                                        value={moment(timing.start_time, 'h:mm a')}

                                                        use12Hours
                                                        allowEmpty={false}

                                                    />
                                                    <Form.Group className="mt-2">
                                                        <TimePicker
                                                            showSecond={false}
                                                            defaultValue={moment(timing.end_time, 'h:mm a')}
                                                            className="xxx"
                                                            onChange={(value) => edit_timings(e, 'sun', timing.uuid, timing.start_time, value.format(format))}
                                                            format={format}
                                                            value={moment(timing.end_time, 'h:mm a')}

                                                            use12Hours
                                                            allowEmpty={false}
                                                        />
                                                    </Form.Group>
                                                    <Button variant="danger" onClick={(e) => setSundayTiming(sundayTiming.filter(item => item.uuid !== timing.uuid))} size="sm"><TrashFill color=" #ffffff" size={20} /> </Button>

                                                </Form.Group>
                                            )}
                                            {(sundayTiming.length == 0 || s2Show) &&
                                                <Form.Group style={{ width: '100px', marginBottom: '10px' }}>
                                                    {/* <Form.Label >Start Time</Form.Label> */}
                                                    <TimePicker
                                                        showSecond={false}
                                                        className="xxx"
                                                        onChange={async (value) => {

                                                            await setSuStart(value)

                                                            await setSuEnd(value)

                                                            await add_timings(e, "sun", value.format(format), value.add(1, 'hours').format(format))
                                                        }}
                                                        format={format}
                                                        use12Hours
                                                        inputReadOnly
                                                        allowEmpty={false}
                                                    />
                                                    {/* <Form.Control type='time' value={suStart || ''} required onChange={async (e) => {
                                                        add_timings(e, "sun", e.target.value, String(Number(String(e.target.value).substring(0, 2)) + 1) + String(e.target.value).slice(2))
                                                        await setSuStart(e.target.value)

                                                        await setSuEnd((String(Number(String(e.target.value).substring(0, 2)) + 1)).toString().padStart(2, 0) + String(e.target.value).slice(2))
                                                    }} /> */}
                                                    <Form.Group className="mt-2">
                                                        {/* <Form.Label >End Time</Form.Label> */}
                                                        <Form.Control type='time' disabled={suStart ? 'false' : 'true'} value={suEnd || ''} required onChange={(e) => setSuEnd(e.target.value)} />
                                                    </Form.Group>
                                                </Form.Group>}
                                            <Button variant="primary" onClick={() => setS2Show(true)} style={{ marginBottom: "45px" }} className="shadow-none btn bg-transparent btn-outline-transparent border-0">

                                                <div className="add-new-time">
                                                    <PlusCircleFill className="add-new-time-icon" style={{ marginLeft: 10 }} size={30} color='#2c278b' />
                                                </div>

                                            </Button>
                                        </Row>
                                    </div>
                                </Col>

                            </Row>
                        </Form>
                    </div>
                </Modal.Body>

            </Modal>

            {/* edit modal start */}
            <Modal size='xl' centered show={isEditShow} onHide={handleEditClose} >
                <Form onSubmit={(e) => { saveEditScheduleMain(e) }}>
                    <Modal.Header closeButton={true} className={'border-0'}>

                        <Modal.Title>Schedule:</Modal.Title>
                        <Form.Group className="ml-5 w-75">
                            <Form.Control required type='text' placeholder='Name The Schedule' value={currentEditSchedule || ''} onChange={(e) => setCurrentEditSchedule(e.target.value)} />
                        </Form.Group>
                        <button className='fare-discount-button col-md-2 ml-3'>Save Schedule</button>



                    </Modal.Header>
                    <p className="pl-3">Schedule ID : {currentEditScheduleId}  </p>
                    <p className="pl-3">Created At : {moment(currentCreatedAt).format('YYYY-MM-DD HH:mm:ss')}</p>
                    {currentUpdatedAt && <p className="pl-3">Updated At : {moment(currentUpdatedAt).format('YYYY-MM-DD HH:mm:ss')}</p>}
                </Form>
                <Modal.Body className="border border-top-2">
                    <div className='text-center' >
                        {/* new start */}
                        <Row className="align-items-center">
                            <Col xs={1}>
                                <Form.Check style={{ marginBottom: '10px' }} type="checkbox" checked={isMoN} onChange={(e) => handleEditCheckbox(e, 'mon')} />
                            </Col>
                            <Col xs={11}>

                                <div style={{ width: '100%', height: '40px', border: '2px solid black', marginBottom: '15px', textAlign: 'center',opacity: isMoN ? 1 : 0.4 }}><h4>Monday</h4></div>
                            </Col>
                        </Row>

                        <Form >
                            <Row className="align-items-center">

                                <Col xs={2} style={{
                                    width: '10.499999995%',
                                    flex: '0 0 10.499%', maxWidth: '10.499%', marginBottom: '60px'
                                }}>
                                    <Form.Label style={{ fontWeight: 'bold' }}>Trip No.</Form.Label><br></br>

                                    <Form.Group>
                                        <Form.Label style={{ fontWeight: 'bold' }} >Start Time</Form.Label>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label style={{ fontWeight: 'bold' }}>End Time</Form.Label>
                                    </Form.Group>
                                    <Form.Group>


                                    </Form.Group>
                                </Col>
                                <Col xs={10} style={{ opacity: isMoN ? 1 : 0.4, pointerEvents: isMoN ? '' : 'none' }}>
                                    <div className="scroll">
                                        <Row className="align-items-center scroll2">
                                            {(mondayTimingE.concat(mondayTimingN).length > 0) && sort_by_key(mondayTimingE.concat(mondayTimingN), "start_time").map((timing, index) =>
                                                <Fragment key={timing.uuid}>
                                                    <Form.Group style={{ width: '100px', marginRight: '10px', marginLeft: '0px' }} key={timing.uuid}>
                                                        {/* <Form.Label >Start Time</Form.Label> */}

                                                        <Form.Label style={{ fontWeight: 'bold' }}>{index + 1}</Form.Label><br></br>
                                                        <TimePicker
                                                            showSecond={false}
                                                            defaultValue={moment(timing.start_time, 'h:mm a')}
                                                            className="xxx"
                                                            onChange={(value) => edit_timings_time(e, 'mon', timing.uuid, value.format(format), timing.end_time, timing.schedule_id)}
                                                            format={format}
                                                            value={moment(timing.start_time, 'h:mm a')}

                                                            use12Hours
                                                            allowEmpty={false}

                                                        />
                                                        <Form.Group className="mt-2">
                                                            <TimePicker
                                                                showSecond={false}
                                                                defaultValue={moment(timing.end_time, 'h:mm a')}
                                                                className="xxx"
                                                                onChange={(value) => edit_timings_time(e, 'mon', timing.uuid, timing.start_time, value.format(format), timing.schedule_id)}
                                                                format={format}
                                                                value={moment(timing.end_time, 'h:mm a')}

                                                                use12Hours
                                                                allowEmpty={false}
                                                            />
                                                        </Form.Group>

                                                        <Button onClick={(e) => {
                                                            // if contains in E then directly remove from db, but if not in E, remove from N
                                                            if (mondayTimingE.length > 0) {
                                                                mondayTimingE.some(element => {
                                                                    if (element.uuid == timing.uuid) {
                                                                        // handleScheduleRecordDelete(timing.uuid)
                                                                        setTimingsToDelete(prev => [...prev, timing.uuid])
                                                                        setMondayTimingE(mondayTimingE.filter(item => item.uuid !== timing.uuid))

                                                                    }
                                                                    else {
                                                                        setMondayTimingN(mondayTimingN.filter(item => item.uuid !== timing.uuid))
                                                                    }
                                                                })

                                                            }
                                                            else {
                                                                setMondayTimingN(mondayTimingN.filter(item => item.uuid !== timing.uuid))
                                                            }
                                                        }
                                                        } variant="danger" size="sm"> <TrashFill color=" #ffffff" size={20} /> </Button>

                                                    </Form.Group>

                                                </Fragment>
                                            )}
                                            {(mondayTimingE.concat(mondayTimingN).length == 0 || mShowN) &&
                                                <Form.Group style={{ width: '100px', marginBottom: '10px' }}>
                                                    {/* <Form.Label >Start Time</Form.Label> */}
                                                    <TimePicker
                                                        showSecond={false}
                                                        className="xxx"
                                                        onChange={async (value) => {

                                                            await setMoStartN(value)

                                                            await setMoEndN(value)

                                                            await add_timings_time(e, "mon", value.format(format), value.add(1, 'hours').format(format))
                                                        }}
                                                        format={format}
                                                        use12Hours
                                                        inputReadOnly
                                                        allowEmpty={false}
                                                    />
                                                    {/* <Form.Control type='time' value={''} required onChange={async (e) => {
                                                        add_timings_time(e, "mon", e.target.value, (String(Number(String(e.target.value).substring(0, 2)) + 1)).toString().padStart(2, 0) + String(e.target.value).slice(2))
                                                        await setMoStartN(e.target.value)

                                                        await setMoEndN((String(Number(String(e.target.value).substring(0, 2)) + 1)).toString().padStart(2, 0) + String(e.target.value).slice(2))
                                                    }} /> */}
                                                    <Form.Group className="mt-2">

                                                        {/* <Form.Label >End Time</Form.Label> */}
                                                        <Form.Control type='time' disabled={moStartN ? 'false' : 'true'} value={moEndN || ''} required onChange={(e) => {


                                                            setMoEndN(e.target.value)
                                                        }} />
                                                    </Form.Group>
                                                </Form.Group>}
                                            <Button variant="primary" onClick={() => setMShowN(true)} style={{ marginBottom: "45px" }} className="shadow-none btn bg-transparent btn-outline-transparent border-0">

                                                <div className="add-new-time">
                                                    <PlusCircleFill className="add-new-time-icon" style={{ marginLeft: 10 }} size={30} color='#2c278b' />
                                                </div>

                                            </Button>
                                        </Row>
                                    </div>
                                </Col>

                            </Row>
                        </Form>
                        {/* new end */}
                        {/* new start */}
                        <Row className="align-items-center">
                            <Col xs={1}>
                                <Form.Check style={{ marginBottom: '10px' }} type="checkbox" checked={isTuN} onChange={(e) => handleEditCheckbox(e, 'tue')} />
                            </Col>
                            <Col xs={11}>

                                <div style={{ width: '100%', height: '40px', border: '2px solid black', marginBottom: '15px', textAlign: 'center',opacity: isTuN ? 1 : 0.4 }}><h4>Tuesday</h4></div>
                            </Col>
                        </Row>

                        <Form className='mt-4'>
                            <Row className="align-items-center">

                                <Col xs={2} style={{
                                    width: '10.499999995%',
                                    flex: '0 0 10.499%', maxWidth: '10.499%', marginBottom: '60px'
                                }}>
                                    <Form.Label style={{ fontWeight: 'bold' }}>Trip No.</Form.Label><br></br>

                                    <Form.Group>
                                        <Form.Label style={{ fontWeight: 'bold' }} >Start Time</Form.Label>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label style={{ fontWeight: 'bold' }}>End Time</Form.Label>
                                    </Form.Group>
                                    <Form.Group>


                                    </Form.Group>
                                </Col>
                                <Col xs={10} style={{ opacity: isTuN ? 1 : 0.4, pointerEvents: isTuN ? '' : 'none' }}>
                                    <div className="scroll">
                                        <Row className="align-items-center scroll2">
                                            {(tuesdayTimingE.concat(tuesdayTimingN).length > 0) && sort_by_key(tuesdayTimingE.concat(tuesdayTimingN), "start_time").map((timing, index) =>
                                                <Form.Group style={{ width: '100px', marginRight: '10px', marginLeft: '0px' }} key={timing.uuid}>
                                                    {/* <Form.Label >Start Time</Form.Label> */}
                                                    <Form.Label style={{ fontWeight: 'bold' }}>{index + 1}</Form.Label><br></br>

                                                    <TimePicker
                                                        showSecond={false}
                                                        defaultValue={moment(timing.start_time, 'h:mm a')}
                                                        className="xxx"
                                                        onChange={(value) => edit_timings_time(e, 'tue', timing.uuid, value.format(format), timing.end_time, timing.schedule_id)}
                                                        format={format}
                                                        value={moment(timing.start_time, 'h:mm a')}

                                                        use12Hours
                                                        allowEmpty={false}

                                                    />
                                                    <Form.Group className="mt-2">
                                                        <TimePicker
                                                            showSecond={false}
                                                            defaultValue={moment(timing.end_time, 'h:mm a')}
                                                            className="xxx"
                                                            onChange={(value) => edit_timings_time(e, 'tue', timing.uuid, timing.start_time, value.format(format), timing.schedule_id)}
                                                            format={format}
                                                            value={moment(timing.end_time, 'h:mm a')}

                                                            use12Hours
                                                            allowEmpty={false}
                                                        />
                                                    </Form.Group>
                                                    <Button variant="danger" onClick={(e) => {
                                                        // if contains in E then directly remove from db, but if not in E, remove from N
                                                        
                                                        if (tuesdayTimingE.length > 0) {

                                                            tuesdayTimingE.some(element => {
                                                                if (element != null && element.uuid == timing.uuid) {
                                                                    
                                                                    setTimingsToDelete(prev => [...prev, timing.uuid])

                                                                    // handleScheduleRecordDelete(timing.uuid)
                                                                    setTuesdayTimingE(tuesdayTimingE.filter(item => item.uuid !== timing.uuid))

                                                                }
                                                                else {
                                                                    

                                                                    setTuesdayTimingN(tuesdayTimingN.filter(item => item.uuid !== timing.uuid))
                                                                }
                                                            })
                                                        }
                                                        else {
                                                            setTuesdayTimingN(tuesdayTimingN.filter(item => item.uuid !== timing.uuid))

                                                        }

                                                    }
                                                    } size="sm"><TrashFill color=" #ffffff" size={20} /> </Button>

                                                </Form.Group>
                                            )}
                                            {(tuesdayTimingE.concat(tuesdayTimingN).length == 0 || tShowN) &&

                                                <Form.Group style={{ width: '100px', marginBottom: '10px' }}>
                                                    {/* <Form.Label >Start Time</Form.Label> */}
                                                    <TimePicker
                                                        showSecond={false}
                                                        className="xxx"
                                                        onChange={async (value) => {

                                                            await setTuStartN(value)

                                                            await setTuEndN(value)

                                                            await add_timings_time(e, "tue", value.format(format), value.add(1, 'hours').format(format))
                                                        }}
                                                        format={format}
                                                        use12Hours
                                                        inputReadOnly
                                                        allowEmpty={false}
                                                    />
                                                    {/* <Form.Control type='time' value={tuStartN || ''} required onChange={async (e) => {
                                                        add_timings_time(e, "tue", e.target.value, String(Number(String(e.target.value).substring(0, 2)) + 1) + String(e.target.value).slice(2))
                                                        await setTuStartN(e.target.value)

                                                        await setTuEndN((String(Number(String(e.target.value).substring(0, 2)) + 1)).toString().padStart(2, 0) + String(e.target.value).slice(2))
                                                    }} /> */}
                                                    <Form.Group className="mt-2">
                                                        {/* <Form.Label >End Time</Form.Label> */}
                                                        <Form.Control type='time' disabled={tuStartN ? 'false' : 'true'} value={tuEndN || ''} required onChange={(e) => setTuEndN(e.target.value)} />
                                                    </Form.Group>
                                                </Form.Group>}
                                            <Button variant="primary" onClick={() => setTShowN(true)} style={{ marginBottom: "45px" }} className="shadow-none btn bg-transparent btn-outline-transparent border-0">

                                                <div className="add-new-time">
                                                    <PlusCircleFill className="add-new-time-icon" style={{ marginLeft: 10 }} size={30} color='#2c278b' />
                                                </div>

                                            </Button>
                                        </Row>
                                    </div>
                                </Col>

                            </Row>
                        </Form>
                        {/* new end */}
                        {/* new start */}
                        <Row className="align-items-center">
                            <Col xs={1}>
                                <Form.Check style={{ marginBottom: '10px' }} type="checkbox" checked={isWeN} onChange={(e) => handleEditCheckbox(e, 'wed')} />
                            </Col>
                            <Col xs={11}>

                                <div style={{ width: '100%', height: '40px', border: '2px solid black', marginBottom: '15px', textAlign: 'center',opacity: isWeN ? 1 : 0.4 }}><h4>Wednesday</h4></div>
                            </Col>
                        </Row>

                        <Form className='mt-4' >
                            <Row className="align-items-center">

                                <Col xs={2} style={{
                                    width: '10.499999995%',
                                    flex: '0 0 10.499%', maxWidth: '10.499%', marginBottom: '60px'
                                }}>
                                    <Form.Label style={{ fontWeight: 'bold' }}>Trip No.</Form.Label><br></br>

                                    <Form.Group>
                                        <Form.Label style={{ fontWeight: 'bold' }} >Start Time</Form.Label>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label style={{ fontWeight: 'bold' }}>End Time</Form.Label>
                                    </Form.Group>
                                    <Form.Group>


                                    </Form.Group>
                                </Col>
                                <Col xs={10} style={{ opacity: isWeN ? 1 : 0.4, pointerEvents: isWeN ? '' : 'none' }}>
                                    <div className="scroll">
                                        <Row className="align-items-center scroll2">
                                            {(wednesdayTimingE.concat(wednesdayTimingN).length > 0) && sort_by_key(wednesdayTimingE.concat(wednesdayTimingN), "start_time").map((timing, index) =>
                                                <Form.Group style={{ width: '100px', marginRight: '10px', marginLeft: '0px' }} key={timing.uuid}>
                                                    {/* <Form.Label >Start Time</Form.Label> */}
                                                    <Form.Label style={{ fontWeight: 'bold' }}>{index + 1}</Form.Label><br></br>

                                                    <TimePicker
                                                        showSecond={false}
                                                        defaultValue={moment(timing.start_time, 'h:mm a')}
                                                        className="xxx"
                                                        onChange={(value) => edit_timings_time(e, 'wed', timing.uuid, value.format(format), timing.end_time, timing.schedule_id)}
                                                        format={format}
                                                        value={moment(timing.start_time, 'h:mm a')}

                                                        use12Hours
                                                        allowEmpty={false}

                                                    />
                                                    <Form.Group className="mt-2">
                                                        <TimePicker
                                                            showSecond={false}
                                                            defaultValue={moment(timing.end_time, 'h:mm a')}
                                                            className="xxx"
                                                            onChange={(value) => edit_timings_time(e, 'wed', timing.uuid, timing.start_time, value.format(format), timing.schedule_id)}
                                                            format={format}
                                                            value={moment(timing.end_time, 'h:mm a')}

                                                            use12Hours
                                                            allowEmpty={false}
                                                        />
                                                    </Form.Group>
                                                    <Button variant="danger" onClick={(e) => {
                                                        // if contains in E then directly remove from db, but if not in E, remove from N
                                                        if (wednesdayTimingE.length > 0) {
                                                            wednesdayTimingE.some(element => {
                                                                if (element.uuid == timing.uuid) {
                                                                    setTimingsToDelete(prev => [...prev, timing.uuid])

                                                                    // handleScheduleRecordDelete(timing.uuid)
                                                                    setWednesdayTimingE(wednesdayTimingE.filter(item => item.uuid !== timing.uuid))

                                                                }
                                                                else {
                                                                    setWednesdayTimingN(wednesdayTimingN.filter(item => item.uuid !== timing.uuid))
                                                                }
                                                            })

                                                        }

                                                        else {
                                                            setWednesdayTimingN(wednesdayTimingN.filter(item => item.uuid !== timing.uuid))
                                                        }
                                                    }}
                                                        size="sm"><TrashFill color=" #ffffff" size={20} /> </Button>

                                                </Form.Group>
                                            )}
                                            {(wednesdayTimingE.concat(wednesdayTimingN).length == 0 || wShowN) &&

                                                <Form.Group style={{ width: '100px', marginBottom: '10px' }}>
                                                    {/* <Form.Label >Start Time</Form.Label> */}
                                                    <TimePicker
                                                        showSecond={false}
                                                        className="xxx"
                                                        onChange={async (value) => {

                                                            await setWeStartN(value)

                                                            await setWeEndN(value)

                                                            await add_timings_time(e, "wed", value.format(format), value.add(1, 'hours').format(format))
                                                        }}
                                                        format={format}
                                                        use12Hours
                                                        inputReadOnly
                                                        allowEmpty={false}
                                                    />
                                                    {/* <Form.Control type='time' value={weStartN || ''} required onChange={async (e) => {
                                                        add_timings_time(e, "wed", e.target.value, String(Number(String(e.target.value).substring(0, 2)) + 1) + String(e.target.value).slice(2))
                                                        await setWeStartN(e.target.value)

                                                        await setWeEndN((String(Number(String(e.target.value).substring(0, 2)) + 1)).toString().padStart(2, 0) + String(e.target.value).slice(2))
                                                    }} /> */}
                                                    <Form.Group className="mt-2">
                                                        {/* <Form.Label >End Time</Form.Label> */}
                                                        <Form.Control type='time' disabled={weStartN ? 'false' : 'true'} value={weEndN || ''} required onChange={(e) => setWeEndN(e.target.value)} />
                                                    </Form.Group>
                                                </Form.Group>}
                                            <Button variant="primary" onClick={() => setWShowN(true)} style={{ marginBottom: "45px" }} className="shadow-none btn bg-transparent btn-outline-transparent border-0">

                                                <div className="add-new-time">
                                                    <PlusCircleFill className="add-new-time-icon" style={{ marginLeft: 10 }} size={30} color='#2c278b' />
                                                </div>

                                            </Button>
                                        </Row>
                                    </div>
                                </Col>

                            </Row>
                        </Form>
                        {/* new end */}
                        {/* new start */}
                        <Row className="align-items-center">
                            <Col xs={1}>
                                <Form.Check style={{ marginBottom: '10px' }} type="checkbox" checked={isThN} onChange={(e) => handleEditCheckbox(e, 'thu')} />
                            </Col>
                            <Col xs={11}>

                                <div style={{ width: '100%', height: '40px', border: '2px solid black', marginBottom: '15px', textAlign: 'center' , opacity: isThN ? 1 : 0.4}}><h4>Thursday</h4></div>
                            </Col>
                        </Row>

                        <Form className='mt-4'>
                            <Row className="align-items-center">

                                <Col xs={2} style={{
                                    width: '10.499999995%',
                                    flex: '0 0 10.499%', maxWidth: '10.499%', marginBottom: '60px'
                                }}>
                                    <Form.Label style={{ fontWeight: 'bold' }}>Trip No.</Form.Label><br></br>

                                    <Form.Group>
                                        <Form.Label style={{ fontWeight: 'bold' }} >Start Time</Form.Label>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label style={{ fontWeight: 'bold' }}>End Time</Form.Label>
                                    </Form.Group>
                                    <Form.Group>


                                    </Form.Group>
                                </Col>
                                <Col xs={10} style={{ opacity: isThN ? 1 : 0.4, pointerEvents: isThN ? '' : 'none' }}>
                                    <div className="scroll">
                                        <Row className="align-items-center scroll2">
                                            {(thursdayTimingE.concat(thursdayTimingN).length > 0) && sort_by_key(thursdayTimingE.concat(thursdayTimingN), "start_time").map((timing, index) =>
                                                <Form.Group style={{ width: '100px', marginRight: '10px', marginLeft: '0px' }} key={timing.uuid}>
                                                    {/* <Form.Label >Start Time</Form.Label> */}
                                                    <Form.Label style={{ fontWeight: 'bold' }}>{index + 1}</Form.Label><br></br>

                                                    <TimePicker
                                                        showSecond={false}
                                                        defaultValue={moment(timing.start_time, 'h:mm a')}
                                                        className="xxx"
                                                        onChange={(value) => edit_timings_time(e, 'thu', timing.uuid, value.format(format), timing.end_time, timing.schedule_id)}
                                                        format={format}
                                                        value={moment(timing.start_time, 'h:mm a')}

                                                        use12Hours
                                                        allowEmpty={false}

                                                    />
                                                    <Form.Group className="mt-2">
                                                        <TimePicker
                                                            showSecond={false}
                                                            defaultValue={moment(timing.end_time, 'h:mm a')}
                                                            className="xxx"
                                                            onChange={(value) => edit_timings_time(e, 'thu', timing.uuid, timing.start_time, value.format(format), timing.schedule_id)}
                                                            format={format}
                                                            value={moment(timing.end_time, 'h:mm a')}

                                                            use12Hours
                                                            allowEmpty={false}
                                                        />
                                                    </Form.Group>
                                                    <Button variant="danger" onClick={(e) => {
                                                        // if contains in E then directly remove from db, but if not in E, remove from N
                                                        if (thursdayTimingE.length > 0) {
                                                            thursdayTimingE.some(element => {
                                                                if (element.uuid == timing.uuid) {
                                                                    setTimingsToDelete(prev => [...prev, timing.uuid])

                                                                    // handleScheduleRecordDelete(timing.uuid)
                                                                    setThursdayTimingE(thursdayTimingE.filter(item => item.uuid !== timing.uuid))

                                                                }
                                                                else {
                                                                    setThursdayTimingN(thursdayTimingN.filter(item => item.uuid !== timing.uuid))
                                                                }
                                                            })

                                                        }
                                                        else {
                                                            setThursdayTimingN(thursdayTimingN.filter(item => item.uuid !== timing.uuid))
                                                        }
                                                    }
                                                    } size="sm"><TrashFill color=" #ffffff" size={20} /> </Button>

                                                </Form.Group>
                                            )}
                                            {(thursdayTimingE.concat(thursdayTimingN).length == 0 || t2ShowN) &&

                                                <Form.Group style={{ width: '100px', marginBottom: '10px' }}>
                                                    {/* <Form.Label >Start Time</Form.Label> */}
                                                    <TimePicker
                                                        showSecond={false}
                                                        className="xxx"
                                                        onChange={async (value) => {

                                                            await setThStartN(value)

                                                            await setThEndN(value)

                                                            await add_timings_time(e, "thu", value.format(format), value.add(1, 'hours').format(format))
                                                        }}
                                                        format={format}
                                                        use12Hours
                                                        inputReadOnly
                                                        allowEmpty={false}
                                                    />
                                                    {/* <Form.Control type='time' value={thStartN || ''} required onChange={async (e) => {
                                                        add_timings_time(e, "thu", e.target.value, String(Number(String(e.target.value).substring(0, 2)) + 1) + String(e.target.value).slice(2))
                                                        await setThStartN(e.target.value)

                                                        await setThEndN((String(Number(String(e.target.value).substring(0, 2)) + 1)).toString().padStart(2, 0) + String(e.target.value).slice(2))
                                                    }} /> */}
                                                    <Form.Group className="mt-2">
                                                        {/* <Form.Label >End Time</Form.Label> */}
                                                        <Form.Control type='time' disabled={thStartN ? 'false' : 'true'} value={thEndN || ''} required onChange={(e) => setThEndN(e.target.value)} />
                                                    </Form.Group>
                                                </Form.Group>}
                                            <Button variant="primary" onClick={() => setT2ShowN(true)} style={{ marginBottom: "45px" }} className="shadow-none btn bg-transparent btn-outline-transparent border-0">

                                                <div className="add-new-time">
                                                    <PlusCircleFill className="add-new-time-icon" style={{ marginLeft: 10 }} size={30} color='#2c278b' />
                                                </div>

                                            </Button>
                                        </Row>
                                    </div>
                                </Col>

                            </Row>
                        </Form>
                        {/* new end */}
                        {/* new start */}
                        <Row className="align-items-center">
                            <Col xs={1}>
                                <Form.Check style={{ marginBottom: '10px' }} type="checkbox" checked={isFrN} onChange={(e) => handleEditCheckbox(e, 'fri')} />
                            </Col>
                            <Col xs={11}>

                                <div style={{ width: '100%', height: '40px', border: '2px solid black', marginBottom: '15px', textAlign: 'center',opacity: isFrN ? 1 : 0.4 }}><h4>Friday</h4></div>
                            </Col>
                        </Row>

                        <Form className='mt-4'>
                            <Row className="align-items-center">

                                <Col xs={2} style={{
                                    width: '10.499999995%',
                                    flex: '0 0 10.499%', maxWidth: '10.499%', marginBottom: '60px'
                                }}>
                                    <Form.Label style={{ fontWeight: 'bold' }}>Trip No.</Form.Label><br></br>

                                    <Form.Group>
                                        <Form.Label style={{ fontWeight: 'bold' }} >Start Time</Form.Label>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label style={{ fontWeight: 'bold' }}>End Time</Form.Label>
                                    </Form.Group>
                                    <Form.Group>


                                    </Form.Group>
                                </Col>
                                <Col xs={10} style={{ opacity: isFrN ? 1 : 0.4, pointerEvents: isFrN ? '' : 'none' }}>
                                    <div className="scroll">
                                        <Row className="align-items-center scroll2">
                                            {(fridayTimingE.concat(fridayTimingN).length > 0) && sort_by_key(fridayTimingE.concat(fridayTimingN), "start_time").map((timing, index) =>
                                                <Form.Group style={{ width: '100px', marginRight: '10px', marginLeft: '0px' }} key={timing.uuid}>
                                                    {/* <Form.Label >Start Time</Form.Label> */}
                                                    <Form.Label style={{ fontWeight: 'bold' }}>{index + 1}</Form.Label><br></br>

                                                    <TimePicker
                                                        showSecond={false}
                                                        defaultValue={moment(timing.start_time, 'h:mm a')}
                                                        className="xxx"
                                                        onChange={(value) => edit_timings_time(e, 'fri', timing.uuid, value.format(format), timing.end_time, timing.schedule_id)}
                                                        format={format}
                                                        value={moment(timing.start_time, 'h:mm a')}

                                                        use12Hours
                                                        allowEmpty={false}

                                                    />
                                                    <Form.Group className="mt-2">
                                                        <TimePicker
                                                            showSecond={false}
                                                            defaultValue={moment(timing.end_time, 'h:mm a')}
                                                            className="xxx"
                                                            onChange={(value) => edit_timings_time(e, 'fri', timing.uuid, timing.start_time, value.format(format), timing.schedule_id)}
                                                            format={format}
                                                            value={moment(timing.end_time, 'h:mm a')}

                                                            use12Hours
                                                            allowEmpty={false}
                                                        />
                                                    </Form.Group>
                                                    <Button variant="danger" onClick={(e) => {
                                                        // if contains in E then directly remove from db, but if not in E, remove from N
                                                        if (fridayTimingE.length > 0) {
                                                            fridayTimingE.some(element => {
                                                                if (element.uuid == timing.uuid) {
                                                                    setTimingsToDelete(prev => [...prev, timing.uuid])

                                                                    // handleScheduleRecordDelete(timing.uuid)
                                                                    setFridayTimingE(fridayTimingE.filter(item => item.uuid !== timing.uuid))

                                                                }
                                                                else {
                                                                    setFridayTimingN(fridayTimingN.filter(item => item.uuid !== timing.uuid))
                                                                }
                                                            })

                                                        }
                                                        else {
                                                            setFridayTimingN(fridayTimingN.filter(item => item.uuid !== timing.uuid))
                                                        }
                                                    }
                                                    } size="sm"><TrashFill color=" #ffffff" size={20} /> </Button>

                                                </Form.Group>
                                            )}
                                            {(fridayTimingE.concat(fridayTimingN).length == 0 || fShowN) &&

                                                <Form.Group style={{ width: '100px', marginBottom: '10px' }}>
                                                    {/* <Form.Label >Start Time</Form.Label> */}
                                                    <TimePicker
                                                        showSecond={false}
                                                        className="xxx"
                                                        onChange={async (value) => {

                                                            await setFrStartN(value)

                                                            await setFrEndN(value)

                                                            await add_timings_time(e, "fri", value.format(format), value.add(1, 'hours').format(format))
                                                        }}
                                                        format={format}
                                                        use12Hours
                                                        inputReadOnly
                                                        allowEmpty={false}
                                                    />
                                                    {/* <Form.Control type='time' value={frStartN || ''} required onChange={async (e) => {
                                                        add_timings_time(e, "fri", e.target.value, String(Number(String(e.target.value).substring(0, 2)) + 1) + String(e.target.value).slice(2))
                                                        await setFrStartN(e.target.value)

                                                        await setFrEndN((String(Number(String(e.target.value).substring(0, 2)) + 1)).toString().padStart(2, 0) + String(e.target.value).slice(2))
                                                    }} /> */}
                                                    <Form.Group className="mt-2">
                                                        {/* <Form.Label >End Time</Form.Label> */}
                                                        <Form.Control type='time' value={frEndN || ''} required onChange={(e) => setFrEndN(e.target.value)} />
                                                    </Form.Group>
                                                </Form.Group>}
                                            <Button variant="primary" onClick={() => setFShowN(true)} style={{ marginBottom: "45px" }} className="shadow-none btn bg-transparent btn-outline-transparent border-0">

                                                <div className="add-new-time">
                                                    <PlusCircleFill className="add-new-time-icon" style={{ marginLeft: 10 }} size={30} color='#2c278b' />
                                                </div>

                                            </Button>
                                        </Row>
                                    </div>
                                </Col>

                            </Row>
                        </Form>
                        {/* new end */}
                        {/* new start */}
                        <Row className="align-items-center">
                            <Col xs={1}>
                                <Form.Check style={{ marginBottom: '10px' }} type="checkbox" checked={isSaN} onChange={(e) => handleEditCheckbox(e, 'sat')} />
                            </Col>
                            <Col xs={11}>

                                <div style={{ width: '100%', height: '40px', border: '2px solid black', marginBottom: '15px', textAlign: 'center',opacity: isSaN ? 1 : 0.4 }}><h4>Saturday</h4></div>
                            </Col>
                        </Row>

                        <Form className='mt-4'>
                            <Row className="align-items-center">

                                <Col xs={2} style={{
                                    width: '10.499999995%',
                                    flex: '0 0 10.499%', maxWidth: '10.499%', marginBottom: '60px'
                                }}>
                                    <Form.Label style={{ fontWeight: 'bold' }}>Trip No.</Form.Label><br></br>

                                    <Form.Group>
                                        <Form.Label style={{ fontWeight: 'bold' }} >Start Time</Form.Label>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label style={{ fontWeight: 'bold' }}>End Time</Form.Label>
                                    </Form.Group>
                                    <Form.Group>


                                    </Form.Group>
                                </Col>
                                <Col xs={10} style={{ opacity: isSaN ? 1 : 0.4, pointerEvents: isSaN ? '' : 'none' }}>
                                    <div className="scroll">
                                        <Row className="align-items-center scroll2">
                                            {(saturdayTimingE.concat(saturdayTimingN).length > 0) && sort_by_key(saturdayTimingE.concat(saturdayTimingN), "start_time").map((timing, index) =>
                                                <Form.Group style={{ width: '100px', marginRight: '10px', marginLeft: '0px' }} key={timing.uuid}>
                                                    {/* <Form.Label >Start Time</Form.Label> */}
                                                    <Form.Label style={{ fontWeight: 'bold' }}>{index + 1}</Form.Label><br></br>

                                                    <TimePicker
                                                        showSecond={false}
                                                        defaultValue={moment(timing.start_time, 'h:mm a')}
                                                        className="xxx"
                                                        onChange={(value) => edit_timings_time(e, 'sat', timing.uuid, value.format(format), timing.end_time, timing.schedule_id)}
                                                        format={format}
                                                        value={moment(timing.start_time, 'h:mm a')}

                                                        use12Hours
                                                        allowEmpty={false}

                                                    />
                                                    <Form.Group className="mt-2">
                                                        <TimePicker
                                                            showSecond={false}
                                                            defaultValue={moment(timing.end_time, 'h:mm a')}
                                                            className="xxx"
                                                            onChange={(value) => edit_timings_time(e, 'sat', timing.uuid, timing.start_time, value.format(format), timing.schedule_id)}
                                                            format={format}
                                                            value={moment(timing.end_time, 'h:mm a')}

                                                            use12Hours
                                                            allowEmpty={false}
                                                        />
                                                    </Form.Group>
                                                    <Button variant="danger" onClick={(e) => {
                                                        // if contains in E then directly remove from db, but if not in E, remove from N
                                                        
                                                        if (saturdayTimingE.length > 0) {
                                                            saturdayTimingE.some(element => {
                                                                if (element.uuid == timing.uuid) {
                                                                    setTimingsToDelete(prev => [...prev, timing.uuid])

                                                                    // handleScheduleRecordDelete(timing.uuid)
                                                                    setSaturdayTimingE(saturdayTimingE.filter(item => item.uuid !== timing.uuid))

                                                                }
                                                                else {
                                                                    setSaturdayTimingN(saturdayTimingN.filter(item => item.uuid !== timing.uuid))
                                                                }
                                                            })

                                                        }
                                                        else {
                                                            setSaturdayTimingN(saturdayTimingN.filter(item => item.uuid !== timing.uuid))
                                                        }
                                                    }
                                                    } size="sm"><TrashFill color=" #ffffff" size={20} /> </Button>

                                                </Form.Group>

                                            )}
                                            {(saturdayTimingE.concat(saturdayTimingN).length == 0 || sShowN) &&

                                                <Form.Group style={{ width: '100px', marginBottom: '10px' }}>
                                                    {/* <Form.Label >Start Time</Form.Label> */}
                                                    <TimePicker
                                                        showSecond={false}
                                                        className="xxx"
                                                        onChange={async (value) => {

                                                            await setSaStartN(value)

                                                            await setSaEndN(value)

                                                            await add_timings_time(e, "sat", value.format(format), value.add(1, 'hours').format(format))
                                                        }}
                                                        format={format}
                                                        use12Hours
                                                        inputReadOnly
                                                        allowEmpty={false}
                                                    />
                                                    {/* <Form.Control type='time' value={saStartN || ''} required onChange={async (e) => {
                                                        add_timings_time(e, "sat", e.target.value, String(Number(String(e.target.value).substring(0, 2)) + 1) + String(e.target.value).slice(2))
                                                        await setSaStartN(e.target.value)

                                                        await setSaEndN((String(Number(String(e.target.value).substring(0, 2)) + 1)).toString().padStart(2, 0) + String(e.target.value).slice(2))
                                                    }} /> */}
                                                    <Form.Group className="mt-2">
                                                        {/* <Form.Label >End Time</Form.Label> */}
                                                        <Form.Control type='time' disabled={saStartN ? 'false' : 'true'} value={saEndN || ''} required onChange={(e) => setSaEndN(e.target.value)} />
                                                    </Form.Group>
                                                </Form.Group>}
                                            <Button variant="primary" onClick={() => setSShowN(true)} style={{ marginBottom: "45px" }} className="shadow-none btn bg-transparent btn-outline-transparent border-0">

                                                <div className="add-new-time">
                                                    <PlusCircleFill className="add-new-time-icon" style={{ marginLeft: 10 }} size={30} color='#2c278b' />
                                                </div>

                                            </Button>
                                        </Row>
                                    </div>
                                </Col>

                            </Row>
                        </Form>
                        {/* new end */}
                        {/* new start */}
                        <Row className="align-items-center">
                            <Col xs={1}>
                                <Form.Check style={{ marginBottom: '10px' }} type="checkbox" checked={isSuN} onChange={(e) => handleEditCheckbox(e, 'sun')} />
                            </Col>
                            <Col xs={11}>

                                <div style={{ width: '100%', height: '40px', border: '2px solid black', marginBottom: '15px', textAlign: 'center',opacity: isSuN ? 1 : 0.4 }}><h4>Sunday</h4></div>
                            </Col>
                        </Row>

                        <Form className='mt-4'>
                            <Row className="align-items-center">

                                <Col xs={2} style={{
                                    width: '10.499999995%',
                                    flex: '0 0 10.499%', maxWidth: '10.499%', marginBottom: '60px'
                                }}>
                                    <Form.Label style={{ fontWeight: 'bold' }}>Trip No.</Form.Label><br></br>

                                    <Form.Group>
                                        <Form.Label style={{ fontWeight: 'bold' }} >Start Time</Form.Label>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label style={{ fontWeight: 'bold' }}>End Time</Form.Label>
                                    </Form.Group>
                                    <Form.Group>


                                    </Form.Group>
                                </Col>
                                <Col xs={10} style={{ opacity: isSuN ? 1 : 0.4, pointerEvents: isSuN ? '' : 'none' }}>
                                    <div className="scroll">
                                        <Row className="align-items-center scroll2">
                                            {(sundayTimingE.concat(sundayTimingN).length > 0) && sort_by_key(sundayTimingE.concat(sundayTimingN), "start_time").map((timing, index) =>
                                                <Form.Group style={{ width: '100px', marginRight: '10px', marginLeft: '0px' }} key={timing.uuid}>
                                                    {/* <Form.Label >Start Time</Form.Label> */}
                                                    <Form.Label style={{ fontWeight: 'bold' }}>{index + 1}</Form.Label><br></br>

                                                    <TimePicker
                                                        showSecond={false}
                                                        defaultValue={moment(timing.start_time, 'h:mm a')}
                                                        className="xxx"
                                                        onChange={(value) => edit_timings_time(e, 'sun', timing.uuid, value.format(format), timing.end_time, timing.schedule_id)}
                                                        format={format}
                                                        value={moment(timing.start_time, 'h:mm a')}

                                                        use12Hours
                                                        allowEmpty={false}

                                                    />
                                                    <Form.Group className="mt-2">
                                                        <TimePicker
                                                            showSecond={false}
                                                            defaultValue={moment(timing.end_time, 'h:mm a')}
                                                            className="xxx"
                                                            onChange={(value) => edit_timings_time(e, 'sun', timing.uuid, timing.start_time, value.format(format), timing.schedule_id)}
                                                            format={format}
                                                            value={moment(timing.end_time, 'h:mm a')}

                                                            use12Hours
                                                            allowEmpty={false}
                                                        />
                                                    </Form.Group>
                                                    <Button variant="danger" onClick={(e) => {
                                                        // if contains in E then directly remove from db, but if not in E, remove from N
                                                        if (sundayTimingE.length > 0) {
                                                            sundayTimingE.some(element => {
                                                                if (element.uuid == timing.uuid) {
                                                                    setTimingsToDelete(prev => [...prev, timing.uuid])

                                                                    // handleScheduleRecordDelete(timing.uuid)
                                                                    setSundayTimingE(sundayTimingE.filter(item => item.uuid !== timing.uuid))

                                                                }
                                                                else {
                                                                    setSundayTimingN(sundayTimingN.filter(item => item.uuid !== timing.uuid))
                                                                }
                                                            })

                                                        }
                                                        else {
                                                            setSundayTimingN(sundayTimingN.filter(item => item.uuid !== timing.uuid))
                                                        }
                                                    }
                                                    } size="sm"><TrashFill color=" #ffffff" size={20} /> </Button>

                                                </Form.Group>
                                            )}
                                            {(sundayTimingE.concat(sundayTimingN).length == 0 || s2ShowN) &&

                                                <Form.Group style={{ width: '100px', marginBottom: '10px' }}>
                                                    {/* <Form.Label >Start Time</Form.Label> */}
                                                    <TimePicker
                                                        showSecond={false}
                                                        className="xxx"
                                                        onChange={async (value) => {

                                                            await setSuStartN(value)

                                                            await setSuEndN(value)

                                                            await add_timings_time(e, "sun", value.format(format), value.add(1, 'hours').format(format))
                                                        }}
                                                        format={format}
                                                        use12Hours
                                                        inputReadOnly
                                                        allowEmpty={false}
                                                    />
                                                    {/* <Form.Control type='time' value={suStartN || ''} required onChange={async (e) => {
                                                        add_timings_time(e, "sun", e.target.value, String(Number(String(e.target.value).substring(0, 2)) + 1) + String(e.target.value).slice(2))
                                                        await setSuStartN(e.target.value)

                                                        await setSuEndN((String(Number(String(e.target.value).substring(0, 2)) + 1)).toString().padStart(2, 0) + String(e.target.value).slice(2))
                                                    }} /> */}
                                                    <Form.Group className="mt-2">
                                                        {/* <Form.Label >End Time</Form.Label> */}
                                                        <Form.Control type='time' disabled={suStartN ? 'false' : 'true'} value={suEndN || ''} required onChange={(e) => setSuEndN(e.target.value)} />
                                                    </Form.Group>
                                                </Form.Group>}
                                            <Button variant="primary" onClick={() => setS2ShowN(true)} style={{ marginBottom: "45px" }} className="shadow-none btn bg-transparent btn-outline-transparent border-0">

                                                <div className="add-new-time">
                                                    <PlusCircleFill className="add-new-time-icon" style={{ marginLeft: 10 }} size={30} color='#2c278b' />
                                                </div>

                                            </Button>
                                        </Row>
                                    </div>
                                </Col>

                            </Row>
                        </Form>
                    </div>
                </Modal.Body>

            </Modal>
            {/* edit modal end */}
        </>
    );
};

export default ScheduleV2Page;
