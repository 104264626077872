import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Alert, Button, Card, Col, Collapse, Container, Form, InputGroup, Row } from 'react-bootstrap'
import { InfoCircle, PencilSquare, PlusCircle,Eye } from 'react-bootstrap-icons'

import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Modal, PhotoProfile, Table, SimpleInputGroup, SimpleSwitch, FailedAlert } from '../../components'
import { ACTION_TYPES, API_END_POINTS, GENERAL } from '../../config'
import { ApiRequest } from '../../helpers'

const ExecutiveForm = ({ onClickCancel, onSubmitForm, defaultValues = { firstName: '', lastName: '', userType: '', isActive: '' } }) => {
    const [firstName, setFirstName] = useState(null)
    const [lastName, setLastName] = useState(null)
    const [userType, setUserType] = useState(null)
    const [isActive, setIsActive] = useState(null)

    const handleBeforeSubmit = (e) => {
        e.preventDefault()

        const data = {}

        if (firstName !== null) {
            data.firstName = firstName
        }
        if (lastName !== null) {
            data.lastName = lastName
        }
        if (userType !== null) {
            data.userType = userType
        }
        if (isActive !== null) {
            data.isActive = isActive
        }

        const dataId = defaultValues.id
        onSubmitForm(dataId, data)
        onClickCancel()
    }

    return (
        <Row>
            <Col className='pt-3' lg={12} >
                <Form onSubmit={handleBeforeSubmit} >
                    <Card >
                        <Card.Body>
                            <Row>
                                <Col md={6} sm={12} >
                                    <Form.Group>
                                        <Form.Label >First Name</Form.Label>
                                        <Form.Control onChange={(e) => setFirstName(e.currentTarget.value)} placeholder='John' defaultValue={defaultValues.firstName} />
                                    </Form.Group>
                                </Col>
                                <Col md={6} sm={12} >
                                    <Form.Group>
                                        <Form.Label >Last Name</Form.Label>
                                        <Form.Control onChange={(e) => setLastName(e.currentTarget.value)} placeholder='Smith' defaultValue={defaultValues.lastName} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label >Role</Form.Label>
                                        <Form.Control custom as='select' onChange={(e) => setUserType(e.currentTarget.value)} defaultValue={defaultValues.userType} >
                                            {
                                                GENERAL.AGENCY_ROLES.map((role, key) => <option key={key} value={role} >{role}</option>)
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label >Active</Form.Label>
                                        <Form.Check type='switch' onChange={(e) => setIsActive(e.target.checked)} defaultChecked={defaultValues.isActive} id='edit-user-active' />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer className='d-flex justify-content-end' >
                            <Button className='mx-1' variant='warning' onClick={onClickCancel} >Cancel</Button>
                            <Button className='mx-1' variant='primary' type='submit' >Save Changes</Button>
                        </Card.Footer>
                    </Card>
                </Form>
            </Col>
        </Row>
    )
}

const SimpleInput = ({ label, value, readOnly = null, children, ...props }) => (
    <SimpleInputGroup preappendText={label} >
        <Form.Control size='sm' readOnly={readOnly} value={value} {...props} />
        {children}
    </SimpleInputGroup>
)

const ApadPasswordForm = ({ user, apadPassword, onclose = () => { } }) => {
    const [isAdminVerified, setIsAdminVerified] = useState(false)
    const [verifyPassword, setVerifyPassword] = useState('')
    const [toggleShowApadPass, setToggleShowApadPass] = useState(false)
    const [newApadPass, setNewApadPass] = useState(apadPassword)
    const [rawPass, setRawPass] = useState('')

    const handleRequestVerify = useCallback(async (e) => {
        e.preventDefault()
        if (isAdminVerified && newApadPass && rawPass && newApadPass !== rawPass) {
            handleUpdateNewApadPass()
        } else {
            if (verifyPassword.length > 0) {
                try {
                    const data = await ApiRequest.fetch({
                        method: 'post',
                        url: `${API_END_POINTS.VERIFY_SUPER_ADMIN}`,
                        data: {
                            password: verifyPassword
                        }
                    })
                    if (data?.isVerified) {
                        setVerifyPassword('')
                        setIsAdminVerified(true)
                        handleRequestRawPass()
                    }
                } catch (e) { }
            }
        }
    }, [verifyPassword, isAdminVerified, newApadPass, rawPass])

    const handleRequestRawPass = useCallback(async () => {
        try {
            const data = await ApiRequest.fetch({
                method: 'get',
                url: `${API_END_POINTS.GET_APAD_AGENCY}`,
            })
            if (data?.rawApadPassword) {
                setNewApadPass(data?.rawApadPassword)
                setRawPass(data?.rawApadPassword)
            }
        } catch (e) { }
    }, [])

    const handleUpdateNewApadPass = useCallback(async () => {
        try {
            const data = await ApiRequest.fetch({
                method: 'put',
                url: `${API_END_POINTS.GET_APAD_AGENCY}`,
                data: {
                    newApadPass
                }
            })
            onclose()
        } catch (e) { }
    }, [newApadPass, onclose])


    return (
        <Form onSubmit={handleRequestVerify} >
            <Form.Group>
                {
                    isAdminVerified ?
                        <div className='d-flex flex-column' >
                            <SimpleInput readOnly={user.userType !== 'SUPER_ADMIN'} onChange={e => setNewApadPass(e.target.value)} label="APAD Password" type={toggleShowApadPass ? '' : 'password'} value={newApadPass} >
                                <InputGroup.Append >
                                    <Button onClick={() => { setToggleShowApadPass(p => !p) }} ><Eye /></Button>
                                </InputGroup.Append>
                            </SimpleInput>
                            {
                                isAdminVerified && newApadPass && rawPass && newApadPass !== rawPass &&
                                < Button className='mt-2' variant='primary' type='submit' >Save Changes</Button>
                            }
                        </div>
                        :
                        <SimpleInput onChange={e => setVerifyPassword(e.target.value)} label="Enter dashboard password to verify" value={verifyPassword} />
                }
            </Form.Group>
        </Form >
    )
}

const AgencyProfilePage = ({ user, requestUserUploadAgencyLogo, requestUpdateAgencyProfile }) => {
    //update agency profile
    const [editAgencyName, setEditAgencyName] = useState(null)
    const [editFasspayDeveloperId, setEditFasspayDeveloperId] = useState(null)
    const [editAgencyPhoneNo, setEditAgencyPhoneNo] = useState(null)
    const [editAgencyHotlineEmail, setEditAgencyHotlineEmail] = useState(null)
    const [editAgencyHotlinePhone, setEditAgencyHotlinePhone] = useState(null)
    const [editAgencyFleetSize, setEditAgencyFleetSize] = useState(null)
    const [maxVehicleCount, setMaxVehicleCount] = useState(user.agency.maxVehicleCount);
    const [maxDriverCount, setMaxDriverCount] = useState(user.agency.maxDriverCount);
    const [maxRouteCount, setMaxRouteCount] = useState(user.agency.maxRouteCount);
    const [maxTripCount, setMaxTripCount] = useState(user.agency.maxTripCount);

    const [apadUsername, setApadUsername] = useState(user?.agency?.apadUsername);
    const [apadPassword, setApadPassword] = useState(user?.agency?.apadPassword);

    const fleetSizeOptions = [
        '1 - 4',
        '5 - 9',
        '10 - 19',
        '20 - 99',
        '100 - 499',
        '500 - 999',
        '1,000 and above'
    ]

    const handleCloseEdit = () => {
        setEditAgencyName(null)
        setEditFasspayDeveloperId(null)
        setEditAgencyPhoneNo(null)
        setEditAgencyHotlineEmail(null)
        setEditAgencyHotlinePhone(null)
        setEditAgencyFleetSize(null)
        setMaxVehicleCount(user.agency.maxVehicleCount)
        setMaxDriverCount(user.agency.maxDriverCount)
        setMaxRouteCount(user.agency.maxRouteCount)
        setMaxTripCount(user.agency.maxTripCount)

        setApadUsername(user?.agency?.apadUsername)
        setApadPassword(user?.agency?.apadPassword)
    }

    const navigation = useHistory()

    const handleUpdateAgencyProfile = (e) => {
        e.preventDefault()
        const editedData = {}
        if (editAgencyName) { editedData.agencyName = editAgencyName }
        if (editFasspayDeveloperId) { editedData.fasspayDeveloperId = editFasspayDeveloperId }
        if (editAgencyPhoneNo) { editedData.phoneNumber = editAgencyPhoneNo }
        if (editAgencyHotlineEmail) { editedData.supportEmail = editAgencyHotlineEmail }
        if (editAgencyHotlinePhone) { editedData.supportHotline = editAgencyHotlinePhone }
        if (editAgencyFleetSize) { editedData.fleetSize = editAgencyFleetSize }

        if (maxVehicleCount !== user?.agency?.maxVehicleCount) { editedData.maxVehicleCount = maxVehicleCount }
        if (maxDriverCount !== user?.agency?.maxDriverCount) { editedData.maxDriverCount = maxDriverCount }
        if (maxRouteCount !== user?.agency?.maxRouteCount) { editedData.maxRouteCount = maxRouteCount }
        if (maxTripCount !== user?.agency?.maxTripCount) { editedData.maxTripCount = maxTripCount }

        if (apadUsername !== user?.agency?.apadUsername) { editedData.apadUsername = apadUsername }
        if (apadPassword !== user?.agency?.apadPassword) { editedData.apadPassword = apadPassword }

        if (Object.keys(editedData).length > 0) {
            requestUpdateAgencyProfile(editedData)
        }
        handleCloseEdit()
        navigation.push('/profile')
    }

    //manage executive CRUD
    const [add, setAdd] = useState(false)
    const [mountForm, setMountForm] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [executives, setExecutives] = useState(null)
    const [showEditModal, setShowEditModal] = useState(null)
    const [selectedExecutive, setSelectedExecutive] = useState(null)
    const [showApadPassModal, setShowApadPassModal] = useState(false)


    const handlePhotoUpload = (name, file) => {
        const form = new FormData()
        form.append(name, file)

        requestUserUploadAgencyLogo(form)
    }

    const handleEdit = (index) => {
        setSelectedExecutive(index)
        setShowEditModal(true)
    }

    const appendAction = (data) => {
        data.map((row, i) => {
            row['onEdit'] = <PencilSquare className='g-hover-pointer text-primary' onClick={() => handleEdit(i)} />
            row['isVerified'] = row.isEmailVerified ? 'COMPLETE' : 'PENDING'
            row['name'] = `${row.firstName} ${row.lastName}`
        })
        return data
    }

    const handleExecutiveUpdate = (id, data) => {
        // 
        ApiRequest.fetch({
            method: 'put',
            url: `${API_END_POINTS.UPDATE_BASIC_USER}/${id}`,
            data
        }).then(() => {
            toast.success('User Updated!')
            setRefresh(!refresh)
        }).catch(e => { })
    }

    const handleClickCancel = () => {
        setSelectedExecutive(null)
        setShowEditModal(false)
    }

    const execTableHeader = useMemo(() => [
        {
            Header: 'Name',
            accessor: 'name',
            // disableFilters: true
        },
        {
            Header: 'Email',
            accessor: 'email',
            // disableFilters: true

        },
        {
            Header: 'Role',
            accessor: 'userType',
            // disableFilters: true

        },
        {
            Header: 'User Verification',
            accessor: 'isVerified',
            // disableFilters: true

        },
        {
            Header: 'Edit',
            accessor: 'onEdit',
            disableFilters: true
        }
    ], [])

    const handleCreateNewExecutive = async (e) => {
        e.preventDefault()

        if (!firstName || !lastName || !email) return handleCancelAdd()

        try {
            await ApiRequest.fetch({
                method: 'post',
                url: `${API_END_POINTS.CREATE_BASIC_USER}`,
                data: {
                    firstName,
                    lastName,
                    email
                }
            })
            toast.success(`${firstName} ${lastName} was added successfully`)
            setRefresh(!refresh)
        } catch (e) {

        } finally {
            handleCancelAdd()
        }
    }

    const handleGetAllExecutive = useCallback(async () => {
        try {
            const basics = await ApiRequest.fetch({
                method: 'get',
                url: `${API_END_POINTS.GET_BASIC_USER}`
            })
            // 
            setExecutives(basics)
        } catch (e) { }
    }, [])

    const handleCancelAdd = () => {
        setAdd(false)
        setMountForm(false)
        setLastName('')
        setFirstName('')
        setEmail('')
    }

    useEffect(() => {
        handleGetAllExecutive()
    }, [refresh])

    const dispatch = useDispatch()

    const handleToggleIsApadItg = useCallback(async (isApadItg) => {
        try {
            if (typeof isApadItg !== 'boolean') throw new Error('isApadItg is null')
            const payload = await ApiRequest.fetch({
                method: 'put',
                url: `${API_END_POINTS.UPDATE_APAD_ITG}`,
                data: {
                    isApadItg
                }
            })
            dispatch({
                type: ACTION_TYPES.UPDATE_AGENCY_INFO_SUCCEEDED,
                payload
            })
            toast.success(`APAD integration was ${isApadItg ? 'activated' : 'deactivated'}`)
        } catch (e) { }
    }, [dispatch])
    const handleToggleIsAllowReport = useCallback(async (isApadReport) => {
        try {
            if (typeof isApadReport !== 'boolean') throw new Error('isApadReport is null')
            const payload = await ApiRequest.fetch({
                method: 'put',
                url: `${API_END_POINTS.UPDATE_APAD_REPORT}`,
                data: {
                    isApadReport
                }
            })
            dispatch({
                type: ACTION_TYPES.UPDATE_AGENCY_INFO_SUCCEEDED,
                payload
            })
            toast.success(`APAD integration was ${isApadReport ? 'activated' : 'deactivated'}`)
        } catch (e) { }
    }, [dispatch])
    const handleToggleIsApadAccess = useCallback(async (isApadAccess) => {
        try {
            if (typeof isApadAccess !== 'boolean') throw new Error('isApadAccess is null')
            const payload = await ApiRequest.fetch({
                method: 'put',
                url: `${API_END_POINTS.UPDATE_APAD_ACCESS}`,
                data: {
                    isApadAccess
                }
            })
            dispatch({
                type: ACTION_TYPES.UPDATE_AGENCY_INFO_SUCCEEDED,
                payload
            })
            toast.success(`Data sharing with APAD is ${isApadAccess ? 'enabled' : 'disabled'}`)
        } catch (e) { }
    }, [dispatch])

    return (
        <Container className='mt-5' >
            <Link to='/profile' ><Button>Back To Profile</Button></Link>
            <Card className='mt-3' >
                <Card.Body>
                    <Card.Title>Agency Profile</Card.Title>
                    <PhotoProfile onConfirm={handlePhotoUpload} imageUrl={GENERAL.IS_PRODUCTION ? user.agency.imageUrl : `${GENERAL.AGENCY_PHOTO_LOCAL_BASE_URL}/${user.agency.imageUrl}`} name='agency_photo' />
                    <Form onSubmit={handleUpdateAgencyProfile} >
                        <SimpleInput onChange={e => setEditAgencyName(e.target.value)} label="Agency Name" value={editAgencyName || user.agency.name} />
                        {/* <SimpleInput onChange={e => setEditAgencyFleetSize(e.target.value)} label="Estimated Fleet Size" value={editAgencyFleetSize || user.agency.fleetSize} /> */}
                        <SimpleInput readOnly={user.userType !== 'SUPER_ADMIN'} onChange={e => setEditFasspayDeveloperId(e.target.value)} label="Fasspay Developer ID" value={editFasspayDeveloperId || user.agency.fasspayDeveloperId} />
                        <Form.Group >
                            <InputGroup size="sm">
                                <InputGroup.Prepend><InputGroup.Text>Estimated Fleet Size</InputGroup.Text></InputGroup.Prepend>

                                <Form.Control onChange={(e) => { setEditAgencyFleetSize(e.currentTarget.value) }} value={editAgencyFleetSize || user.agency.fleetSize} as='select' custom >
                                    {
                                        fleetSizeOptions.map((opt, i) => (<option key={i} value={opt} >{opt}</option>))
                                    }
                                </Form.Control>
                            </InputGroup>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label style={{ fontWeight: 700 }} >Agensi Pengangkutan Awam Darat (APAD) Integration</Form.Label>
                            <SimpleSwitch readOnly={user.userType !== 'SUPER_ADMIN'} onChange={handleToggleIsApadItg} defaultValue={user?.agency?.isApadItg} trueText='Active' falseText='Inactive' />
                            <Form.Label style={{ fontWeight: 700 }} >Allow Custom Reports?</Form.Label>
                            <SimpleSwitch readOnly={user.userType !== 'SUPER_ADMIN'} onChange={handleToggleIsAllowReport} defaultValue={user?.agency?.isApadReport} trueText='Active' falseText='Inactive' />
                            <Form.Label style={{ fontWeight: 700 }} >Share Data with APAD?</Form.Label>
                            <SimpleSwitch onChange={handleToggleIsApadAccess} defaultValue={user?.agency?.isApadAccess} trueText='ON' falseText='OFF' />
                            <SimpleInput readOnly={user.userType !== 'SUPER_ADMIN'} onChange={e => setApadUsername(e.target.value)} label="APAD Username" value={apadUsername || "Not Integrated"} />
                            <SimpleInput readOnly={true} onClick={() => setShowApadPassModal(true)} label="APAD Password" type='password' value={apadPassword || "password"} />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Contacts</Form.Label>
                            <SimpleInput onChange={e => setEditAgencyHotlinePhone(e.target.value)} label="Support Hotline" value={editAgencyHotlinePhone || user.agency.supportHotline} />
                            <SimpleInput onChange={e => setEditAgencyHotlineEmail(e.target.value)} label="Support Email" value={editAgencyHotlineEmail || user.agency.supportEmail} />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Subscription</Form.Label>
                            <SimpleInput readOnly label="Subscription" value={user.agency.maxVehicleCount > 1 ? "Custom Subscription" : "Free"} />
                            <SimpleInput onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} readOnly={user.userType !== 'SUPER_ADMIN' ? true : ''} onChange={e => setMaxVehicleCount(e.target.value)} label="Agency Vehicle Limit" value={maxVehicleCount || user.agency.maxVehicleCount} />
                            <SimpleInput onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} readOnly={user.userType !== 'SUPER_ADMIN' ? true : ''} onChange={e => setMaxDriverCount(e.target.value)} label="Agency Driver Limit" value={maxDriverCount || user.agency.max_driver_count} />
                            <SimpleInput onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} readOnly={user.userType !== 'SUPER_ADMIN' ? true : ''} onChange={e => setMaxRouteCount(e.target.value)} label="Agency Route Limit" value={maxRouteCount || user.agency.max_route_count} />
                            <SimpleInput onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} readOnly={user.userType !== 'SUPER_ADMIN' ? true : ''} onChange={e => setMaxTripCount(e.target.value)} label="Agency Trip Limit" value={maxTripCount || user.agency.max_trip_count} />
                        </Form.Group>
                        {
                            (editAgencyName || editFasspayDeveloperId || editAgencyPhoneNo || editAgencyHotlineEmail || editAgencyHotlinePhone || editAgencyFleetSize || maxDriverCount || maxVehicleCount || maxRouteCount || maxTripCount) &&
                            <Card.Footer className='text-right' >
                                <Button variant='warning' className='mr-3' onClick={handleCloseEdit} >Cancel</Button>
                                <Button type='submit' variant='primary' >Update</Button>
                            </Card.Footer>
                        }

                    </Form>
                </Card.Body>
            </Card>
            <Collapse in={add} onEnter={() => setMountForm(true)} onExited={() => setMountForm(false)} >
                <div>
                    {
                        mountForm &&
                        <Card className='mt-3' >
                            <Card.Body>
                                <Card.Title>Add New Executive</Card.Title>
                                <Form onSubmit={handleCreateNewExecutive} >
                                    <Form.Row>
                                        <Col md={4} sm={12} >
                                            <Form.Label >First Name</Form.Label>
                                            <Form.Control onChange={(e) => setFirstName(e.currentTarget.value)} required size='sm' value={firstName} placeholder='John' />
                                        </Col>
                                        <Col md={4} sm={12} >
                                            <Form.Label >Last Name</Form.Label>
                                            <Form.Control onChange={(e) => setLastName(e.currentTarget.value)} required size='sm' value={lastName} placeholder='Smith' />
                                        </Col>
                                        <Col md={4} sm={12} >
                                            <Form.Label >Email</Form.Label>
                                            <Form.Control onChange={(e) => setEmail(e.currentTarget.value)} required size='sm' type='email' value={email} placeholder='johnsmith@domain.com' />
                                        </Col>
                                    </Form.Row>
                                    <Card.Footer className='text-right px-0 mt-1' >
                                        <Button onClick={handleCancelAdd} className='btn-warning mr-2' >Cancel</Button>
                                        <Button type='submit' >Add</Button>
                                    </Card.Footer>
                                </Form>
                            </Card.Body>
                        </Card>
                    }
                </div>
            </Collapse>
            <div className='mt-3' title='Create new executive' >
                {
                    !add &&
                    <Button onClick={() => setAdd(!add)} className='w-100' >
                        <PlusCircle size={30} />
                    </Button>
                }
                <Table numbering title='Executives' columns={execTableHeader} data={appendAction(executives || [])} />
            </div>
            {
                executives && selectedExecutive !== null &&
                <Modal title={`Edit ${(selectedExecutive !== null) ? `${executives[selectedExecutive].firstName} ${executives[selectedExecutive].lastName}` : ''}`} show={showEditModal} onHide={() => setShowEditModal(false)} >
                    <ExecutiveForm defaultValues={executives[selectedExecutive]} onClickCancel={handleClickCancel} onSubmitForm={handleExecutiveUpdate} />
                </Modal>
            }
            {
                /* Change the condition to showApadPassModal, usertype = super admin, is apad integrate */
                showApadPassModal && user.userType === 'SUPER_ADMIN' && user.agency.isApadItg &&
                <Modal title='Modify APAD Password?' show={showApadPassModal} onHide={() => setShowApadPassModal(false)} >
                    <ApadPasswordForm user={user} apadPassword={apadPassword} onclose={() => {
                        setShowApadPassModal(false)
                        toast.success('APAD password updated')
                    }} />
                </Modal>
            }

        </Container>
    )
}

export default AgencyProfilePage