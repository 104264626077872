import React, { useCallback, useState } from "react";
import { Alert, Card, Col, Form, Row, Button } from "react-bootstrap";
import _ from "lodash";
import { InfoCircle } from "react-bootstrap-icons";
import moment from "moment";
import {
  ContentWrapper,
  Modal,
  SimpleInputGroup,
} from "../../components";
import { ApiRequest, exportData } from "../../helpers";
import { API_END_POINTS } from "../../config";
import { CircularProgress } from "@material-ui/core";
import { toast } from "react-toastify";

import './ApadPmhsScoreboardPage.css';

const TransactionQuery = ({
    onSubmitQuery = () => {},
    onClearQuery = () => {},
    user,
    setToDate,
    setFromDate
  }) => {
    const [route, setRoute] = useState(null);
    const [amPm, setAmPm] = useState("All");
    const [weekendWeekday, setWeekendWeekday] = useState("All");
    const [selectFromDate, setSelectFromDate] = useState(null);
    const [selectToDate, setSelectToDate] = useState(null);
    const [vehicle, setVehicle] = useState(null);
    const [driver, setDriver] = useState(null);
    const [paidBy, setPaidBy] = useState("All");
  
    const [canFilter, setCanFilter] = useState(false);
  
    const handleSubmitQuery = (e) => {
        e.preventDefault();
        
        const query = {
            route,
            amPm,
            selectFromDate,
            selectToDate,
            vehicle,
            driver,
            weekendWeekday,
            paidBy,
        };

        if (!route || route === "null") {
            query["route"] = null;
        }

        if (!selectFromDate || selectFromDate === "null") {
            query["selectFromDate"] = null;
        }

        if (!selectToDate || selectToDate === "null") {
            query["selectToDate"] = null;
        }

        if (!vehicle || vehicle === "null") {
            query["vehicle"] = null;
        }

        if (!driver || driver === "null") {
            query["driver"] = null;
        }

        // console.log("query: ", query);
        onSubmitQuery(query);
    };
  
    const handleClear = () => {
      document.getElementById("date-from").value = "";
      setFromDate(moment().startOf("month").format("YYYY-MM-DD 00:00:00"));
      setToDate(moment().endOf("month").format("YYYY-MM-DD 23:59:59"));
      setRoute(null);
      setAmPm("All");
      setWeekendWeekday("All");
      setVehicle(null);
      setDriver(null);
      setPaidBy("All");
      setCanFilter(false);
      onClearQuery();
    };
    const setperiod = async (e) => {
      const mon = e.currentTarget.value;
      const start = await moment(mon)
        .startOf("month")
        .format("YYYY-MM-DD 00:00:00");
      const end = await moment(mon).endOf("month").format("YYYY-MM-DD 23:59:59");
      await setFromDate(start);
      setToDate(end);
      setCanFilter(true);
    };
  
    return (
      <Card>
        <Card.Body>
          <h5>Search</h5>
          <Row>
            <Col lg={4} md={12} sm={12}></Col>
            <Col lg={8} md={12} sm={12}>
              <Form onSubmit={handleSubmitQuery} className="text-right">
                <Row>
                  <Col>
                    <SimpleInputGroup labelWidth={10} preappendText="Date">
                        <Form.Control
                        id="date-from"
                        max={moment().format("YYYY-MM-DD")}
                        min={
                            user.userType != "SUPER_ADMIN"
                            ? moment().subtract(3, "months").format("YYYY-MM-DD")
                            : moment().subtract(12, "months").format("YYYY-MM-DD")
                        }
                        onChange={(e) => {
                            setFromDate(
                            e.currentTarget.value
                                ? `${e.currentTarget.value} 00:00:00`
                                : e.currentTarget.value
                            )
                            setSelectFromDate(
                            e.currentTarget.value
                                ? `${e.currentTarget.value} 00:00:00`
                                : e.currentTarget.value
                            )

                            setToDate(
                            e.currentTarget.value
                                ? `${e.currentTarget.value} 23:59:59`
                                : e.currentTarget.value
                            )
                            setSelectToDate(
                            e.currentTarget.value
                                ? `${e.currentTarget.value} 23:59:59`
                                : e.currentTarget.value
                            )
                        }
                        }
                        type="date"
                        />
                    </SimpleInputGroup>
                  </Col>
                </Row>
                <Button className="ml-2" onClick={handleClear} variant="warning">
                  Clear
                </Button>
                <Button className="ml-2" type="submit">
                  Search
                </Button>
              </Form>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
};

const RouteTable = ({ direction, routeId, rows }) => {
    const routeName = rows[0].routeName;
    const dir = direction == 'IB' ? 'INBOUND' : 'OUTBOUND';
    const tableTitle = `[${dir}] Route: ${routeId} ${routeName}`;

    let startStop = rows[0].startPoint;
    // remove the word opp from the station, including ()
    // startStop = startStop.replace(/\s*\(?\[?[oO][pP][pP]\]?\)?\s*/g, ' ').trim();

    // Clean up any extra spaces introduced by the replacement
    // startStop = startStop.replace(/\s\s+/g, ' ');

    let endStop = rows[0].finalWaypoint.name || '';

    return (
        <div className="route-table">
        <h2>{tableTitle}</h2>
        <table className="scoreboard-table">
            <thead>
            <tr>
                <th>Trip</th>
                <th>Bus No.</th>
                <th colSpan="3">Departure ({startStop})</th>
                <th colSpan="3">Arrival ({endStop})</th>
                <th>Remark</th>
            </tr>
            <tr>
                <th></th>
                <th></th>
                <th>Planned</th>
                <th>Actual Time</th>
                <th>Departure Status</th>
                <th>Planned</th>
                <th>Actual Time</th>
                <th>Arrival Status</th>
                <th></th>
            </tr>
            </thead>
            <tbody className="scoreboard-body">
            {rows.map((row, index) => {
                const parseTimeToSecond = (time) => {
                    const [hours, minutes, seconds=0] = time.split(':').map(Number);
                    return (hours * 60 * 60) + (minutes * 60) + seconds;
                };

                const formatSecondsToHours = (totalSeconds) => {
                    totalSeconds = Math.abs(totalSeconds);
                    const hours = Math.floor(totalSeconds / 60 / 60);
                    const minutes = Math.floor((totalSeconds % 3600) / 60);
                    const seconds = totalSeconds % 60;
                    return `${hours}h ${minutes}m ${seconds}s`;
                };

                const actualStartSeconds = parseTimeToSecond(row.actualStartWithSeconds);
                const actualEndSeconds = parseTimeToSecond(row.actualEndWithSeconds);
                const serviceStartSeconds = parseTimeToSecond(row.serviceStart);
                const serviceEndSeconds = parseTimeToSecond(row.serviceEnd);

                const startDelaySeconds = actualStartSeconds - serviceStartSeconds;
                const endDelaySeconds = actualEndSeconds - serviceEndSeconds;

                let departureStatus = startDelaySeconds < (-10 * 60) ? 
                                    "EARLY ( " : 
                                    startDelaySeconds > (5 * 60) ? 
                                    "LATE ( - " : 
                                    "ON TIME ( ";

                let arrivalStatus = endDelaySeconds < (-10 * 60) ? 
                                    "EARLY ( " : 
                                    endDelaySeconds > (5 * 60) ? 
                                    "LATE ( - " : 
                                    "ON TIME ( ";

                // add hour, minutes and seconds to statuses
                departureStatus += `${formatSecondsToHours(startDelaySeconds)} )`;
                arrivalStatus += `${formatSecondsToHours(endDelaySeconds)} )`;

                if (Number.isNaN(startDelaySeconds)) departureStatus = "-";
                if (Number.isNaN(endDelaySeconds)) arrivalStatus = "-";

                return (
                    <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{row.busPlate || '-'}</td>
                    <td>{row.serviceStart}</td>
                    <td>{row.actualStart}</td>
                    <td className={departureStatus.includes("EARLY") ? "status-early" : departureStatus.includes("LATE") ? "status-late" : departureStatus.includes("-") ? "" : "status-ontime"}>{departureStatus}</td>
                    <td>{row.serviceEnd}</td>
                    <td>{row.actualEnd}</td>
                    <td className={arrivalStatus.includes("EARLY") ? "status-early" : arrivalStatus.includes("LATE") ? "status-late" : arrivalStatus.includes("-") ? "" : "status-ontime"}>{arrivalStatus}</td>
                    <td>{row.remark}</td>
                    </tr>
                )
            })}
            </tbody>
        </table>
        </div>
    );
};
  

const ApadPmhsScoreboardPage = ({ user }) => {
    const [loading, setLoading] = useState(false);
    const [routesArr, setRoutesArr] = useState(['a', 'b']);
    const [transaction, setTransaction] = useState(null);
    const [mainData, setMainData] = useState({});

    const [toDate, setToDate] = useState(moment(new Date()).format("DD-MM-YYYY"));
    const [fromDate, setFromDate] = useState(
        moment(new Date()).subtract(1, "months").format("DD-MM-YYYY")
    );

    const handleFilterTransactionData = useCallback(async ({ selectFromDate, selectToDate }) => {
        if (!selectFromDate || !selectToDate) {
            toast.warning("Please Select a date!");
            return;
        }

        setLoading(true)

        if (process.env.REACT_APP_NODE_ENV != 'production') {
            // console.log("local data");
            const result = await ApiRequest.fetch({
                method: "get",
                url: `${API_END_POINTS.CLAIM_DETAILS_COLLECTION_BY_DATE_BACKEND}`,
                params: {
                    timestamp: new Date(),
                    from: selectFromDate,
                    to: selectToDate,
                    route: null,
                    amPm: "All",
                    selectFromDate,
                    selectToDate,
                    vehicle: null,
                    driver: null,
                    weekendWeekday: "All",
                    paidBy: "All",
                    agencyId: user.agency.id,
                },
            });
            const data = result.returnData;
            
            if (!data || data.length <= 0) {
                setLoading(false);
                return toast.warning("No data available!");
            }

            // group data via direction and routeId, sort via planned departure time
            const groupedAndSortedData = _.mapValues(
                _.groupBy(data, "direction"),
                directionGroup => _.mapValues(
                    _.groupBy(directionGroup, "routeId"),
                    routeGroup => _.sortBy(routeGroup, "serviceStart")
                )
            );

            for (const direction in groupedAndSortedData) {
                if (groupedAndSortedData.hasOwnProperty(direction)) {
                    const routes = groupedAndSortedData[direction];
            
                    for (const route in routes) {
                        if (routes.hasOwnProperty(route)) {
                            const trips = routes[route];
                            const routeShortName = trips[0].routeId;
                            const directionId = trips[0].direction == "OB" ? 1 : direction == "IB" ? 2 : 0;
    
                            const finalWaypoint = await ApiRequest.fetch({
                                method: "post",
                                url: `${API_END_POINTS.ROUTE_FINAL_WAYPOINT}`,
                                data: {
                                    routeShortName: routeShortName,
                                    directionId: directionId,
                                    agencyId: user.agency.id
                                },
                            });
    
                            trips.forEach(trip => {
                                trip.finalWaypoint = finalWaypoint;
                            });
    
                            continue;
                        }
                    }
                }
            }
            
            // console.log(groupedAndSortedData)
            setMainData(groupedAndSortedData);
        } else {
            // console.log("production data");
            const data = await ApiRequest.fetch({
                method: "get",
                url: `${API_END_POINTS.APAD_SCOREBOARD_COLLECTION}`,
                params: {
                    selectFromDate,
                    selectToDate,
                    agencyId: user.agency.id,
                    timestamp: new Date(),
                },
            });
    
            for (const direction in data) {
                if (data.hasOwnProperty(direction)) {
                    const routes = data[direction];
            
                    for (const route in routes) {
                        if (routes.hasOwnProperty(route)) {
                            const trips = routes[route];
                            const routeShortName = trips[0].routeId;
                            const directionId = trips[0].direction == "OB" ? 1 : direction == "IB" ? 2 : 0;
    
                            const finalWaypoint = await ApiRequest.fetch({
                                method: "post",
                                url: `${API_END_POINTS.ROUTE_FINAL_WAYPOINT}`,
                                data: {
                                    routeShortName: routeShortName,
                                    directionId: directionId,
                                    agencyId: user.agency.id
                                },
                            });
    
                            trips.forEach(trip => {
                                trip.finalWaypoint = finalWaypoint;
                            });
    
                            continue;
                        }
                    }
                }
            }

            // console.log(data)
            setMainData(data);
        }
        
        setLoading(false);
    }, [transaction]);

    const handleClearQuery = useCallback(() => {
        
    }, []);

    return(
        <ContentWrapper>
            <h2 className='mb-3' >Apad Scoreboard Report</h2>
            <Card className='mt-3' >
            <Card.Body>
                    {                   
                        <div>
                        {
                            routesArr ? (
                                <TransactionQuery
                                    onClearQuery={handleClearQuery}
                                    onSubmitQuery={handleFilterTransactionData}
                                    routeOpt={null}
                                    driverOpt={null}
                                    vehicleOpt={null}
                                    agencyNameOpt={[user?.agency?.name]}
                                    user={user}
                                    setFromDate={setFromDate}
                                    setToDate={setToDate}
                                />
                            ) : (
                                <div className="d-flex justify-content-center align-items-center">
                                    <CircularProgress />
                                </div>
                            )
                        }
                            <Alert className="mt-3" variant="info">
                                <InfoCircle /> Scoreboard Report only shows data for the past 1
                                months - Contact{" "}
                                <a href="mailto: info@justnaik.com">info@justnaik.com</a> for
                                more
                            </Alert>
                            {
                                mainData && 
                                <div className="bus-route-tables">
                                    {Object.entries(mainData).map(([direction, routes]) => (
                                    <div className={`${direction}-route-table`} key={direction}>
                                        {Object.entries(routes).map(([routeId, rows]) => (
                                        <RouteTable key={routeId} direction={direction} routeId={routeId} rows={rows} />
                                        ))}
                                    </div>
                                    ))}
                                </div>
                            }
                        </div>
                    }
                </Card.Body>
            </Card>

            <Modal size="sm" centered show={loading}>
                <div className="text-center">
                <CircularProgress size={70} />
                </div>
            </Modal>
        </ContentWrapper>
    );
}

export default ApadPmhsScoreboardPage;