import React, { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'

import { API_END_POINTS, Utils, GENERAL } from '../../config'
import { ApiRequest } from '../../helpers'
import { ContentWrapper, DateRangePicker, Modal, StatCard, SimpleChart } from '../../components'
import { Card, Row } from 'react-bootstrap'
import { CircularProgress } from '@material-ui/core'

const todayDay = moment()
const fromDateDefault = moment().subtract(1, 'weeks') //.subtract(+todayDay.format('DD') - 1, 'days')

const CollectionSummary = () => {
    const [settlement, setSettlement] = useState(null)
    const [fromFilterDate, setFromFilterDate] = useState(fromDateDefault)
    const [toFilterDate, setToFilterDate] = useState(todayDay)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.SETTLEMENT_GET_ALL_BY_DATE_RANGE}`,
            params: {
                fromDateValueOf: new Date(fromFilterDate.startOf('day')).valueOf(),
                toDateValueOf: new Date(toFilterDate.endOf('day')).valueOf()
            }
        }).then(data => {
            const sortedData = _.orderBy(data, [({ createdAt }) => new Date(createdAt)], ['desc'])
            setSettlement(sortedData)
        }).catch(e => { })
            .finally(() => {
                setLoading(false)
            })
    }, [fromFilterDate, toFilterDate])

    const filteredSettlement = useMemo(() => {
        if (!settlement) return null
        let filterred = [...settlement]

        if (fromFilterDate) {
            const minDate = fromFilterDate.startOf('day').valueOf()
            filterred = _.filter(filterred, ({ createdAt }) => moment(createdAt).valueOf() > minDate)
        }

        if (toFilterDate) {
            const maxDate = toFilterDate.endOf('day').valueOf()
            filterred = _.filter(filterred, ({ createdAt }) => moment(createdAt).valueOf() < maxDate)
        }

        return filterred

    }, [settlement, fromFilterDate, toFilterDate])

    const groupSummaryByDay = useMemo(() => {
        if (!filteredSettlement) return null
        const rawObj = _.groupBy(filteredSettlement, ({ createdAt }) => moment(createdAt).format('DD MMM YY'))
        // const arrayedRawObj = Object.keys(rawObj)

        const datesArr = []
        if (fromFilterDate && toFilterDate) {
            const from = moment(fromFilterDate)
            const to = moment(toFilterDate)
            //populate dates in between start and end date selected
            const numOfDays = Math.abs(to.diff(from, 'days'))

            // <= to include the first day eg, 1st Aug to 31st Aug, include 1st Aug
            // push into array first, then subtract from the date
            for (let i = 0; i <= numOfDays; i++) {
                datesArr.push(to.format('DD MMM YY'))
                to.subtract(1, 'day').format('DD MMM YY')
            }
        }

        const summary = {
            allDailySummaries: [],
            totalCollected: 0,
            totalCollectedOnline: 0,
            totalCollectedOffline: 0,
            totalRidershipOnline: 0,
            totalRidershipOffline: 0,
            totalRidership: 0,
            lastDate: datesArr[0],
            beginDate: datesArr[datesArr.length - 1],
            totalTransactionCount: 0,
            totalNetPayable: 0,
            graphFinanceData: [],
            graphTransactionData: [],
            graphRidershipData: []
        }

        const graphGrossDataDay = {
            label: 'Gross Cashless Revenue',
            data: [],
            color: '#362fa9'
        }

        const graphNetDataDay = {
            label: 'Net Cashless Payable',
            data: [],
            color: '#1a73e8'
        }

        const graphCashFareDataDay = {
            label: 'Gross Cash Revenue',
            data: [],
            color: 'red'
        }

        const graphRidershipDayOnline = {
            label: 'Cashless Ridership',
            data: [],
        }

        const graphRidershipDayOffline = {
            label: 'Cash Ridership',
            data: [],
        }

        // const graphTransactionDayOnline = {
        //     label: 'Cashless Transaction',
        //     data: [],
        // }

        // const graphTransactionDayOffline = {
        //     label: 'Cash Transaction',
        //     data: [],
        // }

        datesArr.forEach((date) => {
            const transactions = rawObj[date] || []
            const daySummary = {
                date,
                transactions
            }

            let total_amount = 0
            let total_amount_online = 0
            let total_amount_offline = 0
            let total_ridership_online = 0
            let total_ridership_offline = 0
            let total_ridership = 0

            transactions.forEach(({ amount, noOfPax, paymentType }) => {
                total_amount += amount
                total_ridership += noOfPax

                if (paymentType === GENERAL.PAYMENT_TYPE_ONLINE || paymentType.includes('FP')) {
                    total_amount_online += amount
                    total_ridership_online += noOfPax
                }

                if (paymentType === GENERAL.PAYMENT_TYPE_OFFLINE) {
                    total_amount_offline += amount
                    total_ridership_offline += noOfPax
                }
            })

            daySummary['total_amount'] = +total_amount.toFixed(2)
            daySummary['total_amount_online'] = +total_amount_online.toFixed(2)
            daySummary['total_amount_offline'] = +total_amount_offline.toFixed(2)

            daySummary['total_ridership_online'] = total_ridership_online
            daySummary['total_ridership_offline'] = total_ridership_offline
            daySummary['total_ridership'] = total_ridership
            //net payable is the amount after deduction eg: comission
            const deduction = Utils.getDeductionValue(total_amount_online)
            const netPay = total_amount_online - deduction
            daySummary['net_payable'] = +netPay.toFixed(2)

            summary.allDailySummaries.push(daySummary)
            summary.totalCollected += +total_amount.toFixed(2)
            summary.totalCollectedOnline += +total_amount_online.toFixed(2)
            summary.totalCollectedOffline += +total_amount_offline.toFixed(2)

            summary.totalRidershipOnline += total_ridership_online
            summary.totalRidershipOffline += total_ridership_offline
            summary.totalRidership += total_ridership
            summary.totalTransactionCount += transactions.length
            summary.totalNetPayable += +netPay.toFixed(2)

            graphGrossDataDay.data.push([date, total_amount_online])
            // graphNetDataDay.data.push([date, +netPay.toFixed(2)])
            graphCashFareDataDay.data.push([date, total_amount_offline])
            graphRidershipDayOnline.data.push([date, total_ridership_online])
            graphRidershipDayOffline.data.push([date, total_ridership_offline])
            // graphTransactionDayOnline.data.push([date, total_amount_online])
            // graphTransactionDayOffline.data.push([date, total_amount_offline])
        })

        graphGrossDataDay.data.reverse()
        // graphNetDataDay.data.reverse()
        graphCashFareDataDay.data.reverse()
        graphRidershipDayOnline.data.reverse()
        graphRidershipDayOffline.data.reverse()
        // graphTransactionDayOnline.data.reverse()
        // graphTransactionDayOffline.data.reverse()

        summary.graphFinanceData.push(graphGrossDataDay)
        // summary.graphFinanceData.push(graphNetDataDay)
        summary.graphFinanceData.push(graphCashFareDataDay)

        summary.graphRidershipData.push(graphRidershipDayOnline)
        summary.graphRidershipData.push(graphRidershipDayOffline)

        // summary.graphTransactionData.push(graphTransactionDayOnline)
        // summary.graphTransactionData.push(graphTransactionDayOffline)


        return summary
    }, [filteredSettlement])

    const financeTooltip = useMemo(() => ({
        // this tooltip shows price format correctly
        render: ({ datum }) => {
            // 
            return (
                <div className='p-0 m-0'>
                    {/* <b>{datum?.originalSeries.label}</b><br /> */}
                    <div className='w-100 text-center' ><b>{datum?.originalDatum[0]}</b></div><br />
                    <div className='d-flex align-items-center' >
                        <div style={{ width: 15, height: 15, borderRadius: 10, backgroundColor: datum?.group[1].originalSeries.color, marginRight: 10, border: 'solid 0.5px white' }} />
                        <b >{datum?.group[1].originalSeries.label}: </b>
                        <b className='text-right w-25'>{Number(datum?.group[1].originalDatum[1]).toFixed(2)}</b>
                    </div>
                    <div className='d-flex align-items-center' >
                        <div style={{ width: 15, height: 15, borderRadius: 10, backgroundColor: datum?.group[0].originalSeries.color, marginRight: 10, border: 'solid 0.5px white' }} />
                        <b >{datum?.group[0].originalSeries.label}: </b>
                        <b className='text-right w-25' >{Number(datum?.group[0].originalDatum[1]).toFixed(2)}</b>
                    </div>
                </div>
            )
        }
    }), [])

    return (
        <ContentWrapper>
            <h2 className='mb-3' >Summary Dashboard</h2>
            <DateRangePicker fromDate={fromDateDefault} onFromChanged={setFromFilterDate} onToChanged={setToFilterDate} />
            {
                groupSummaryByDay &&
                <Row>
                    <StatCard lg={4} md={4} sm={12} data={groupSummaryByDay.totalRidership} text='Total Ridership' />
                    <StatCard lg={4} md={4} sm={12} data={groupSummaryByDay.totalRidershipOnline} text='Cashless Ridership' />
                    <StatCard lg={4} md={4} sm={12} data={groupSummaryByDay.totalRidershipOffline} text='Cash Ridership' />
                    <StatCard lg={4} md={4} sm={12} data={groupSummaryByDay.totalCollected.toFixed(2)} text='Gross Fare Collected' />
                    <StatCard lg={4} md={4} sm={12} data={groupSummaryByDay.totalCollectedOnline.toFixed(2)} text='Cashless Fare Collected' />
                    <StatCard lg={4} md={4} sm={12} data={groupSummaryByDay.totalCollectedOffline.toFixed(2)} text='Cash Fare Collected' />
                    <StatCard lg={4} md={4} sm={12} data={groupSummaryByDay.totalTransactionCount} text='Total Transaction' />
                    <StatCard lg={4} md={4} sm={12} data={+groupSummaryByDay.totalNetPayable.toFixed(2)} text='Cashless Net Fare Payable' />
                    {/* <StatCard lg={3} md={6} sm={12} data={+groupSummaryByDay.totalCollected.toFixed(2)} text='Gross Revenue' /> */}
                </Row>
            }
            {
                groupSummaryByDay &&
                <Card className='mt-3 pb-4' >
                    <Card.Body>
                        <SimpleChart tooltip title='Ridership' stacked type='bar' data={groupSummaryByDay.graphRidershipData} />
                    </Card.Body>
                </Card>
            }
            {
                groupSummaryByDay &&
                <Card className='mt-3 pb-4' >
                    <Card.Body>
                        <SimpleChart tooltip={financeTooltip} title='finance' type='line' data={groupSummaryByDay.graphFinanceData} />
                    </Card.Body>
                </Card>
            }
            <Modal size='sm' centered show={loading} >
                <div className='text-center' >
                    <CircularProgress size={70} />
                </div>
            </Modal>
        </ContentWrapper>
    )
}

export default CollectionSummary